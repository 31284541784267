import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { MAX_FORM_CONTENT_WIDTH } from '@creator-portal/common/publishing/constants';

import { WorkSansFont } from '@/components/common/main.layout.styles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    stepperContentWrapper: {
      // 81px - height of stepper
      height: 'calc(100% - 81px)',
      overflowY: 'auto',
      margin: theme.spacing(0, 1),
      '&::-webkit-scrollbar': {
        width: 7,
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
        margin: theme.spacing(2, 0),
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.shade4,
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.shade4,
      },
      '& .MuiAutocomplete-root': {
        background: 'transparent',
        '& .MuiInputBase-root': {
          background: theme.palette.shade7,
        },
      },
      '& .media-has-error .EuiFileDropArea-content': {
        borderColor: theme.palette.commonRed,
      },

      '& .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item': {
        [theme.breakpoints.down(400)]: {
          width: '100%',
          marginTop: theme.spacing(6),
        },
      },
    },
    stepperContentLoading: {
      overflow: 'hidden',
    },
    stepperContentDivider: {
      height: 1,
      background: alpha(theme.palette.shade2, 0.2),
      margin: '0 auto',
      maxWidth: MAX_FORM_CONTENT_WIDTH,
    },
    stepperContentBlockAligned: {
      margin: '0 auto',
      padding: theme.spacing(6, 4),
      // actual width is 930 plus 40 side paddings
      maxWidth: MAX_FORM_CONTENT_WIDTH + 40,
    },
    stepperAccordionWrapper: {
      direction: theme.direction,
      background: 'transparent',
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        margin: '0 auto',
      },
      '& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
        padding: 0,
      },
      '& .MuiAccordionSummary-root.Mui-expanded, & .MuiAccordionSummary-root': {
        minHeight: 0,
      },
      '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
        marginBottom: 0,
        marginTop: theme.spacing(3),
      },
      '& .MuiAccordionSummary-content h3': {
        fontFamily: WorkSansFont,
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 600,
        lineHeight: theme.typography.pxToRem(20),
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        position: 'relative',
        top: 10,
        color: theme.palette.blue,
      },
      '& .MuiInputLabel-root': {
        textTransform: 'capitalize',
      },
    },
    stepperAccordionDescription: {
      direction: theme.direction,
      textAlign: isRTL ? 'right' : 'left',
      fontWeight: 500,
      margin: theme.spacing(4, 0),
    },
    input: {
      backgroundColor: theme.palette.shade7,
    },
    tag: {
      textTransform: 'uppercase',
      color: theme.palette.shade1,
      backgroundColor: theme.palette.shade6,
      '& svg': {
        color: `${theme.palette.shade3} !important`,
      },
    },
    staticTag: {
      color: theme.palette.shade4,
      textTransform: 'uppercase',
      backgroundColor: theme.palette.shade6,
    },
    tagInput: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      direction: theme.direction,
    },
    labelMedia: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      fontSize: theme.typography.pxToRem(12),
      color: 'theme.palette.shade1',
      paddingBottom: theme.spacing(1),
    },
    labelMediaDescription: {
      fontSize: theme.typography.pxToRem(10),
      color: theme.palette.shade2,
    },
    required: {
      color: theme.palette.commonOrangeGradient1,
    },
    fileUploader: {
      backgroundColor: theme.palette.shade7,
      boxShadow: 'none',
      border: `1px solid ${theme.palette.shade3}`,
      '&:hover': {
        borderColor: theme.palette.shade1,
      },
      '& .EuiFileUploader-controls': {
        marginLeft: isRTL ? '-10px' : '0',
        paddingLeft: isRTL ? '0' : '10px',
        marginRight: isRTL ? '0' : '-10px',
        paddingRight: isRTL ? '10px' : '0',
      },
    },
    imagePreview: {
      img: {
        maxWidth: 'auto',
      },
    },
    submitWrapper: {
      flexGrow: 1,
      maxWidth: 308,
    },
    mediaError: {
      '.ar &': {
        textAlign: 'right',
      },
    },
    checkboxLine: {
      display: 'flex',
    },
    checkboxDescription: {
      color: theme.palette.commonGray,
      display: 'flex',
      alignItems: 'center',
      '& label': {
        color: theme.palette.shade2,
      },
    },
    checkbox: {
      '& svg': {
        stroke: `${theme.palette.shade2} !important`,
      },
    },
    restrictIcon: {
      margin: theme.spacing(0, 0.7, -1),
      width: 16,
    },
    checkboxDescriptionIcon: {
      margin: theme.spacing(0, 1),
      fill: theme.palette.shade3,
    },
    staticTagTooltip: {
      '& .MuiTooltip-tooltip': {
        direction: `${theme.direction} !important`,
      },
    },
  });
});

export default useStyles;
