import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/styles';
import type { JSX } from 'react';
interface Props {
  cssClassName?: string;
  size?: string;
}
export default function CircularLoader({
  cssClassName,
  size
}: Props): JSX.Element {
  const theme = useTheme();
  return <Box className={cssClassName} sx={{
    color: theme.palette.shade3
  }} data-sentry-element="Box" data-sentry-component="CircularLoader" data-sentry-source-file="circular-loader.tsx">
      <CircularProgress color="inherit" size={size} data-sentry-element="CircularProgress" data-sentry-source-file="circular-loader.tsx" />
    </Box>;
}