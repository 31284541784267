import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    tableContainer: {
      background: 'transparent',
      '& table': {
        borderCollapse: 'separate',
        backgroundColor: 'transparent',
        borderSpacing: theme.spacing(0, 1.2),
        '& thead': {
          '& th': {
            textTransform: 'capitalize',
            fontSize: theme.typography.pxToRem(9),
            [isRTL ? 'paddingLeft' : 'paddingRight']: theme.spacing(2),
            textAlign: isRTL ? 'right' : 'left',
            padding: 0,
          },
          '& th:first-child': {
            [isRTL ? 'paddingRight' : 'paddingLeft']: theme.spacing(2),
          },
        },
        '& tbody': {
          '& tr': {
            marginBottom: theme.spacing(1),
            backgroundColor: theme.palette.shade5,
            '& td': {
              height: 80,
              padding: 0,
              [isRTL ? 'paddingLeft' : 'paddingRight']: theme.spacing(2),
              textAlign: isRTL ? 'right' : 'left',

              '& p': {
                fontSize: theme.typography.pxToRem(12),
              },
            },
            '& td:first-child': {
              [isRTL ? 'paddingRight' : 'paddingLeft']: theme.spacing(2),
            },
          },
        },
      },
    },
    regionBold: {
      fontWeight: 600,
    },
    ratingCategory: {
      textAlign: isRTL ? 'right' : 'left',
      marginBottom: theme.spacing(1),
    },
    regionTitle: {
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.2px',
      color: theme.palette.shade0,
    },
    partialMatureBacklight: {
      backgroundColor: `${alpha(theme.palette.commonOrangeGradient2, 0.2)} !important`,
    },
    matureBacklight: {
      backgroundColor: `${alpha(theme.palette.commonRedGradient2, 0.2)} !important`,
    },
    overriddenIcon: {
      color: theme.palette.shade2,
      marginRight: theme.spacing(1.5),
      '& svg': {
        marginBottom: -2,
      },
    },
    tdImg: {
      width: theme.spacing(18),
    },
    tdCategory: {
      minWidth: theme.spacing(19),
      maxWidth: theme.spacing(33),
    },
    tdRegion: {
      minWidth: theme.spacing(16),
      maxWidth: theme.spacing(33),
    },
    tdOthers: {
      maxWidth: theme.spacing(24),
    },
    tdBtn: {
      width: theme.spacing(22),
    },
    img: {
      height: 60,
      width: 60,
      position: 'relative',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.commonWhite,
    },
    ratingCategoryLabel: {
      backgroundColor: theme.palette.shade6,
      borderRadius: theme.spacing(0.8),
      color: theme.palette.shade0,
      padding: theme.spacing(0.5, 1.2),
      width: 'fit-content',
      display: 'flex',
    },
    tooltip: {
      '& .MuiTooltip-tooltip': {
        maxWidth: 330,

        [theme.breakpoints.up('xs')]: {
          maxWidth: 380,
        },
      },
    },
    warnIcon: {
      fill: theme.palette.commonOrangeGradient1,
    },
    successIcon: {
      fill: theme.palette.shade3,
    },
    matureCategory: {
      color: theme.palette.commonRed,
    },
    iconWrapper: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      borderRadius: '50%',
      position: 'absolute',
      [isRTL ? 'left' : 'right']: theme.spacing(-1.2),
      top: theme.spacing(-1.2),
      backgroundColor: theme.palette.commonWhite,
    },
    btnManage: {
      border: 'none !important',
      padding: `${theme.spacing(0.8)} ${theme.spacing(1.6)} !important`,
      height: `${theme.spacing(6)} !important`,
    },
  });
});

export default useStyles;
