import * as Xhr from '@/util/xhr';

import type { AccountInfoResponse } from '@creator-portal/common/types';

export namespace AccountUrl {
  export const marvelousDesignerSendCode = () => '/api/account/v1/marvelous-designer/send-code';
  export const accountInfo = () => '/api/account/v1/account';
}

export const marvelousDesignerSendCode = async (): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(AccountUrl.marvelousDesignerSendCode(), {
    method: 'POST',
  });

  Xhr.throwOnFailure(response);
};

export const getPublicAccountInfo = async (): Promise<AccountInfoResponse> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson<AccountInfoResponse>(AccountUrl.accountInfo(), {
    method: 'GET',
  });

  Xhr.throwOnFailure(response);

  return response.data;
};
