import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import getPaletteModeStyles from '@/util/getPaletteModeStyles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    close: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '-10px',
    },
    title: {
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(25),
      color: theme.palette.shade0,
      maxWidth: '58%',
    },
    standardTitleColor: {
      '& span': {
        color: theme.palette.shade1,
      },
    },
    top: {
      paddingBottom: theme.spacing(6.6),
    },
    teamCard: {
      position: 'relative',
      '& #cardWrapper': {
        display: 'none',
        background: theme.palette.shade5,
        borderRadius: theme.spacing(1.6),
        [isRTL ? 'marginRight' : 'marginLeft']: theme.spacing(-1.8),
        position: 'absolute',
        width: 'calc(100% + 18px)',
      },
      '&:hover': {
        '& #cardWrapper': {
          display: 'block',
          marginTop: theme.spacing(-2.9),
          height: 124,
        },
      },
    },
    currentCardWrapper: {
      display: 'block !important',
      padding: theme.spacing(1.8, 1.8, 2.4, 1.8),
      marginTop: '-34px !important',
      height: '144px !important',
    },
    relative: {
      position: 'relative',
    },
    currentLabel: {
      marginBottom: theme.spacing(1.6),
      color: theme.palette.shade2,
      letterSpacing: theme.spacing(0.2),
      fontSize: theme.typography.pxToRem(10),
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'center',

      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: 34,

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 236px',
      },
    },
    dialog: {
      direction: theme.direction,
      overflow: 'hidden',

      '& .MuiDialog-container': {
        alignItems: 'flex-start',
        paddingTop: theme.spacing(11.2),

        [theme.breakpoints.up('sm')]: {
          alignItems: 'center',
          paddingTop: 0,
        },
      },
      '& .MuiPaper-root': {
        maxWidth: 749,
        margin: 0,
      },
      '& .EuiDialogHeader-root': {
        padding: theme.spacing(7, 4.8, 1.5),
        backgroundColor: theme.palette.shade5,
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(7, 8, 1.5),
        },
      },
      '& .MuiDialogContent-root': {
        overflow: 'hidden',
        padding: '0px 0px 0px 0px !important',
        backgroundColor: theme.palette.shade5,
        width: 'calc(100vw - 2px)',
      },
      '& .EuiDialogHeader-root button': {
        marginBottom: theme.spacing(-0.6),
        position: 'absolute',
        top: theme.spacing(3),
        [isRTL ? 'left' : 'right']: theme.spacing(4),
      },

      [theme.breakpoints.up('sm')]: {
        '& .MuiDialogContent-root': {
          width: '579px',
        },
      },

      [theme.breakpoints.up('md')]: {
        '& .MuiDialogContent-root': {
          width: '747px',
        },
      },
    },
    scrollWrapper: {
      [isRTL ? 'paddingLeft' : 'paddingRight']: theme.spacing(1.8),
      overflow: 'hidden',
      background: getPaletteModeStyles(theme.palette.mode)(theme.palette.shade6, theme.palette.shade7),
    },
    subTitle: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.shade2,
    },
    bottom: {
      background: getPaletteModeStyles(theme.palette.mode)(theme.palette.shade6, theme.palette.shade7),
      paddingTop: theme.spacing(11.8),
      paddingBottom: theme.spacing(16),
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(10, 6.6),
      maxHeight: 384,
      gridAutoRows: 'auto',
      '& .MuiCard-root': {
        margin: 0,
      },
      overflow: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar-thumb': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.shade4,
      },
      '&::-webkit-scrollbar': {
        width: '6px',
        scrollMarginLeft: theme.spacing(4),
      },

      [theme.breakpoints.up('xs')]: {
        gridTemplateColumns: '1fr 1fr',
      },

      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(6.4),
      },

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '200px 200px 200px',
      },
    },
    withSidePadding: {
      paddingLeft: theme.spacing(4.8),
      paddingRight: theme.spacing(4.8),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    tableBrand: {
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.shade0,
    },
    tableTeam: {
      padding: '15.5px 0px',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(15),
      color: theme.palette.shade2,
      letterSpacing: '0.5px',
      display: 'flex',
      alignItems: 'center',
    },
    row: {
      padding: '0px 24px',
      backgroundColor: theme.palette.shade7,
      boxShadow: `0px 0px 3px ${theme.palette.shade1}`,
      borderRadius: '8px',
      display: 'grid',
      gridTemplateColumns: '240px 1fr',
      width: '100%',
      marginBottom: '4px',
      alignItems: 'center',
      minHeight: '49px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    rowActive: {
      outline: `2px solid ${theme.palette.commonBlueGradient2}`,
      '& td': {
        color: `${theme.palette.commonBlueGradient2} !important`,
      },
    },
    rowWithMargin: {
      marginTop: '32px',
    },
    buttonWrapper: {
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        maxWidth: 194,
        top: theme.spacing(6),
        [isRTL ? 'left' : 'right']: theme.spacing(16),
      },
    },
    mobileButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });
});

export default useStyles;
