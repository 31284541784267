import { v4 as uuid4 } from 'uuid';

import { isLiveEnv, isLiveTestingEnv } from '@creator-portal/common/util/env';
import { canAccess } from '@creator-portal/common/auth';
import {
  CREATIVE_ISLAND_TYPE,
  DISCOVERY_INTENT,
  FNC,
  GAME_FEATURE,
  LINK_CATEGORY,
  LINK_STATE,
  UEFN,
  VALKYRIE_LINK_TYPE,
} from '@creator-portal/common/links';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import { PROJECT_STATUS, RELEASE_STATUS } from '@creator-portal/common/publishing/constants';
import { resolveProjectOriginAndAuthority } from '@creator-portal/common/vk';
import { BLOCK_PROMOTE_BUILD_CODE_SYSMETA_KEY, PROJECT_KIND_LIBRARY, VK_ID_REGEX } from '@creator-portal/common/vk/constants';

import { isProjectDisabled } from '@/services/projects/projects-service';
import { getIsTeamPersonal } from '@/services/teams/utils';

import { RatingStatus } from '@/components/publishing/ratings-dialog.component';

import { ProjectTypeForAnalytics } from '@/types/publishing';

import { Roles } from './teams';

import type { Attribution, AttributionModel } from '@/types/publishing';
import type {
  AuthSession,
  LinkBuildStatus,
  LinkCodeData,
  ProjectDetailResult,
  ProjectDoc,
  ProjectRelease,
  ProjectSearchResult,
  SysMeta,
  TeamMembershipRole,
  ValidatePublishResult,
} from '@creator-portal/common/types';

export const getProjectStatus = (project: ProjectDetailResult | ProjectSearchResult, session?: AuthSession): PROJECT_STATUS => {
  const isDisabled = isProjectDisabled(project);
  const iarcStage2 = session ? canAccess(session, PERMISSION.IARC_STAGE_2) : false;

  if (!project.linkData || project.linkData.moderationStatus === 'Denied') return PROJECT_STATUS.UNPUBLISHED;
  // else if (project.linkData.moderationStatus === 'Denied') return PROJECT_STATUS.DENIED;
  else if (isDisabled) return PROJECT_STATUS.DISABLED;
  else if (iarcStage2 && !project.linkData.meta.ratings) return PROJECT_STATUS.NO_RATING;
  else if (project.linkData.discoveryIntent === DISCOVERY_INTENT.DISABLED) return PROJECT_STATUS.DEACTIVATED;
  else if (project.public) return PROJECT_STATUS.PUBLIC;
  return PROJECT_STATUS.PRIVATE;
};

export const getLatestReleaseStatus = (project: ProjectDetailResult | ProjectSearchResult): RELEASE_STATUS => {
  if (!project.latestRelease) return RELEASE_STATUS.UNMODERATED;
  else if (project.latestRelease.error || project.latestRelease.expired) return RELEASE_STATUS.ERROR;
  else if (project.latestRelease.cancelled) return RELEASE_STATUS.CANCELLED;
  else if (project.latestRelease.success === null) return RELEASE_STATUS.PENDING;
  else if (!project.latestRelease.certId && project.latestRelease.success) return RELEASE_STATUS.NO_RATING;

  if (project.latestRelease.success && project.linkData?.version === project.latestRelease.releaseVersion) return RELEASE_STATUS.ACTIVE;
  else if (project.latestRelease.success) return RELEASE_STATUS.APPROVED;

  return RELEASE_STATUS.DENIED;
};

export const getReleaseStatus = (
  release: ProjectRelease,
  ratingStatus: RatingStatus | null,
  activeCertificateId?: string,
  activeVersion?: number,
): RELEASE_STATUS => {
  const releaseCertificateId = release.certId;

  const isApproved = Boolean(release.success && release.liveLink);
  const isFailed = release.success === false;
  const isError = release.error ?? release.expired;
  const isCancelled = release.cancelled;
  const isPending = release.success === null;
  const isNoRating = !releaseCertificateId;
  const isRegionalRestrictions = ratingStatus === RatingStatus.PARTIAL;
  const isRecentVersion = activeVersion && release.releaseVersion && release.releaseVersion >= activeVersion;
  const isRatingExpired =
    !!activeCertificateId && !!releaseCertificateId && releaseCertificateId !== activeCertificateId && !isRecentVersion;
  const isPromoReset = release.linkData?.isPromoReset;

  if (isError) return RELEASE_STATUS.ERROR;
  if (isFailed) return RELEASE_STATUS.DENIED;
  if (isCancelled) return RELEASE_STATUS.CANCELLED;
  if (isPending) return RELEASE_STATUS.PENDING;
  if (isPromoReset) return RELEASE_STATUS.APPROVED_WITH_EDITS;
  if (isRatingExpired) return RELEASE_STATUS.EXPIRED_RATING;
  if (isRegionalRestrictions) return RELEASE_STATUS.REGIONAL_RESTRICTIONS;
  if (isNoRating) return RELEASE_STATUS.NO_RATING;
  if (isApproved) return RELEASE_STATUS.APPROVED;

  return RELEASE_STATUS.UNMODERATED;
};

export const checkIsTeamIdValid = (teamId?: string): boolean => {
  if (!teamId) return false;

  return VK_ID_REGEX.test(teamId) || getIsTeamPersonal(teamId);
};

export function isBuildEligableForPublishing(build: LinkCodeData, project: ProjectDoc): boolean {
  const projectOriginAndAuthority = resolveProjectOriginAndAuthority(project.sysMeta);

  if (projectOriginAndAuthority.authority === UEFN && build.type !== VALKYRIE_LINK_TYPE) return false;
  if (projectOriginAndAuthority.authority === FNC && build.type !== CREATIVE_ISLAND_TYPE) return false;

  if (build.isDisabled) return false;
  if (build.moderationStatus === 'Denied') return false;

  return true;
}

export function canPublishBuild(
  config: { EPIC_ENV: Readonly<string>; EPIC_DEPLOYMENT: Readonly<string> },
  project: ProjectDoc,
  build: LinkCodeData,
  liveLink?: LinkCodeData,
  buildStatus?: LinkBuildStatus,
  validatePublishResult?: ValidatePublishResult,
  session?: AuthSession,
): boolean {
  const isUEFN = build.type === VALKYRIE_LINK_TYPE;
  const bypassMemoryAndDownloadCheck = session ? canAccess(session, PERMISSION.BYPASS_MEMORY_AND_DOWNLOAD_CHECK) : false;

  if (project.sysMeta[BLOCK_PROMOTE_BUILD_CODE_SYSMETA_KEY]) return false;

  if (!buildStatus) return false;

  if (buildStatus.experimental) return false;

  if (isLiveTestingEnv(config) && liveLink?.linkState === LINK_STATE.LIVE) return false;
  if (isLiveEnv(config) && liveLink?.linkState === LINK_STATE.STAGED) return false;

  if (!isBuildEligableForPublishing(build, project)) return false;

  if (isUEFN) {
    if (!validatePublishResult) return false;

    if (buildStatus.contentSize === undefined) return false;

    if (!buildStatus.contentSize.isValid || !buildStatus.contentSize.isWithinPublicBudget) return false;

    if (buildStatus.failedModuleSafetyStatus) return false;

    if (validatePublishResult.status !== 'success') return false;

    // profiling (memory usage) is not available for Content Libraries
    if (
      !bypassMemoryAndDownloadCheck &&
      project.meta.kind !== PROJECT_KIND_LIBRARY &&
      (!buildStatus.profiling || !buildStatus.profiling.isValid || !buildStatus.profiling.isWithinPublicBudget)
    )
      return false;
  }

  return true;
}

export const getProjectTitle = (project: ProjectDetailResult | ProjectSearchResult | any): string | undefined => {
  const mainTitle = project?.meta?.title || project?.info?.title || undefined;
  const releasedTitle = (project?.activeLinkData?.meta?.title ?? project?.linkData?.meta?.title) as string;

  return releasedTitle ?? mainTitle;
};

export const prepareAttributionModel = (initAttribution?: Attribution | AttributionModel | null): AttributionModel => ({
  tempId: (initAttribution as AttributionModel)?.tempId || uuid4(),
  title: initAttribution?.title || '',
  source_url: initAttribution?.source_url || '',
  author: initAttribution?.author || '',
  author_url: initAttribution?.author_url || '',
  license: initAttribution?.license || '',
  license_url: initAttribution?.license_url || '',
});

export const prepareAttributionDto = (initAttribution?: AttributionModel | null): Attribution | any => ({
  title: initAttribution?.title || null,
  source_url: initAttribution?.source_url || null,
  author: initAttribution?.author || null,
  author_url: initAttribution?.author_url || null,
  license: initAttribution?.license || null,
  license_url: initAttribution?.license_url || null,
});

export const isProjectSearchResult = (result: ProjectDetailResult | ProjectSearchResult): result is ProjectSearchResult =>
  (result as ProjectSearchResult).info !== undefined;

export const getProjectOwnerType = (project: ProjectDetailResult | ProjectSearchResult) => {
  if (isProjectSearchResult(project)) {
    return project.info.owner.type;
  } else {
    return project.owner.type;
  }
};

export const getProjectOwnerId = (project: ProjectDetailResult | ProjectSearchResult) => {
  if (isProjectSearchResult(project)) {
    return project.info.owner.id;
  } else {
    return project.owner.id;
  }
};

const getGameFeatureByLinkCategory = (linkCategory?: string) => {
  switch (linkCategory) {
    case LINK_CATEGORY.LEGO:
      return GAME_FEATURE.LEGO;
    case LINK_CATEGORY.ROCKET_RACING:
      return GAME_FEATURE.DELMAR;
    default:
      return '';
  }
};

export const checkProjectSysMetaIncludesGameFeature = (sysMeta?: SysMeta, gameFeature?: GAME_FEATURE) => {
  if (!gameFeature || !sysMeta?.gamefeaturesets || sysMeta?.gamefeaturesets?.length === 0) return false;

  const gamefeaturesets = sysMeta.gamefeaturesets;

  return gamefeaturesets.includes(gameFeature);
};

export const getFirstGameFeature = (sysMeta?: SysMeta) => {
  if (!sysMeta?.gamefeaturesets || sysMeta?.gamefeaturesets?.length === 0) return;

  return sysMeta.gamefeaturesets[0];
};

export const checkProjectSysMetaIncludesLinkCategory = (sysMeta?: SysMeta, linkCategory?: string) => {
  const gamefeaturesets = sysMeta?.gamefeaturesets || [];
  const gamefeature = getGameFeatureByLinkCategory(linkCategory);

  return gamefeaturesets.includes(gamefeature);
};

const gameFeatureToAnalyticTypeMap = {
  [GAME_FEATURE.LEGO]: ProjectTypeForAnalytics.LEGO,
  [GAME_FEATURE.DELMAR]: ProjectTypeForAnalytics.ROCKET_RACING,
  [GAME_FEATURE.FALL_GUYS]: ProjectTypeForAnalytics.FALL_GUYS,
  [GAME_FEATURE.TMNT]: ProjectTypeForAnalytics.TMNT,
  [GAME_FEATURE.TWDU]: ProjectTypeForAnalytics.TWD,
};

export const getProjectTypeForAnalytics = (sysMeta?: SysMeta) => {
  const gameFeature = getFirstGameFeature(sysMeta);

  if (!gameFeature) return ProjectTypeForAnalytics.DEFAULT;

  return (gameFeatureToAnalyticTypeMap[gameFeature] as ProjectTypeForAnalytics) || ProjectTypeForAnalytics.DEFAULT;
};

export const supportedRolesForMarketingFeature = [Roles.OWNER, Roles.ADMIN, Roles.PUBLISHER, 'owner'];

export const isMarketingOptInEnabled = (
  projectStatus: PROJECT_STATUS,
  isProjectHasLiveLink: boolean,
  isProjectArchived: boolean,
  role?: TeamMembershipRole,
) => {
  const disabled = projectStatus === PROJECT_STATUS.DISABLED;
  const isProjectUnpublished = projectStatus === PROJECT_STATUS.UNPUBLISHED;

  const isEnabled =
    isProjectHasLiveLink &&
    !disabled &&
    !isProjectUnpublished &&
    !isProjectArchived &&
    role &&
    supportedRolesForMarketingFeature.includes(role);

  return isEnabled;
};
