import { createContext, useContext } from 'react';
import { ReleaseAutoSavingInfo } from '@/components/publishing/deploy-release-flow/useReleaseAutoSaving';
export function usePublishingAutoSaving(): PublishingAutoSavingProps {
  return useContext(PublishingAutoSavingContext);
}
export interface PublishingAutoSavingProps {
  onResetReleaseFromStorage: () => void;
  getReleaseFromStorage: () => ReleaseAutoSavingInfo | null;
  setReleaseFieldToStorage: (fieldName: string, value: string) => void;
  onSaveReleaseToStorage: () => void;
  onSaveAttributionsToStorage: () => void;
}
export const PublishingAutoSavingContext = createContext<PublishingAutoSavingProps>({} as PublishingAutoSavingProps);