import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toast: {
      '& .Toastify__toast': {
        minHeight: '98px',
        padding: theme.spacing(4),
        '&.custom__toast': {
          display: 'flex',
          maxWidth: '540px',
          padding: theme.spacing(3.6, 0, 3.6, 1.6),
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: theme.spacing(3.2),
          borderRadius: theme.spacing(0.8),
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          marginInlineStart: 'auto',
          '&.custom__toast--success': {
            background: theme.palette.commonGradientBackgroundGreen,
          },
        },
      },
      '& .Toastify__toast--success': {
        background: theme.palette.commonGradientBackgroundGreen,
      },
      '& .Toastify__toast--error': {
        background: `linear-gradient(91.53deg, ${theme.palette.commonRedGradient1} -5.24%, ${theme.palette.commonRedGradient2} 103.3%)`,
      },
      '& .Toastify__progress-bar': {
        visibility: 'hidden',
      },
      '& .Toastify__close-button': {
        opacity: 1,
      },
      '& .Toastify__toast-body': {
        fontSize: theme.typography.pxToRem(18),
        fontHeight: theme.typography.pxToRem(25),
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(0, 1.2),
      },
      '& .Toastify__toast-icon': {
        marginInlineEnd: theme.spacing(3.6),
      },

      '&.Toastify__toast-container--top-right': {
        top: 0,
        right: 10,
        marginTop: 10,
        padding: 0,
        width: 'max-content',
        maxWidth: '100%',
        [theme.breakpoints.up('sm')]: {
          minWidth: '576px',
        },
      },
    },
    grayToastSuccess: {
      '&.Toastify__toast--success': {
        background: theme.palette.commonGray,
      },
    },
  }),
);

export default useStyles;
