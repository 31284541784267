import { CloseIcon } from '@epic-ui/icons';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { canAccess } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import { ABOUT_RATING_URL } from '@/config/common/constants';
import { getInternationalAgeRatingCoalitionUrl } from '@/config/common/functions';
import { toDocLocale } from '@/config/common/locale.config';
import CircularLoader from '@/components/common/loaders/circular-loader';
import RatingInfoboxComponent from '@/components/publishing/deploy-release-flow/steps/form-parts/rating-infobox';
import RatingsDisplayComponent, { COMPONENT_SOURCE_TYPES } from '@/components/publishing/deploy-release-flow/steps/form-parts/ratings-display';
import { useAuthSession } from '@/hooks/useAuthSession';
import LogoImage from '@/public/iarc-logo.svg';
import useStyles from './ratings-dialog.styles';
import type { RatingInfo } from '@/hooks/useRatingData';
import type { FC, JSX } from 'react';
export enum RatingStatus {
  SUCCESS = 'SUCCESS',
  MATURE = 'MATURE',
  PARTIAL = 'PARTIAL',
}
interface ProjectRatingsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  projectName: string;
  privateVersion: string;
  ratingData: RatingInfo;
}
const ProjectRatingsDialog: FC<ProjectRatingsDialogProps> = ({
  isOpen,
  onClose,
  privateVersion,
  projectName,
  ratingData
}): JSX.Element => {
  const session = useAuthSession();
  const {
    t,
    i18n
  } = useTranslation();
  const docLang = toDocLocale(i18n.language);
  const classes = useStyles();
  const hasIarcPortability = canAccess(session, PERMISSION.IARC_PORTABILITY);
  const {
    productName,
    email,
    publicDeveloperEmail,
    data,
    status,
    date,
    projectId,
    certificateId
  } = ratingData;
  const title = t('projects.modal.ratings.title', {
    projectName
  });
  const subTitle = <Typography className={classes.subTitle}>
      <Trans i18nKey="projects.modal.ratings.subTitle" values={{
      version: privateVersion
    }} components={{
      strong: <strong></strong>
    }} />
    </Typography>;
  return <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="lg" scroll="paper" className={classes.dialog} data-sentry-element="Dialog" data-sentry-component="ProjectRatingsDialog" data-sentry-source-file="ratings-dialog.component.tsx">
      <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="ratings-dialog.component.tsx">
        {title}
        <IconButton aria-label={t('button.close')} onClick={onClose} className={classes.closeIcon} data-sentry-element="IconButton" data-sentry-source-file="ratings-dialog.component.tsx">
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="ratings-dialog.component.tsx" />
        </IconButton>
        {subTitle}
      </DialogTitle>
      <DialogContent className={classes.content} data-sentry-element="DialogContent" data-sentry-source-file="ratings-dialog.component.tsx">
        {email && status ? <div className={classes.scrollContainer}>
            <div className={classes.contentBlock}>
              <div className={classes.titleContent}>
                <Typography component="h3" className={classes.contentHeader}>
                  {t('publish.stepper.sub-header.rating.received')}
                </Typography>
                {hasIarcPortability ? <Image src={LogoImage} alt="iarc" /> : null}
              </div>
              {hasIarcPortability ? <>
                  <Typography component="p" className={classes.contentSubheader}>
                    <Trans i18nKey="publish.stepper.sub-header.rating.received.description" components={{
                a1: <a href={getInternationalAgeRatingCoalitionUrl(docLang)} target="_blank" rel="noopener noreferrer" />,
                a2: <a href={ABOUT_RATING_URL} target="_blank" rel="noopener noreferrer" />
              }} />
                  </Typography>
                  <div className={classes.line} />
                </> : null}
              <RatingInfoboxComponent date={date} status={status} hasIarcPortability={hasIarcPortability} />
            </div>
            <div className={classes.contentBlock}>
              <RatingsDisplayComponent componentSourceTypes={COMPONENT_SOURCE_TYPES.ratingDialog} productName={productName} date={date} email={email} publicDeveloperEmail={publicDeveloperEmail} status={status} certificateId={certificateId} projectId={projectId} data={data} hasIarcPortability={hasIarcPortability} />
            </div>
          </div> : <div className={classes.loader}>
            <CircularLoader cssClassName="loader-after-list" />
          </div>}
      </DialogContent>
    </Dialog>;
};
export default ProjectRatingsDialog;