import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { MAX_FORM_CONTENT_WIDTH } from '@creator-portal/common/publishing/constants';

import { WorkSansFont } from '@/components/common/main.layout.styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '& .MuiDialogTitle-root': {
        backgroundColor: theme.palette.shade5,
        fontFamily: WorkSansFont,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(32),
        lineHeight: theme.typography.pxToRem(36),
        position: 'relative',
        textAlign: 'left',
        padding: theme.spacing(7.2, 16, 7.2, 8.2),
        minHeight: 134,
      },
      '& .MuiDialogContent-root': {
        padding: 0,
      },
      '& .MuiPaper-root': {
        border: 0,
        borderRadius: 10,
        minHeight: 'calc(100% - 64px)',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: 27,
      right: 34,
    },
    subTitle: {
      marginTop: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.shade1,
      '& strong': {
        color: theme.palette.shade3,
      },
    },
    contentHeader: {
      fontFamily: WorkSansFont,
      color: theme.palette.shade0,
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
      lineHeight: theme.typography.pxToRem(20),
      marginBottom: theme.spacing(5),
    },
    contentSubheader: {
      color: theme.palette.shade2,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
    },
    content: {
      backgroundColor: theme.palette.shade6,
      display: 'flex',
      flexDirection: 'column',
      paddingRight: `${theme.spacing(2.4)} !important`,
      paddingLeft: `${theme.spacing(2.4)} !important`,
    },
    scrollContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      '&::-webkit-scrollbar-thumb': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.shade4,
      },
      '&::-webkit-scrollbar': {
        width: '8px',
        scrollMarginRight: theme.spacing(5),
      },
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    contentBlock: {
      width: '100%',
      margin: '0 auto',
      // actual width is 930 plus 40 side paddings
      maxWidth: MAX_FORM_CONTENT_WIDTH + 40,
      marginBottom: theme.spacing(6),
    },
    titleContent: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    loader: {
      display: 'flex',
      marginTop: theme.spacing(20),
      justifyContent: 'center',
    },
    line: {
      backgroundColor: theme.palette.shade3,
      height: 1,
      width: '100%',
      margin: theme.spacing(8, 0, 8, 0),
    },
  }),
);

export default useStyles;
