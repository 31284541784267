import { PERSONAL_TEAM_ID } from '@creator-portal/common/publishing/constants';
import { TeamSearchResult } from '@creator-portal/common/types';

import { Roles } from '@/util/teams';

export const sortByNamesAndBrand = (list: TeamSearchResult[]): TeamSearchResult[] =>
  list.sort((team1, team2) => {
    const compareByName = team1.name.localeCompare(team2.name);

    return compareByName === 0 ? (team1.publishingBrand || '').localeCompare(team2.publishingBrand || '') : compareByName;
  });

export const sortByRolesAndNames = (list: TeamSearchResult[]): TeamSearchResult[] => {
  const sortedList = sortByNamesAndBrand(list);
  const ownerTeams: TeamSearchResult[] = [];
  const adminTeams: TeamSearchResult[] = [];
  const publisherTeams: TeamSearchResult[] = [];
  const collaboratorTeams: TeamSearchResult[] = [];
  const otherTeams: TeamSearchResult[] = [];

  sortedList.forEach((team) => {
    const cpRole = team.membership?.cpRole;

    switch (cpRole) {
      case Roles.OWNER:
        ownerTeams.push(team);
        break;
      case Roles.ADMIN:
        adminTeams.push(team);
        break;
      case Roles.PUBLISHER:
        publisherTeams.push(team);
        break;
      case Roles.COLLABORATOR:
        collaboratorTeams.push(team);
        break;
      default:
        otherTeams.push(team);
    }
  });

  return [...ownerTeams, ...adminTeams, ...publisherTeams, ...collaboratorTeams, ...otherTeams];
};

export const updateInfoInParticularTeam = (teams: TeamSearchResult[], newInfo: TeamSearchResult): TeamSearchResult[] =>
  teams.map((el) => {
    if (newInfo.teamId === el.teamId) {
      return {
        ...el,
        name: newInfo.name,
        description: newInfo.description,
      };
    } else return el;
  });

export const excludeKeyFromQuery = (query: Record<string, unknown>, excludeKey: string): any => {
  const objectKeysList = Object.keys(query);

  return objectKeysList.reduce(
    (accumulator, currentValue) => (currentValue !== excludeKey ? { ...accumulator, [currentValue]: query[currentValue] } : accumulator),
    {},
  );
};

export const getIsTeamPersonal = (teamId: string | string[] | undefined) => teamId === PERSONAL_TEAM_ID;

export const getCpRole = (activeTeamId: string, teams?: TeamSearchResult[]) => {
  if (getIsTeamPersonal(activeTeamId) || !teams) return Roles.OWNER;

  const activeTeam = teams.find((item) => item.teamId === activeTeamId);

  return activeTeam?.membership?.cpRole;
};
