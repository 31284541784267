import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';
const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';
  return createStyles({
    inputAdornment: {
      // @ts-ignore
      pointerEvents: 'all !important',
      marginRight: theme.spacing(7),
      marginBottom: theme.spacing(1)
    },
    textField: {
      direction: theme.direction,
      borderRadius: theme.spacing(0.8),
      border: `1px solid ${theme.palette.shade4}`,
      background: `${theme.palette.shade7} !important`,
      '& .MuiInputBase-root': {
        opacity: 'inherit !important'
      },
      '& fieldset': {
        border: 'none'
      },
      '& label': {
        opacity: 'inherit !important'
      },
      '& input': {
        textOverflow: 'ellipsis',
        '-webkit-text-fill-color': `${theme.palette.shade1} !important`
      },
      '& .MuiInputBase-input': {
        color: theme.palette.shade1
      }
    },
    iconButton: {
      '&:hover': {
        filter: theme.palette.brightnessUp,
        background: 'none'
      },
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      position: 'absolute',
      display: 'flex',
      '&:nth-of-type(1)': {
        order: 1
      },
      '&:nth-of-type(2)': {
        right: isRTL ? 0 : 45
      },
      '& svg': {
        width: 25,
        height: 25,
        '& path': {
          fill: theme.palette.shade3
        }
      }
    },
    iconButtonIsCopied: {
      '& svg': {
        '& path': {
          fill: `${theme.palette.shade4} !important`
        }
      }
    }
  });
});
export default useStyles;