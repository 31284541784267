import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';
import ConfirmationDialog, { DialogType } from '@/components/ui/Dialog';
import type { JSX } from 'react';
const StepperModalCloseConfirmation = (onClosePublishDialog: () => void): {
  confirmationDialog: () => JSX.Element;
  handlePublishDialogClose: () => void;
} => {
  const {
    t
  } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const {
    reset,
    formState: {
      isDirty
    }
  } = useFormContext();
  const closePublishDialog = () => {
    onClosePublishDialog();
    reset();
  };
  const handlePublishDialogClose = () => {
    if (isDirty) setIsConfirmDialogOpen(true);else closePublishDialog();
  };
  const handleConfirmationDialogClose = () => setIsConfirmDialogOpen(false);
  const confirmPublishModalClose = () => {
    handleConfirmationDialogClose();
    closePublishDialog();
  };
  const confirmationDialog = <ConfirmationDialog open={isConfirmDialogOpen} dialogType={DialogType.INFO} title={t('projects.modal.exit-publishing-dialog.title')} description={t('projects.modal.exit-publishing-dialog.description')} onClose={handleConfirmationDialogClose} onSecondaryButton={handleConfirmationDialogClose} onPrimaryButton={confirmPublishModalClose} secondaryButtonText={t('button.go-back')} primaryButtonText={t('button.continue-and-exit')} dataTestId={'closePublishDialogConfirmation'} />;
  return {
    confirmationDialog: () => confirmationDialog,
    handlePublishDialogClose: () => handlePublishDialogClose()
  };
};
export default StepperModalCloseConfirmation;