import { useRouter } from 'next/router';

import { LinkCodeMeta, RatingLinkMetadata } from '@creator-portal/common/links-service/types';
import { LinkCodeData } from '@creator-portal/common/types';

import {
  GAME_DETAILS_FIELDS,
  MEDIA_FILES_FIELDS,
  NEW_CHANGES_FIELDS,
  RATING_FIELDS,
  STEPPER_KEYS,
} from '@/components/publishing/deploy-release-flow/stepper-types';
import { stepperItems } from '@/components/publishing/deploy-release-flow/stepper.config';

export const isStepHasErrors = (errors: string[], fields: string[]) => errors.some((item) => fields.includes(item));

export const getStepperKeyByIndex = (index: number) => stepperItems[index].key;

export const getRatingLinkMetadata = (
  linksCodeRatingsDataArray?: (LinkCodeMeta | undefined)[],
  privateVersion?: string,
): LinkCodeMeta | null => {
  if (!privateVersion || !linksCodeRatingsDataArray || (linksCodeRatingsDataArray && !linksCodeRatingsDataArray.length)) return null;

  const linkRatings = linksCodeRatingsDataArray.find((item) => item?.linkCode && item.linkCode === privateVersion);
  if (!linkRatings?.ratings) return null;

  return {
    cert_id: linkRatings.cert_id,
    ratings: {
      ...linkRatings.ratings,
    },
  };
};

export const validateCertificate = (
  currentCertificateId?: string,
  activeCertificateId?: string,
  isAttachedExistingRating?: boolean,
): string | null => {
  const router = useRouter();
  const applyCert = router?.query?.applyCert;

  // when we just came from IARC and got currentCertificateId
  // or if the PERMISSION.IARC_PORTABILITY is granted and an existing rating was attached.
  if ((applyCert || isAttachedExistingRating) && currentCertificateId) return currentCertificateId;

  // Require taking questionnaire - if Active Release DOES NOT have a rating
  const isFirstCaseForRatingRequired = !activeCertificateId;

  // Require taking questionnaire - when rating is expired (if we have a certificate but it is different from active release certificate)
  const isSecondCaseForRatingRequired = !!currentCertificateId && !!activeCertificateId && currentCertificateId !== activeCertificateId;

  const isRatingRequired = isFirstCaseForRatingRequired || isSecondCaseForRatingRequired;
  if (isRatingRequired) return null;

  // inheriting active certificate when No current certificate or certificates are the same
  const isCertificateInherited = !!activeCertificateId && (!currentCertificateId || currentCertificateId === activeCertificateId);
  if (isCertificateInherited) return activeCertificateId;

  return currentCertificateId || null;
};

export const getStepFieldsToValidate = (key: string, isNewChangesFieldExist: boolean) => {
  switch (key) {
    case STEPPER_KEYS.gameDetails: {
      if (isNewChangesFieldExist) {
        return [...Object.values(GAME_DETAILS_FIELDS), ...Object.values(NEW_CHANGES_FIELDS)];
      } else {
        return Object.values(GAME_DETAILS_FIELDS);
      }
    }
    case STEPPER_KEYS.rating: {
      return Object.values(RATING_FIELDS);
    }
    case STEPPER_KEYS.promotionalMedia: {
      return Object.values(MEDIA_FILES_FIELDS);
    }

    default:
      return [];
  }
};

export const validateCurrentStep = (
  stepIndex: number,
  validate: (steps: string[]) => Promise<boolean>,
  isNewChangesFieldExist: boolean,
) => {
  const currentKey = getStepperKeyByIndex(stepIndex);
  const stepsToValidate = getStepFieldsToValidate(currentKey, isNewChangesFieldExist);

  void validate(stepsToValidate);
};

export const preparePresetFileFullName = (presetFileName: string, file?: File): string => {
  if (!file) return '';

  const splitContentType = file.type.split('/');
  const extension = splitContentType.length > 1 ? splitContentType[1] : '';

  return `${presetFileName}.${extension}`;
};

export const preparePresetVideoFileFullName = (presetFileName: string, file?: File): string => {
  if (!file) return '';

  const splitFileName = file.name.split('.').pop();
  const extension = splitFileName || '';

  return `${presetFileName}.${extension}`;
};

export const prepareFileUploadUrl = (
  filesToUploadUrls: { [key: string]: { url: string } | undefined },
  fileFullName?: string,
): string | undefined => {
  if (!fileFullName) return undefined;

  return filesToUploadUrls[fileFullName]?.url;
};

export const getMediaFormatsFromArray = (formats: string[], searchValue: string) =>
  formats.map((item) => item.replace(searchValue, '')).join(' / ');
