import { TFunction } from 'i18next';
import * as yup from 'yup';

import { DISCOVERY_INTENT, GAME_FEATURE, LINK_CATEGORY } from '@creator-portal/common/links';
import {
  ACCEPTABLE_LEGO_SUPPORT_CODE,
  IARC_CERT_ID_LENGTH,
  IMG_ASPECT_RATIO,
  IMG_LOBBY_ASPECT_RATIO,
  IMG_MAX_SIZE,
  IMG_MIN_HEIGHT,
  IMG_MIN_WIDTH,
  IMG_SQUARE_ASPECT_RATIO,
  IMG_SQUARE_SIDE,
  IMG_SQUARE_SIZE,
  LEGO_FORBIDDEN_STRING_REGEXP,
  LOBBY_BG_HEIGHT,
  LOBBY_BG_MAX_SIZE,
  LOBBY_BG_WIDTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_GAME_TAGS,
  MAX_IARC_EMAIL_LENGTH,
  MAX_INTRODUCTION_LINE_LENGTH,
  MAX_NEW_CHANGES_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_GAME_TAGS,
  RATING_CERTIFICATE_MAX_SIZE,
  REQUIRED_INTRODUCTION_LINE_COUNT,
  SUPPORTED_GAME_TAGS,
  TITLE_FORBIDDEN_CHARACTERS,
  TITLE_FORBIDDEN_CHARACTERS_REGEXP,
  TRAILER_MAX_SIZE,
  TWD_FORBIDDEN_STRING_REGEXP,
} from '@creator-portal/common/publishing/constants';
import { ProjectDetailResult } from '@creator-portal/common/types';

import {
  GAME_DETAILS_FIELDS,
  MANAGE_RATING_FIELDS,
  MEDIA_FILES_FIELDS,
  NEW_CHANGES_FIELDS,
  RATING_FIELDS,
  VISIBILITY_FIELDS,
} from '@/components/publishing/deploy-release-flow/stepper-types';

import { getFileExtensionFromName } from '@/util/media';
import { isEditsApprovedOrUnpubDenied, VIOLATIONS_FORM_ERRORS } from '@/util/project/project-violations-utils';
import { checkProjectSysMetaIncludesGameFeature, checkProjectSysMetaIncludesLinkCategory } from '@/util/publishing';
import { ScreenApiValidaton } from '@/util/screenApiValidation';

import type { ProjectViolationsData } from '@/types/projects';

export const VALID_IMAGE_FORMATS = ['png', 'jpeg', 'jpg'];
export const VALID_VIDEO_FORMATS = ['mp4', 'mov', 'mkv', 'webm'];

const EMAIL_REGEX = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

type MediaToUpload = File | undefined;

const isValidFileType = (file: MediaToUpload, fileType: 'image' | 'video'): boolean => {
  if (!file) return true;

  const fileName = file.name.toLowerCase();
  const extension = getFileExtensionFromName(fileName);
  const validExtensions = fileType === 'image' ? VALID_IMAGE_FORMATS : VALID_VIDEO_FORMATS;

  return validExtensions.some((ext) => ext === extension);
};

export const checkMediaDimensions = (file: MediaToUpload, widthToCompare: number, heightToCompare: number): Promise<boolean> =>
  new Promise((resolve) => {
    if (!file || !isValidFileType(file, 'image')) {
      resolve(true);

      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      resolve(widthToCompare <= image.width && heightToCompare <= image.height);
    };
  });

const checkMediaSize = (file: MediaToUpload, sizeToCompare: number) => {
  if (!file) return true;

  return file.size <= sizeToCompare;
};

export const checkAspectRatio = (file: MediaToUpload, aspectRatio: number): Promise<boolean> =>
  new Promise((resolve) => {
    if (!file || !isValidFileType(file, 'image')) {
      resolve(true);

      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      resolve(image.width / image.height === aspectRatio);

      return;
    };
  });

type tFunction = TFunction<'common', undefined>;

const getIarcEmailRule = (t: tFunction) =>
  yup
    .string()
    .required(t('yup.validation.required'))
    .max(MAX_IARC_EMAIL_LENGTH, t('yup.validation.max', { limit: MAX_IARC_EMAIL_LENGTH }))
    .matches(EMAIL_REGEX, t('yup.validation.wrong-email2'));

const getIarcCertIdRule = (t: tFunction, certId?: string) =>
  yup.string().when(RATING_FIELDS.isIarcHasNotCertID, ([val], schema) => {
    if (!val || (typeof val === 'boolean' && val === false)) {
      return schema
        .required(t('yup.validation.required'))
        .max(IARC_CERT_ID_LENGTH, t('yup.validation.max', { limit: IARC_CERT_ID_LENGTH }))
        .matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, t('yup.validation.wrong-certificate-id'))
        .test('not-equal-certId', t('yup.validation.wrong-certificate-id.already-in-use'), (value) => !(certId && value === certId));
    }
    return schema;
  });

const screenApiValidation = ScreenApiValidaton.getInstance();

export const createYupSchemaForPublishingFlow = (
  t: tFunction,
  hasCertificateId: boolean,
  isProjectHasReleases: boolean,
  project: ProjectDetailResult,
  lastReleaseViolations: ProjectViolationsData | undefined,
  disableIarc?: boolean,
): yup.AnyObjectSchema => {
  const isAddLegoCheck =
    checkProjectSysMetaIncludesLinkCategory(project?.sysMeta, LINK_CATEGORY.LEGO) && project?.supportCode !== ACCEPTABLE_LEGO_SUPPORT_CODE;
  const isAddTwdCheck = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.TWDU);
  const isScreenApiValidationEnabled = !project?.meta?.skip_publish_screen;

  const getDefaultRequiredRule = (maxLength: number) =>
    yup
      .string()
      .trim()
      .required(t('yup.validation.required'))
      .max(maxLength, t('yup.validation.max-characters', { maxLength: maxLength }));

  const getViolationsRequiredRule = (maxLength: number) =>
    // note that we don't return translated text for test here, as it contains tags, that need to be replaced by the consuming component
    // we make sort of custom 'required' to be able to check the error type in the component and choose appropriate error message
    yup
      .string()
      .trim()
      .test(VIOLATIONS_FORM_ERRORS.requiredTitle, 'releases.infobox.new-release.game-title.error-message', (value) => !!value)
      .max(maxLength, t('yup.validation.max-characters', { maxLength: maxLength }));

  const getRequiredRule = (maxLength: number) => {
    if (lastReleaseViolations && isEditsApprovedOrUnpubDenied(lastReleaseViolations)) {
      return getViolationsRequiredRule(maxLength);
    }
    return getDefaultRequiredRule(maxLength);
  };

  const getTagsRule = () =>
    yup
      .array()
      .required(t('yup.validation.required'))
      .of(
        yup.string().test('is-valid-tag-name', t('meta.gameTags.invalidTag'), (value) => {
          if (!value) return true;
          else return SUPPORTED_GAME_TAGS.includes(value);
        }),
      )
      .min(MIN_GAME_TAGS, t('yup.validation.required'))
      .max(MAX_GAME_TAGS, t('yup.validation.max-tags', { maxGameTags: MAX_GAME_TAGS }));

  return yup.object({
    [GAME_DETAILS_FIELDS.title]: getRequiredRule(MAX_TITLE_LENGTH)
      .matches(TITLE_FORBIDDEN_CHARACTERS_REGEXP, (field) => {
        const value: string = field.value;
        // We want to break as soon as we find a match. eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const char of TITLE_FORBIDDEN_CHARACTERS) {
          if (value.includes(char)) {
            return t('meta.title.forbidden-characters', { char });
          }
        }

        return t('yup.validation.required'); // should not reach this ever
      })
      .test((value, ctx) => {
        if (isAddLegoCheck && value && LEGO_FORBIDDEN_STRING_REGEXP.test(value)) {
          return ctx.createError({ message: t('meta.title.forbidden-lego'), type: 'custom' });
        }
        if (isAddTwdCheck && value && TWD_FORBIDDEN_STRING_REGEXP.test(value)) {
          return ctx.createError({ message: t('meta.title.forbidden-twd'), type: 'custom' });
        }
        return true;
      })
      .test('screening', t('yup.validation.screening-rejection'), () => !isScreenApiValidationEnabled || screenApiValidation.isTitleSafe()),
    [GAME_DETAILS_FIELDS.tagline]: getDefaultRequiredRule(MAX_DESCRIPTION_LENGTH)
      .test((value, ctx) => {
        if (isAddLegoCheck && LEGO_FORBIDDEN_STRING_REGEXP.test(value)) {
          return ctx.createError({ message: t('meta.description.forbidden-lego') });
        }
        return true;
      })
      .test(
        'screening',
        t('yup.validation.screening-rejection'),
        () => !isScreenApiValidationEnabled || screenApiValidation.isTaglineSafe(),
      ),
    [GAME_DETAILS_FIELDS.tags]: getTagsRule(),
    ...(isProjectHasReleases ? { [NEW_CHANGES_FIELDS.changes]: getDefaultRequiredRule(MAX_NEW_CHANGES_LENGTH) } : {}),
    [GAME_DETAILS_FIELDS.introduction]: yup
      .array()
      .of(
        yup
          .string()
          .max(MAX_INTRODUCTION_LINE_LENGTH, t('yup.validation.max-characters', { maxLength: MAX_INTRODUCTION_LINE_LENGTH }))
          .test((value, ctx) => {
            if (isAddLegoCheck && value && LEGO_FORBIDDEN_STRING_REGEXP.test(value)) {
              return ctx.createError({ message: t('meta.instructions.forbidden-lego') });
            }
            return true;
          })
          .test('screening', t('yup.validation.screening-rejection'), (value: string | undefined, context: yup.TestContext) => {
            const index = parseInt(context.path.charAt(13));
            if (isNaN(index)) return true;
            return screenApiValidation.isIntroSafe(index);
          }),
      )
      .max(REQUIRED_INTRODUCTION_LINE_COUNT, `Max bullets count is ${REQUIRED_INTRODUCTION_LINE_COUNT}`),

    // rating step
    ...(hasCertificateId || disableIarc
      ? {}
      : {
          [RATING_FIELDS.iarcEmail]: getIarcEmailRule(t),
          [RATING_FIELDS.iarcPublicEmail]: yup
            .string()
            .transform((value: string) => (value === '' ? undefined : value))
            .when(RATING_FIELDS.isIarcHasNotPublicEmail, ([val], schema) => {
              if (val) {
                return schema
                  .notRequired()
                  .nullable()
                  .matches(EMAIL_REGEX, t('yup.validation.wrong-email2'))
                  .max(MAX_IARC_EMAIL_LENGTH, t('yup.validation.max', { limit: MAX_IARC_EMAIL_LENGTH }));
              } else if (typeof val === 'boolean' && val === false) {
                return schema
                  .matches(EMAIL_REGEX, t('yup.validation.wrong-email2'))
                  .max(MAX_IARC_EMAIL_LENGTH, t('yup.validation.max', { limit: MAX_IARC_EMAIL_LENGTH }))
                  .required(t('yup.validation.required'));
              } else {
                return schema;
              }
            }),
          [RATING_FIELDS.iarcCertId]: getIarcCertIdRule(t),
        }),

    [MEDIA_FILES_FIELDS.existingImageUrl]: yup.string(),
    [MEDIA_FILES_FIELDS.imageToUpload]: yup
      .mixed()
      .when([MEDIA_FILES_FIELDS.existingImageUrl], {
        is: (isAlreadyLoaded) => !isAlreadyLoaded,
        then: (schema) => schema.required(t('yup.validation.required')),
      })
      .test('is-valid-landscape-image-type', t('meta.gameImage.imageTypeError', { formats: VALID_IMAGE_FORMATS.join(', ') }), (value) =>
        isValidFileType(value as MediaToUpload, 'image'),
      )
      .test('is-valid-landscape-image-size', t('meta.gameImage.imageSizeError', { maxSizeInMB: IMG_MAX_SIZE / 1024 / 1024 }), (value) =>
        checkMediaSize(value as MediaToUpload, IMG_MAX_SIZE),
      )
      .test(
        'is-valid-landscape-image-aspect-ratio',
        t('meta.gameImage.imageMustAspectRatioError'),
        async (value) => await checkAspectRatio(value as MediaToUpload, IMG_ASPECT_RATIO),
      )
      .test(
        'is-valid-image-dimension',
        t('meta.gameImage.imageResolutionError', { width: IMG_MIN_WIDTH, height: IMG_MIN_HEIGHT }),
        async (value) => await checkMediaDimensions(value as MediaToUpload, IMG_MIN_WIDTH, IMG_MIN_HEIGHT),
      ),
    [MEDIA_FILES_FIELDS.squareImageToUpload]: yup
      .mixed()
      .notRequired()
      .test('is-valid-square-image-type', t('meta.gameImage.imageTypeError', { formats: VALID_IMAGE_FORMATS.join(', ') }), (value) =>
        isValidFileType(value as MediaToUpload, 'image'),
      )
      .test('is-valid-square-image-size', t('meta.gameImage.imageSizeError', { maxSizeInMB: IMG_SQUARE_SIZE / 1000 / 1000 }), (value) =>
        checkMediaSize(value as MediaToUpload, IMG_SQUARE_SIZE),
      )
      .test(
        'is-valid-square-image-aspect-ratio',
        t('meta.gameImage.squareImageMustAspectRatioError'),
        async (value) => await checkAspectRatio(value as MediaToUpload, IMG_SQUARE_ASPECT_RATIO),
      )
      .test(
        'is-valid-image-dimension',
        t('meta.gameImage.imageResolutionError', { width: IMG_SQUARE_SIDE, height: IMG_SQUARE_SIDE }),
        async (value) => await checkMediaDimensions(value as MediaToUpload, IMG_SQUARE_SIDE, IMG_SQUARE_SIDE),
      ),
    [MEDIA_FILES_FIELDS.videoToUpload]: yup
      .mixed()
      .notRequired()
      .test('is-valid-trailer-type', t('meta.gameTrailer.videoTypeError', { formats: VALID_VIDEO_FORMATS.join(', ') }), (value) =>
        isValidFileType(value as MediaToUpload, 'video'),
      )
      .test('is-valid-trailer-size', t('yup.validation.max-trailer-size', { trailerMaxSize: TRAILER_MAX_SIZE / 1024 / 1024 }), (value) =>
        checkMediaSize(value as MediaToUpload, TRAILER_MAX_SIZE),
      ),
    [MEDIA_FILES_FIELDS.lobbyImageToUpload]: yup
      .mixed()
      .notRequired()
      .test('is-valid-lobby-image-type', t('meta.gameImage.imageTypeError', { formats: VALID_IMAGE_FORMATS.join(', ') }), (value) =>
        isValidFileType(value as MediaToUpload, 'image'),
      )
      .test('is-valid-lobby-image-size', t('meta.gameImage.imageSizeError', { maxSizeInMB: LOBBY_BG_MAX_SIZE / 1000 / 1000 }), (value) =>
        checkMediaSize(value as MediaToUpload, LOBBY_BG_MAX_SIZE),
      )
      .test(
        'is-valid-lobby-image-aspect-ratio',
        t('meta.gameImage.lobbyImageMustAspectRatioError'),
        async (value) => await checkAspectRatio(value as MediaToUpload, IMG_LOBBY_ASPECT_RATIO),
      )
      .test(
        'is-valid-lobby-image-dimension',
        t('meta.gameImage.imageResolutionError', { width: LOBBY_BG_WIDTH, height: LOBBY_BG_HEIGHT }),
        async (value) => await checkMediaDimensions(value as MediaToUpload, LOBBY_BG_WIDTH, LOBBY_BG_HEIGHT),
      ),

    [VISIBILITY_FIELDS.discoveryIntent]: yup.mixed<DISCOVERY_INTENT>().oneOf(Object.values(DISCOVERY_INTENT)).required(),
  });
};

export const createSchemaForEditIarcEmail = (t: tFunction): yup.AnyObjectSchema =>
  yup.object({
    [RATING_FIELDS.iarcEmail]: getIarcEmailRule(t),
  });

export const createSchemaForEditIarcCertId = (t: tFunction, certId?: string): yup.AnyObjectSchema =>
  yup.object({
    [RATING_FIELDS.iarcCertId]: getIarcCertIdRule(t, certId),
  });

export const createSchemaForEditIarcRating = (t: tFunction, fileIsRequired: boolean): yup.AnyObjectSchema =>
  yup.object({
    [MANAGE_RATING_FIELDS.file]: yup
      .mixed()
      .nullable()
      .when([], {
        is: () => fileIsRequired,
        then: (schema) =>
          schema
            .required(t('yup.validation.required'))
            .test('fileType', t('yup.validation.wrong-rating-certificate-file'), (value) => {
              if (!value || typeof value !== 'object' || !('type' in value)) return false;
              const allowedTypes = ['application/pdf'];
              return allowedTypes.includes((value as { type: string }).type);
            })
            .test(
              'fileSize',
              t('yup.validation.wrong-rating-certificate-file-size', { maxSizeInMB: RATING_CERTIFICATE_MAX_SIZE }),
              (value) => {
                if (!value || typeof value !== 'object' || !('size' in value)) return false;
                return (value as { size: number }).size <= RATING_CERTIFICATE_MAX_SIZE * 1024 * 1024;
              },
            ),
        otherwise: (schema) =>
          schema
            .nullable()
            .test('fileType', t('yup.validation.wrong-rating-certificate-file'), (value) => {
              if (!value || typeof value !== 'object' || !('type' in value)) return true;
              const allowedTypes = ['application/pdf'];
              return allowedTypes.includes((value as { type: string }).type);
            })
            .test(
              'fileSize',
              t('yup.validation.wrong-rating-certificate-file-size', { maxSizeInMB: RATING_CERTIFICATE_MAX_SIZE }),
              (value) => {
                if (!value || typeof value !== 'object' || !('size' in value)) return true;
                return (value as { size: number }).size <= RATING_CERTIFICATE_MAX_SIZE * 1024 * 1024;
              },
            ),
      }),
    [MANAGE_RATING_FIELDS.ageRating]: yup.number().required(t('yup.validation.required')),
    [MANAGE_RATING_FIELDS.descriptors]: yup.string(),
    [MANAGE_RATING_FIELDS.elements]: yup.string(),
  });
