import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import { useSWRConfig } from 'swr';
import { IarcPortabilityAttachRequest, IarcPortabilityRequest, LoadCertResult, UpdateTraditionalRating } from '@creator-portal/common/iarc/types';
import { LinkCodeInfo } from '@creator-portal/common/types';
import { attachExistingRating, deleteRating, editRating, IarcUrl, searchExistingCertID, updateIarcEmail, uploadRatingFileCertificate } from '@/services/iarc/iarc-service';
import PrefilledInputComponent from '@/components/common/prefilled-input';
import { EMAIL_FIELDS, IarcEmailData, RATING_OPERATIONS } from '@/components/publishing/deploy-release-flow/stepper-types';
import EditIarcCertIdDialog from '@/components/publishing/deploy-release-flow/steps/form-parts/edit-iarc-cert-id-dialog';
import EditIarcEmailDialog from '@/components/publishing/deploy-release-flow/steps/form-parts/edit-iarc-email-dialog';
import EditIarcRatingDialog from '@/components/publishing/deploy-release-flow/steps/form-parts/edit-iarc-rating-dialog';
import RatingTable from '@/components/publishing/deploy-release-flow/steps/form-parts/ratings-table';
import { RatingStatus } from '@/components/publishing/ratings-dialog.component';
import Button from '@/components/ui/Button';
import Dialog, { DialogType } from '@/components/ui/Dialog';
import { trackClickInteraction } from '@/util/tracking';
import { ErrorPayload } from '@/util/xhr';
import useStyles from './ratings-display.styles';
import type { RatingData } from '@/util/ratingFunctions';
import type { JSX } from 'react';
export const COMPONENT_SOURCE_TYPES = {
  ratingReceived: 'ratingReceived',
  ratingDialog: 'ratingDialog'
};
interface RatingsDisplayComponentProps {
  componentSourceTypes: string;
  productName: string | null;
  date: string;
  email: string;
  publicDeveloperEmail: string | null;
  status: RatingStatus;
  certificateId: string | null;
  projectId: string;
  data: RatingData[] | null;
  buildCode?: LinkCodeInfo;
  hasRatingInfoByCert?: boolean;
  handleSetExistingRating?: (existingRating: LoadCertResult | null) => void;
  handleUpdatingRatingData?: (operation: RATING_OPERATIONS) => void;
  hasIarcPortability: boolean;
}
const RatingsDisplayComponent: React.FC<RatingsDisplayComponentProps> = ({
  componentSourceTypes,
  productName,
  date,
  email: defaultEmail,
  publicDeveloperEmail: defaultPublicEmail,
  status,
  certificateId,
  projectId,
  data = [],
  buildCode,
  hasRatingInfoByCert = false,
  handleSetExistingRating = () => {},
  handleUpdatingRatingData = () => {},
  hasIarcPortability
}): JSX.Element => {
  const {
    t,
    i18n
  } = useTranslation();
  const classes = useStyles();
  const urlForCertificateDetails = certificateId ? IarcUrl.ratingInfo(projectId, certificateId) : null;
  const {
    mutate
  } = useSWRConfig();
  const isBlockedActions = componentSourceTypes === COMPONENT_SOURCE_TYPES.ratingDialog;
  const [isEditIarcEmailDialogOpen, setIsEditIarcEmailDialogOpen] = useState<boolean>(false);
  const [emailFieldName, setEmailFieldName] = useState<EMAIL_FIELDS>(EMAIL_FIELDS.EMAIL);
  const [isUpdatingIarcEmail, setIsUpdatingIarcEmail] = useState(false);
  const iarcEmailErrorText = t('edit-iarc-email-dialog.toast.rename.error');
  const defaultEmails = {
    [EMAIL_FIELDS.EMAIL]: defaultEmail,
    [EMAIL_FIELDS.PUBLIC_EMAIL]: defaultPublicEmail || defaultEmail
  };
  const [emails, setEmail] = useState({
    ...defaultEmails
  });
  const [isEditIarcCertIdDialogOpen, setIsEditIarcCertIdDialogOpen] = useState<boolean>(false);
  const [isUpdatingIarcCertId, setisUpdatingIarcCertId] = useState(false);
  const [iarcCertIdErrorMessage, setIarcCertIdErrorMessage] = useState<string | null>(null);
  const [certId, setCertId] = useState<string | null>(certificateId);
  const [isReplaceIarcRatingDialogOpen, setIsReplaceIarcRatingDialogOpen] = useState<boolean>(false);
  const [isUpdatingIarcRating, setisUpdatingIarcRating] = useState(false);
  const [rating, setRating] = useState<RatingData | null>(null);
  const handleSetIarcRating = (item: RatingData | null) => {
    setRating(item);
    setIsReplaceIarcRatingDialogOpen(!!item);
  };
  const [isRevertToIARCDialogOpen, setIsRevertToIARCDialogOpen] = useState<boolean>(false);
  const [isBlockedConfirmBtn, setIsBlockedConfirmBtn] = useState(false);
  useEffect(() => {
    setEmail(defaultEmails);
  }, [defaultEmail]);
  useEffect(() => {
    setCertId(certificateId);
  }, [certificateId]);
  const handleUpdateIarcEmail = async (data: IarcEmailData): Promise<void> => {
    trackClickInteraction({
      eventCategory: 'Iarc',
      eventAction: 'update email'
    });
    setIsUpdatingIarcEmail(true);
    try {
      if (!certId || !data?.[EMAIL_FIELDS.EMAIL] && !data?.[EMAIL_FIELDS.PUBLIC_EMAIL]) {
        toast.error(iarcEmailErrorText);
        console.log('Iarc email is missing data', data);
        return;
      }
      await updateIarcEmail(projectId, certId, data);
      setEmail({
        ...emails,
        ...data
      });
      await toast.promise(mutate(urlForCertificateDetails), {
        success: t('edit-iarc-email-dialog.toast.rename.success'),
        error: iarcEmailErrorText
      });
    } catch (error) {
      console.log('Error while renaming Iarc email');
    } finally {
      setIsEditIarcEmailDialogOpen(false);
      setIsUpdatingIarcEmail(false);
    }
  };
  const handleSearchExistingCertID = async (newCertId: string) => {
    trackClickInteraction({
      eventCategory: 'Iarc',
      eventAction: 'update CertID'
    });
    setisUpdatingIarcCertId(true);
    setIarcCertIdErrorMessage(null);
    try {
      const model: IarcPortabilityRequest = {
        certId: newCertId,
        contactEmail: emails[EMAIL_FIELDS.EMAIL],
        publicDeveloperEmail: emails[EMAIL_FIELDS.PUBLIC_EMAIL]
      };
      const existingRating = await searchExistingCertID(projectId, model);
      handleSetExistingRating(existingRating || null);
      setCertId(existingRating?.certId || null);
      setIsEditIarcCertIdDialogOpen(false);
    } catch (error) {
      // t('edit-iarc-cert-id-dialog.error.not_found')
      // t('edit-iarc-cert-id-dialog.error.not_supported')
      const errorCode = (error as ErrorPayload)?.errorCode ?? '';
      if (['not_found', 'not_supported'].includes(errorCode.split('.').pop() || '')) {
        setIarcCertIdErrorMessage(t(`edit-iarc-cert-id-dialog.error.${errorCode.split('.').pop()}`));
      } else {
        toast.error(t('error-description'));
      }
    } finally {
      setisUpdatingIarcCertId(false);
    }
  };
  const handleIarcCertIdDialogClose = () => {
    setIarcCertIdErrorMessage(null);
    setIsEditIarcCertIdDialogOpen(false);
  };
  const handleAttachExistingRating = async () => {
    if (certificateId && buildCode) {
      trackClickInteraction({
        eventCategory: 'Iarc',
        eventAction: 'attach the CertID'
      });
      setIsBlockedConfirmBtn(true);
      try {
        const model: IarcPortabilityAttachRequest = {
          certId: certificateId,
          contactEmail: emails[EMAIL_FIELDS.EMAIL],
          publicDeveloperEmail: emails[EMAIL_FIELDS.PUBLIC_EMAIL] || emails[EMAIL_FIELDS.EMAIL],
          linkCode: buildCode && buildCode.linkCode,
          version: buildCode && buildCode.version
        };
        await attachExistingRating(projectId, model);
        handleSetExistingRating(null);
        handleUpdatingRatingData(RATING_OPERATIONS.attachExistingRating);
      } catch (error) {
        // t('edit-iarc-cert.attach.error.invalid_request')
        // t('edit-iarc-cert.attach.error.cert_duplicated')
        const errorCode = (error as ErrorPayload)?.errorCode ?? '';
        if (['invalid_request', 'cert_duplicated'].includes(errorCode.split('.').pop() || '')) {
          toast.error(t(`edit-iarc-cert.attach.error.${errorCode.split('.').pop()}`));
        } else {
          toast.error(t('error-description'));
        }
      } finally {
        setIsBlockedConfirmBtn(false);
      }
    }
  };
  const handleChangeIarcRating = async (ratingData: UpdateTraditionalRating | null, file: File | null) => {
    if (certId && buildCode && rating) {
      trackClickInteraction({
        eventCategory: 'Iarc',
        eventAction: 'update rating'
      });
      setisUpdatingIarcRating(true);
      try {
        if (ratingData) {
          await editRating(projectId, certId, {
            rating: ratingData,
            ...buildCode
          });
          handleUpdatingRatingData(RATING_OPERATIONS.refreshCertDetails);
        }
        if (file) {
          await uploadRatingFileCertificate(projectId, certId, rating.ratingBoardId, file);
        }
      } catch (error) {
        // t('edit-iarc-rating-dialog.error.invalid_upload')
        const errorCode = (error as ErrorPayload)?.errorCode ?? '';
        if (['invalid_upload'].includes(errorCode.split('.').pop() || '')) {
          toast.error(t(`edit-iarc-rating-dialog.error.${errorCode.split('.').pop()}`));
        } else {
          toast.error(t('error-description'));
        }
      }
      setIsReplaceIarcRatingDialogOpen(false);
      setisUpdatingIarcRating(false);
      setRating(null);
    }
  };
  const handleOpenRevertToIARCDialog = () => {
    setIsReplaceIarcRatingDialogOpen(false);
    setIsRevertToIARCDialogOpen(true);
  };
  const handleCloseRevertToIARCDialog = () => {
    setIsRevertToIARCDialogOpen(false);
    setRating(null);
  };
  const handleRevertToIARC = async () => {
    if (certId && buildCode && rating) {
      trackClickInteraction({
        eventCategory: 'Iarc',
        eventAction: 'revert to IARC'
      });
      setisUpdatingIarcRating(true);
      try {
        await deleteRating(projectId, certId, {
          ratingBoardId: rating.ratingBoardId,
          ...buildCode
        });
        handleUpdatingRatingData(RATING_OPERATIONS.refreshCertDetails);
      } catch (error) {
        toast.error(t('error-description'));
      }
      setIsRevertToIARCDialogOpen(false);
      setisUpdatingIarcRating(false);
      setRating(null);
    }
  };
  const getFormattedDate = () => {
    if (!date) return '';
    return new Date(date).toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  return <>
      <div className={classes.ratingsDisplay}>
        {hasIarcPortability && <div className={classes.textFieldsLineByLine}>
            <PrefilledInputComponent label={t('projects.modal.ratings.product-name-label')} value={productName || ''} />
          </div>}
        <div className={classes.textFieldsBlock}>
          <PrefilledInputComponent hasEditButton={!!hasIarcPortability && !hasRatingInfoByCert && !isBlockedActions} hasCopyButton={!!hasIarcPortability} openEditDialog={() => setIsEditIarcCertIdDialogOpen(true)} label={!hasIarcPortability ? t('projects.modal.ratings.certificate-id-label') : t('projects.modal.ratings.iarc-certificate-id.placeholder')} value={certificateId} data-sentry-element="PrefilledInputComponent" data-sentry-source-file="ratings-display.tsx" />
          {hasIarcPortability && <PrefilledInputComponent label={t('projects.modal.ratings.certificate-date')} value={getFormattedDate()} />}
          {!hasRatingInfoByCert && <PrefilledInputComponent hasEditButton={true} openEditDialog={() => {
          setIsEditIarcEmailDialogOpen(true);
          setEmailFieldName(EMAIL_FIELDS.EMAIL);
        }} label={!hasIarcPortability ? t('projects.modal.ratings.email-label.new') : t('projects.modal.ratings.email-label.new.iarc-portability')} value={emails[EMAIL_FIELDS.EMAIL]} />}
          {hasIarcPortability && !hasRatingInfoByCert && <PrefilledInputComponent hasEditButton={true} openEditDialog={() => {
          setIsEditIarcEmailDialogOpen(true);
          setEmailFieldName(EMAIL_FIELDS.PUBLIC_EMAIL);
        }} label={!hasIarcPortability ? t('projects.modal.ratings.public-email.placeholder') : t('projects.modal.ratings.public-email.placeholder.iarc-portability')} value={emails[EMAIL_FIELDS.PUBLIC_EMAIL]} />}
        </div>
        <RatingTable data={data} status={status} handleSetIarcRating={handleSetIarcRating} hasRatingInfoByCert={hasRatingInfoByCert} hasIarcPortability={hasIarcPortability} isBlockedActions={isBlockedActions} data-sentry-element="RatingTable" data-sentry-source-file="ratings-display.tsx" />
        {hasIarcPortability && hasRatingInfoByCert && <div className={classes.dRatingInfoByCert}>
            <Button variant="outlined" onClick={() => handleSetExistingRating(null)} disabled={isBlockedConfirmBtn}>
              {t('button.go-back')}
            </Button>
            <Button onClick={handleAttachExistingRating} disabled={isBlockedConfirmBtn}>
              {t('button.confirm-ratings')}
            </Button>
          </div>}
      </div>
      <EditIarcEmailDialog isOpen={isEditIarcEmailDialogOpen} email={emails[emailFieldName]} isLoading={isUpdatingIarcEmail} onSubmit={handleUpdateIarcEmail} onClose={() => setIsEditIarcEmailDialogOpen(false)} fieldName={emailFieldName} hasIarcPortability={hasIarcPortability} data-sentry-element="EditIarcEmailDialog" data-sentry-source-file="ratings-display.tsx" />
      {hasIarcPortability && !isBlockedActions && certId && <EditIarcCertIdDialog isOpen={isEditIarcCertIdDialogOpen} certId={certId} isLoading={isUpdatingIarcCertId} onSubmit={handleSearchExistingCertID} onClose={handleIarcCertIdDialogClose} errorMessage={iarcCertIdErrorMessage} hasIarcPortability={hasIarcPortability} />}
      {hasIarcPortability && rating && certId && <EditIarcRatingDialog isOpen={isReplaceIarcRatingDialogOpen} isLoading={isUpdatingIarcRating} onSubmit={handleChangeIarcRating} onClose={() => handleSetIarcRating(null)} rating={rating} projectId={projectId} certId={certId} handleOpenRevertToIARCDialog={handleOpenRevertToIARCDialog} />}
      {hasIarcPortability && !isBlockedActions && rating && buildCode && certId && <Dialog open={isRevertToIARCDialogOpen} dialogType={DialogType.INFO_NEW} title={t('edit-iarc-rating-dialog.delete-title', {
      ratingBoardShortText: rating.ratingBoardShortText
    })} description={t('edit-iarc-rating-dialog.delete-text', {
      ratingBoardShortText: rating.ratingBoardShortText
    })} secondaryButtonText={t('button.cancel')} onSecondaryButton={handleCloseRevertToIARCDialog} primaryButtonText={t('button.continue')} onPrimaryButton={handleRevertToIARC} onClose={handleCloseRevertToIARCDialog} isPrimaryButtonDisabled={isUpdatingIarcRating} isSecondaryButtonDisabled={isUpdatingIarcRating} displayCloseButton={false} />}
    </>;
};
export default RatingsDisplayComponent;