import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, Typography } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { IARC_CERT_ID_LENGTH } from '@creator-portal/common/publishing/constants';
import { IARC_RATING_ONLINE_PRIVACY_URL } from '@/config/common/constants';
import { getPrivacyUrl } from '@/config/common/functions';
import { createSchemaForEditIarcCertId } from '@/services/publishing/publishing-validations';
import { RATING_FIELDS } from '@/components/publishing/deploy-release-flow/stepper-types';
import Dialog, { DialogType } from '@/components/ui/Dialog';
import TextField from '@/components/ui/TextField';
import useStyles from './edit-iarc-dialog.styles';
import type { JSX } from 'react';
export interface IarcCertIdValues {
  iarcCertId: string;
}
interface EditIarcCertIdDialogProps {
  onSubmit: (newCertId: string) => void;
  onClose: () => void;
  certId: string;
  isOpen: boolean;
  isLoading: boolean;
  errorMessage: string | null;
  hasIarcPortability: boolean;
}
const EditIarcCertIdDialog: React.FC<EditIarcCertIdDialogProps> = ({
  certId = '',
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  errorMessage,
  hasIarcPortability
}): JSX.Element => {
  const {
    t,
    i18n
  } = useTranslation();
  const classes = useStyles();
  const schema = useMemo(() => createSchemaForEditIarcCertId(t, certId), [t, certId]);
  const {
    setValue,
    trigger,
    watch,
    clearErrors,
    setError,
    reset,
    formState: {
      errors
    }
  } = useForm<IarcCertIdValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      [RATING_FIELDS.iarcCertId]: ''
    }
  });
  const {
    iarcCertId: iarcCertIdState
  } = watch();
  const handleCertIdChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(RATING_FIELDS.iarcCertId, e.target.value);
    await trigger(RATING_FIELDS.iarcCertId);
  };
  const handleCertIdSubmit = (): void => {
    onSubmit(iarcCertIdState);
  };
  useEffect(() => {
    if (errorMessage) {
      setError(RATING_FIELDS.iarcCertId, {
        type: 'manual',
        message: errorMessage
      });
    } else {
      clearErrors(RATING_FIELDS.iarcCertId);
    }
    return () => {
      clearErrors();
      reset();
    };
  }, [isLoading, certId, isOpen, errorMessage]);
  const isErrorOccur = !!errors.iarcCertId?.message;
  // const isNotEqualWithDefaultValue = iarcCertIdState?.trim() !== certId;

  const footerContent = <Typography component="p" className={classes.footerContent}>
      <Trans i18nKey="edit-iarc-email-dialog.footer-text" components={{
      a: <a href={IARC_RATING_ONLINE_PRIVACY_URL} target="_blank" rel="noopener noreferrer" />,
      a2: <a href={getPrivacyUrl(i18n.language)} target="_blank" rel="noopener noreferrer" />
    }} />
    </Typography>;
  return <Dialog open={isOpen} onClose={onClose} dialogType={DialogType.INFO} title={t('edit-iarc-cert-id-dialog.title')} footerContent={footerContent} className={classes.dialog} primaryButtonText={t('button.save')} secondaryButtonText={t('button.close')} onSecondaryButton={onClose} onPrimaryButton={handleCertIdSubmit} isSecondaryButtonDisabled={isLoading} isPrimaryButtonDisabled={isLoading || isErrorOccur || !iarcCertIdState} data-sentry-element="Dialog" data-sentry-component="EditIarcCertIdDialog" data-sentry-source-file="edit-iarc-cert-id-dialog.tsx">
      <Typography component="p" className={classes.description} data-sentry-element="Typography" data-sentry-source-file="edit-iarc-cert-id-dialog.tsx">
        {t('edit-iarc-cert-id-dialog.description')}
      </Typography>
      <div className={classes.textFieldBlock}>
        <TextField maxLength={!hasIarcPortability ? IARC_CERT_ID_LENGTH : undefined} error={!!errors[RATING_FIELDS.iarcCertId]?.message} value={iarcCertIdState} name={RATING_FIELDS.iarcCertId} label={<>
              {t('projects.modal.ratings.iarc-certificate-id.placeholder')} <span className={classes.spanRequired}>*</span>
            </>} onChange={handleCertIdChange} data-sentry-element="TextField" data-sentry-source-file="edit-iarc-cert-id-dialog.tsx" />
        <FormHelperText error data-sentry-element="FormHelperText" data-sentry-source-file="edit-iarc-cert-id-dialog.tsx">{errors[RATING_FIELDS.iarcCertId]?.message || ' '}</FormHelperText>
      </div>
    </Dialog>;
};
export default EditIarcCertIdDialog;