'use client';

import { FormEvent, useEffect, useState } from 'react';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { ToastContainer } from 'react-toastify';
import { AuthAction, canAdminAccess } from '@creator-portal/common/auth';
import { LOGOUT_URL } from '@/config/common/constants';
import NavigationBottomMobile from '@/components/common/navigation/navigation-bottom-mobile.component';
import SlidingPanelMobileComponent from '@/components/common/navigation/sliding-panel-mobile.component';
import CancelUploadDialog from '@/components/media/cancel-upload-dialog/cancel-upload-dialog';
import Dialog, { DialogType } from '@/components/ui/Dialog';
import { useAuthSession } from '@/hooks/useAuthSession';
import getEventPath from '@/util/getEventPath';
import useToastStyles from './alerts/toast.styles';
import { MainFooterComponent } from './main.footer.component';
import MainHeaderComponent from './main.header.component';
import useStyles from './main.layout.styles';
import MainNavigationComponent from './main.navigation.component';
import 'react-toastify/dist/ReactToastify.css';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import { isMainNavigationCollapsed } from '@/services/common/navigationUtils';
import { ErrorBoundary } from '@/components/common/error-boundary';
import { usePublicRuntimeConfig } from '@/hooks/use-public-runtime-config';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';
import { useTeams } from '@/hooks/useTeams';
import type { JSX } from 'react';
const AdminHeaderComponent = dynamic(() => import('./admin.header.component'));
type BackGrounds = 'standardRootBackground' | 'enrollBackground';
// TODO: Look into using just client-side fetching for teams here instead of having to pass it from every page
interface MainLayoutProps {
  children: React.ReactNode;
  isMediaUploading?: boolean;
  isDataChanged?: boolean;
  dialogConfirm?: (redirectUrl?: string) => void;
  isJoinMemberPage?: boolean;
  backgroundClassName?: BackGrounds;
  hideNavigation?: boolean;
  isHideMainNavigation?: boolean;
}
const TOAST_AUTOCLOSE_SECONDS = 2000; // in ms

export default function MainLayout({
  isMediaUploading,
  dialogConfirm,
  children,
  isDataChanged,
  isJoinMemberPage,
  backgroundClassName = 'standardRootBackground',
  hideNavigation = false,
  isHideMainNavigation = false
}: MainLayoutProps): JSX.Element {
  const theme = useTheme();
  const {
    teams
  } = useTeams();
  const isRTL = theme.direction === 'rtl';
  const lessThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [mounted, setMounted] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileSlideOpen, setMobileSlideOpen] = useState(false);
  const classes = useStyles();
  const toastStyles = useToastStyles();
  const {
    t
  } = useTranslation();
  const user = useAuthSession();
  const [isCancelUploadDialogOpen, setIsCancelUploadDialogOpen] = useState<boolean>(false);
  const [isLeavePageDialogOpen, setIsLeavePageDialogOpen] = useState<boolean>(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState<boolean>(false);
  const [newRoute, setNewRoute] = useState<string | undefined>(undefined);
  const [newExternalRoute, setNewExternalRoute] = useState<string | undefined>(undefined);
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const creatorProgramStatus = useCreatorProgramStatus();
  const {
    EPIC_DEPLOYMENT
  } = usePublicRuntimeConfig();
  const isProjectSubNavVisible = isMainNavigationCollapsed(user, params);
  const handleDrawerToggle = (mode: 'open' | 'close') => {
    if (mode === 'open') {
      setMobileOpen(true);
      setMobileSlideOpen(false);
    } else {
      setMobileOpen(false);
      setMobileSlideOpen(false);
    }
  };
  const handleMobileSlideToggle = () => {
    setMobileSlideOpen(!mobileSlideOpen);
  };
  const handleDialogCancel = () => {
    setIsCancelUploadDialogOpen(false);
    setIsLeavePageDialogOpen(false);
    setIsLogoutDialogOpen(false);
    setNewRoute(undefined);
    setNewExternalRoute(undefined);
  };
  const handleDialogConfirm = () => {
    dialogConfirm && dialogConfirm(newRoute);
    setIsCancelUploadDialogOpen(false);
    setIsLeavePageDialogOpen(false);
    setIsLogoutDialogOpen(false);
    if (newExternalRoute) {
      window.open(newExternalRoute, '_self', 'noopener, noreferrer');
      setNewExternalRoute(undefined);
    } else {
      newRoute && void router.push(newRoute);
    }
  };
  const handleLogoutClick = (e?: FormEvent<Element>) => {
    if (isMediaUploading || isDataChanged) {
      e && e.preventDefault();
      setNewRoute && setNewRoute(LOGOUT_URL);
      isMediaUploading && setIsCancelUploadDialogOpen(true);
      isDataChanged && setIsLogoutDialogOpen(true);
    } else {
      void router.push(LOGOUT_URL);
    }
  };

  // Handle beforeunload event to warn user tries to leave the page via browser controls when unsaved changes
  useEffect(() => {
    if (isMediaUploading || isDataChanged) {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isMediaUploading, isDataChanged]);

  // Intercepts navigation initiated by clicking on links within the app. It prevents navigation and shows a dialog if there are unsaved changes.
  const handleClick = (e: React.MouseEvent) => {
    if (isMediaUploading || isDataChanged) {
      const path = getEventPath(e);
      const anchor = path.find(el => el) as HTMLAnchorElement | undefined;
      if (anchor && anchor.href && anchor.target !== '_blank') {
        const href = anchor.getAttribute('href');
        if (href && !href.startsWith('#')) {
          e.preventDefault();
          e.stopPropagation();
          setNewRoute(href);
          if (isMediaUploading) {
            setIsCancelUploadDialogOpen(true);
          } else if (isDataChanged) {
            setIsLeavePageDialogOpen(true);
          }
        }
      }
    }
  };
  useEffect(() => {
    if (newExternalRoute) {
      if (isDataChanged) {
        setIsLeavePageDialogOpen(true);
      } else {
        window.open(newExternalRoute, '_self', 'noopener, noreferrer');
      }
      return () => setNewExternalRoute(undefined);
    }
  }, [newExternalRoute]);
  useEffect(() => {
    setMounted(true);
  }, []);
  const container = typeof window !== 'undefined' ? () => window.document.body : undefined;
  let isNavVisible = !!user;
  const isNewsPage = pathname?.includes('news');
  if (!user && isNewsPage) isNavVisible = true;
  if (!user && pathname === '/welcome') isNavVisible = true;
  if (!user && pathname === '/enroll') isNavVisible = true;
  if (!user && pathname?.includes('support')) isNavVisible = true;
  if (isHideMainNavigation) isNavVisible = false;
  return <Container className={clsx(classes.root, classes[backgroundClassName], isProjectSubNavVisible && 'project-sub-nav-visible')} maxWidth={false} disableGutters style={{
    visibility: mounted ? 'visible' : 'hidden'
  }} onClickCapture={handleClick} data-sentry-element="Container" data-sentry-component="MainLayout" data-sentry-source-file="main.layout.tsx">
      <MainHeaderComponent burgerCallBack={handleDrawerToggle} handleLogoutClick={handleLogoutClick} data-sentry-element="MainHeaderComponent" data-sentry-source-file="main.layout.tsx" />
      {canAdminAccess(user, PERMISSION.ADMIN_IMPERSONATE, AuthAction.UPDATE) && EPIC_DEPLOYMENT !== 'live' && <AdminHeaderComponent />}
      <Box className={classes.mainContentWrapper} data-sentry-element="Box" data-sentry-source-file="main.layout.tsx">
        {isNavVisible ? <Box component="nav" aria-label="mailbox folders" className={classes.mainNavigation}>
            {greaterThanLg ? <Box sx={{
          display: {
            xxs: 'none',
            lg: 'block'
          }
        }} height="100%">
                <Drawer variant="permanent" className={classes.drawer} classes={{
            paper: classes.drawerPaper
          }} open>
                  <MainNavigationComponent teams={teams} isJoinMemberPage={isJoinMemberPage} hideNavigation={hideNavigation} setNewExternalRoute={setNewExternalRoute} />
                </Drawer>
              </Box> : <Box>
                <Drawer container={container} variant="temporary" anchor="right" open={mobileOpen} onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleDrawerToggle('close');
          }} className={classes.drawer} classes={{
            paper: classes.drawerPaper
          }} ModalProps={{
            keepMounted: true,
            // Better open performance on mobile.
            sx: {
              marginLeft: 30
            }
          }}>
                  <MainNavigationComponent handleMobileSlideToggle={() => {
              if (lessThanLg) {
                handleDrawerToggle('close');
              }
            }} teams={teams} isJoinMemberPage={isJoinMemberPage} hideNavigation={hideNavigation} setNewExternalRoute={setNewExternalRoute} />
                  <NavigationBottomMobile slideOpener={handleMobileSlideToggle} />
                  {mobileSlideOpen ? <SlidingPanelMobileComponent isOpen={mobileSlideOpen} slideOpener={handleMobileSlideToggle} handleLogoutClick={handleLogoutClick} /> : null}
                </Drawer>
              </Box>}
          </Box> : null}
        <Box className={clsx(classes.mainContainerWrapper, {
        [classes.mainContainerWrapperFullWidth]: isHideMainNavigation
      })} data-sentry-element="Box" data-sentry-source-file="main.layout.tsx">
          <Container maxWidth={false} disableGutters className={classes.mainContainer} data-sentry-element="Container" data-sentry-source-file="main.layout.tsx">
            <Box component="main" className={clsx(classes.content, {
            [classes.mainContainerWrapperFullWidth]: isHideMainNavigation
          })} data-sentry-element="Box" data-sentry-source-file="main.layout.tsx">
              <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="main.layout.tsx">{children}</ErrorBoundary>
            </Box>
          </Container>
          <MainFooterComponent data-sentry-element="MainFooterComponent" data-sentry-source-file="main.layout.tsx" />
        </Box>
      </Box>
      <CancelUploadDialog cancelUploading={handleDialogConfirm} open={isCancelUploadDialogOpen} onClose={handleDialogCancel} isUploading={!!isMediaUploading} description={t('cancel-upload.leave-description')} data-sentry-element="CancelUploadDialog" data-sentry-source-file="main.layout.tsx" />
      <Dialog open={isLeavePageDialogOpen} dialogType={DialogType.WARNING} title={t('leave-dialog.title')} description={t('leave-dialog.description')} onClose={handleDialogCancel} primaryButtonText={t('button.discard-changes')} onPrimaryButton={handleDialogConfirm} onSecondaryButton={handleDialogCancel} secondaryButtonText={t('button.back')} data-sentry-element="Dialog" data-sentry-source-file="main.layout.tsx" />
      <Dialog open={isLogoutDialogOpen} dialogType={DialogType.WARNING} title={t('logout-dialog.title')} description={t('logout-dialog.description')} onClose={handleDialogCancel} primaryButtonText={t('logout-dialog.button.confirm')} onPrimaryButton={handleDialogConfirm} onSecondaryButton={handleDialogCancel} secondaryButtonText={t('button.back')} data-sentry-element="Dialog" data-sentry-source-file="main.layout.tsx" />
      <ToastContainer autoClose={TOAST_AUTOCLOSE_SECONDS} className={toastStyles.toast} theme="colored" rtl={isRTL} data-sentry-element="ToastContainer" data-sentry-source-file="main.layout.tsx" />
    </Container>;
}