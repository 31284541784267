import { FC, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import { BootstrapTooltip } from '@/components/common/tooltip.component';
import ModeEditOutlineIcon from '@/styles/icons/publishing-stepper/mode-edit-outline';
import { withMuiRTL } from '@/hoc/withMuiRTL';
import useStyles from './prefilled-input.styles';
import type { JSX } from 'react';
interface PrefilledInputComponentProps {
  hasEditButton?: boolean;
  hasCopyButton?: boolean;
  openEditDialog?: () => void;
  label: string;
  value: string | null;
}
const PrefilledInputComponent: FC<PrefilledInputComponentProps> = ({
  hasEditButton = false,
  hasCopyButton = false,
  openEditDialog,
  label,
  value
}): JSX.Element => {
  const {
    t
  } = useTranslation();
  const classes = useStyles();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const handleCopy = async (value: string) => {
    await navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return <TextField label={label} value={value} disabled={true} className={classes.textField} InputProps={{
    endAdornment: hasEditButton || hasCopyButton ? <InputAdornment position="end" className={classes.inputAdornment}>
              {hasEditButton && <IconButton className={classes.iconButton} onClick={openEditDialog}>
                  <ModeEditOutlineIcon />
                </IconButton>}
              {hasCopyButton && <BootstrapTooltip title={!isCopied ? t('tooltip.aria-label.copy') : t('tooltip.text.copied')} placement={'top'}>
                  <IconButton className={clsx(classes.iconButton, {
          [classes.iconButtonIsCopied]: isCopied
        })} onClick={() => value && handleCopy(value)}>
                    <ContentCopyIcon />
                  </IconButton>
                </BootstrapTooltip>}
            </InputAdornment> : null
  }} data-sentry-element="TextField" data-sentry-component="PrefilledInputComponent" data-sentry-source-file="prefilled-input.tsx" />;
};
export default withMuiRTL(PrefilledInputComponent);