const maybeICU = require('i18next-icu');
// Need to have run same file both on server and client.
const ICU = typeof maybeICU === 'function' ? maybeICU : maybeICU.default;

const { DEFAULT_LOCALE, ADDITIONAL_LOCALES } = require('@/config/common/locale.config');

/** @type {import('next-i18next').UserConfig} */
const getConfig = {
  i18n: {
    defaultLocale: DEFAULT_LOCALE,
    locales: [DEFAULT_LOCALE, ...ADDITIONAL_LOCALES],
  },
  use: [new ICU()],
  serializeConfig: false,
  ns: 'common',
  resources: require('./.locales/config.json'),
  returnNull: false,
  returnEmptyString: false,
}

module.exports = getConfig;
