import { useEffect, useState } from 'react';
import { FortniteFooter } from '@wf-components/footer-react';
import { useTranslation } from 'next-i18next';
import { getPublicAccountInfo } from '@/services/account/account-service';
import { useAuthSession } from '@/hooks/useAuthSession';
export const MainFooterComponent = () => {
  const [countryCode, setCountryCode] = useState<string | undefined>();
  const {
    i18n
  } = useTranslation();
  const user = useAuthSession();
  const isLoggedIn = Boolean(user);
  const locale = i18n.resolvedLanguage;
  useEffect(() => {
    if (isLoggedIn) {
      const findUserCountry = async () => {
        const account = await getPublicAccountInfo();
        if (account.country) {
          setCountryCode(account.country);
        }
      };
      void findUserCountry();
    }
  }, [isLoggedIn]);
  return <div style={{
    zIndex: 9999
  }} data-sentry-component="MainFooterComponent" data-sentry-source-file="main.footer.component.tsx">
      <FortniteFooter locale={locale} countryCode={countryCode} data-sentry-element="FortniteFooter" data-sentry-source-file="main.footer.component.tsx" />
    </div>;
};