import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  CHART_HORIZONTAL_SPACING,
  CHART_HORIZONTAL_SPACING_SM_DOWN,
  HIGHLIGHTS_HORIZONTAL_GAP,
} from '@/components/common/charts/constants';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    chartTitle: {
      '& svg': {
        [isRTL ? ' marginRight' : 'marginLeft']: theme.spacing(1),
      },
    },
    skeletonStyleOverride: {
      backgroundColor: theme.palette.shade5,
      transform: 'scale(1)',
    },
    skeletonStyleOverrideV2: {
      backgroundColor: theme.palette.shade5,
      transform: 'scale(1)',
      flex: '1 0 0',
      minHeight: theme.spacing(16),
    },
    singleMobileSkeleton: {
      flexGrow: 1,
    },
    highlightSkeletonBlock: {
      display: 'flex',
      gap: theme.spacing(0.8),
      flexDirection: 'column',
    },
    highlightsSkeletonsWrapper: {
      padding: theme.spacing(0, CHART_HORIZONTAL_SPACING),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(HIGHLIGHTS_HORIZONTAL_GAP),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, CHART_HORIZONTAL_SPACING_SM_DOWN),
      },
    },
    highlightsSkeletonsWrapperV2: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(3.2),
      margin: theme.spacing(0, CHART_HORIZONTAL_SPACING, 4.2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: theme.spacing(0, CHART_HORIZONTAL_SPACING_SM_DOWN, 4.2),
      },
    },
    highlightsSkeletonsWrapperV3: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(HIGHLIGHTS_HORIZONTAL_GAP),
      margin: theme.spacing(0, CHART_HORIZONTAL_SPACING),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: theme.spacing(0, CHART_HORIZONTAL_SPACING_SM_DOWN),
      },
    },
    chartSkeletonsWrapper: {
      padding: theme.spacing(0, CHART_HORIZONTAL_SPACING),
      margin: theme.spacing(4, 0),
      display: 'flex',
      gap: theme.spacing(1.8),
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, CHART_HORIZONTAL_SPACING_SM_DOWN),
      },
    },
    skeletonLegendsWrapper: {
      margin: theme.spacing(2.4, 0, 0),
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(4),
    },
    bannerSkeletonsWrapper: {
      margin: theme.spacing(0, CHART_HORIZONTAL_SPACING, 4.8),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, CHART_HORIZONTAL_SPACING_SM_DOWN, 4.8),
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    hidden: {
      visibility: 'hidden',
    },
  });
});

export default useStyles;
