import { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import { localeList } from '@/config/common/locale.config';
import { switchLanguage } from '@/util/locale';
import { getImage } from '@/util/media';
import GlobeIconDark from '@/public/globe_icon_mobile_dark.svg';
import GlobeIconLight from '@/public/globe_icon_mobile_light.svg';
import type { JSX, MouseEventHandler } from 'react';
interface Props {
  className?: string;
}
const selected = <Box component="span" sx={{
  ml: '15px'
}}>
    &#10003;
  </Box>;
export default function LocaleSwitcher({
  className
}: Props): JSX.Element {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleMenuOpen: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const iconElement = getImage(theme?.palette?.mode === 'dark' ? GlobeIconDark : GlobeIconLight);
  const {
    i18n
  } = useTranslation();
  return <>
      <IconButton onClick={handleMenuOpen} className={className} data-sentry-element="IconButton" data-sentry-source-file="locale-switcher.component.tsx">
        <Image height={22} src={iconElement} data-sentry-element="Image" data-sentry-source-file="locale-switcher.component.tsx" />
      </IconButton>
      <Menu anchorEl={anchorEl} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} keepMounted transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} open={Boolean(anchorEl)} onClose={handleMenuClose} data-sentry-element="Menu" data-sentry-source-file="locale-switcher.component.tsx">
        {localeList.map(locale => <MenuItem key={locale.displayName} onClick={() => switchLanguage(locale.code)}>
            <Typography variant="body1">
              {locale.displayName}
              {locale.code === i18n.language && selected}
            </Typography>
          </MenuItem>)}
      </Menu>
    </>;
}