export interface AnalyticsResponse<T> {
  count: number;
  results: T[];
}

export enum Timescales {
  LIVE = 'live',
  HOURLY = 'hau',
  DAILY = 'dau',
  WEEKLY = 'wau',
  MONTHLY = 'mau',
}

export enum DIFFICULTY_LEVELS {
  TOO_EASY = 'too_easy',
  EASY = 'easy',
  JUST_RIGHT = 'just_right',
  DIFFICULT = 'difficult',
  TOO_DIFFICULT = 'too_difficult',
}

export enum RATING_LEVELS {
  RATE_1 = 'rate_1',
  RATE_2 = 'rate_2',
  RATE_3 = 'rate_3',
  RATE_4 = 'rate_4',
  RATE_5 = 'rate_5',
  RATE_6 = 'rate_6',
  RATE_7 = 'rate_7',
  RATE_8 = 'rate_8',
  RATE_9 = 'rate_9',
  RATE_10 = 'rate_10',
}

export enum FUN_LEVELS {
  NO = 'no',
  YES = 'yes',
}

export enum DateTime {
  DT = 'DT',
}

export enum Estimate {
  ACTUAL = 'ACTUAL',
  SEVEN_DAY_LAG = '7 DAY LAG',
  NO_LAG = 'NO LAG',
}
export type EstimateType = Estimate.ACTUAL | Estimate.SEVEN_DAY_LAG | Estimate.NO_LAG;
export type HauDauTimescale = Timescales.HOURLY | Timescales.DAILY;
export type DauTimescale = Timescales.DAILY;

export interface AnalyticsInsightsResponse {
  monthly: {
    activePlayers: number;
    change: number;
    data: number[];
  };
  weekly: {
    activePlayers: number;
    change: number;
    data: number[];
  };
  daily: {
    activePlayers: number;
    change: number;
    data: number[];
  };
}

export interface AnalyticsNotification {
  id: number;
  severity: 'success' | 'info' | 'warning' | 'error';
  title: string;
  message: string;
  dismissable?: boolean;
  link?: {
    label: string;
    href: string;
  };
}

export interface AnalyticsProject {
  projectId: number;
  title: string;
  activePlayers: number;
  change: number;
  data: number[];
}

export interface AnalyticsStatsResponse {
  mostPopular: AnalyticsProject;
  mostGrowth: AnalyticsProject;
  stats: {
    activePlayers: number;
    change: number;
    data: number[];
  };
}

export interface AnalyticsInteraction {
  interactionType: string;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: number;
  portalAccountId?: string;
  data?: {
    checked?: boolean | string;
    settingKey?: string;
    count?: number;
    creatorCode?: string;
    profileType?: string;
    deviceType?: string;
    route?: string;
    viewport?: string;
    screen?: string;
  };
}

interface IAnalyticsBase {
  DATE: Date;
  CREATIVE_ISLAND_CODE: string;
}
export interface AnalyticsAccoladesResponse extends IAnalyticsBase {
  PAYLOAD: AnalyticsAccoladesResponsePayload;
}

export type AnalyticsAccoladesDataEntry = {
  accolade_id: string;
  accolade_name: string;
  count: number;
};

export type AnalyticsAccoladesDataItem = {
  DT: string;
  PAYLOAD: AnalyticsAccoladesDataEntry[];
};
export type AnalyticsAccoladesData = AnalyticsAccoladesDataItem[];

export interface AnalyticsAccoladesResponsePayload {
  accolades: Record<string, number>;
  percentile?: number;
  versions: string[];
}

export interface AnalyticsPerformanceResponse extends IAnalyticsBase {
  PAYLOAD: AnalyticsPerformanceResponsePayload;
}

export interface AnalyticsPerformanceResponsePayload {
  [key: string]: {
    [key: string]: {
      [key: string]: number | string;
    };
  };
}

export enum PerformanceSources {
  hau = 'perf_v2',
  dau = 'perf_daily_v2',
}

export interface AnalyticsDeviceResponse {
  DT: Date;
  ISLAND_CODE: string;
  PAYLOAD: AnalyticsDeviceResponsePayload[];
}

export interface AnalyticsDeviceResponsePayload {
  guid_id: string;
  guid_name?: string;
  trigger_count: number;
}

export type AnalyticsDeviceDataEntry = {
  guid_id: string;
  guid_name?: string;
  trigger_count: number;
};

export type AnalyticsDeviceDataItem = {
  DT: string;
  ISLAND_CODE: string;
  PAYLOAD: AnalyticsDeviceDataEntry[];
};
export type AnalyticsDeviceData = AnalyticsDeviceDataItem[];

export interface AnalyticsUsersResponse {
  DT: Date;
  CREATIVE_ISLAND_CODE: string;
  PAYLOAD: AnalyticsUsersResponsePayload;
}

export interface AnalyticsUsersResponsePayload {
  date: Date;
  new_players: number;
  returning_players: number;
}

export interface AnalyticsActiveUsersResponse {
  DT: string;
  CREATIVE_ISLAND_CODE?: string;
  ACTIVE?: number | null;
  RETAINED?: number | null;
  RECURRING?: number | null;
}

export interface AnalyticsActivePlaytimeResponse {
  DT: string;
  CREATIVE_ISLAND_CODE?: string;
  ACTIVE_MINS?: number;
  PSVS?: number;
}

export interface FunResponse {
  DT: string;
  YES_RESPONSES: number;
  NO_RESPONSES: number;
}

export type EngagementPlayersDataItem = Pick<AnalyticsActiveUsersResponse, 'DT' | 'ACTIVE' | 'RETAINED' | 'RECURRING'>;

export type EngagementPlayersData = EngagementPlayersDataItem[];

export type ActivePlayersDataItem = Pick<AnalyticsActiveUsersResponse, 'DT' | 'ACTIVE'>;

export type ActivePlayersData = ActivePlayersDataItem[];

export type ActivePlayersDataEntry = {
  NAME: string;
  ACTIVE_PLAYERS: number;
};

export type ActivePlayersCountryDataItem = {
  DT: string;
  COUNTRIES: ActivePlayersDataEntry[];
};
export type ActivePlayersCountryData = ActivePlayersCountryDataItem[];

export type ActivePlayersPlatformDataItem = {
  DT: string;
  PLATFORMS: ActivePlayersDataEntry[];
};

export type ActivePlayersPlatformData = ActivePlayersPlatformDataItem[];

export type EngagementTimeDataItem = Pick<AnalyticsActivePlaytimeResponse, 'DT' | 'ACTIVE_MINS' | 'PSVS'>;

export type EngagementTimeData = EngagementTimeDataItem[];

export type ActivePlaytimeDataItem = Pick<AnalyticsActivePlaytimeResponse, 'DT' | 'ACTIVE_MINS'>;

export type ActivePlaytimeData = ActivePlaytimeDataItem[];

export type ActivePlaytimeDataEntry = {
  NAME: string;
  ACTIVE_MINS: number;
};

export type ActivePlaytimeCountryDataItem = {
  DT: string;
  COUNTRIES: ActivePlaytimeDataEntry[];
};
export type ActivePlaytimeCountryData = ActivePlaytimeCountryDataItem[];

export type ActivePlaytimePlatformDataItem = {
  DT: string;
  PLATFORMS: ActivePlaytimeDataEntry[];
};
export type ActivePlaytimePlatformData = ActivePlaytimePlatformDataItem[];

export interface AnalyticsNewAndReturningUsersResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  NEW_PLAYERS: number;
  RETURNING_PLAYERS: number;
}
export type FunDataItem = Pick<FunResponse, 'DT' | 'YES_RESPONSES' | 'NO_RESPONSES'>;
export type FunData = FunDataItem[];
export type EngagementUsersDataItem = Pick<AnalyticsNewAndReturningUsersResponse, 'DT' | 'NEW_PLAYERS' | 'RETURNING_PLAYERS'>;

export type EngagementUsersData = EngagementUsersDataItem[];

export interface AnalyticsICPEarningsResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  EARNINGS: number;
}

export enum EarningEstimate {
  EP_TYPE = 'EP_TYPE',
  EP_AMOUNT = 'EP_AMOUNT',
  EP_LOWER_RANGE = 'EP_LOWER_RANGE',
  EP_UPPER_RANGE = 'EP_UPPER_RANGE',
}

export interface AnalyticsEPEstimatesResponse {
  DT: string;
  [EarningEstimate.EP_TYPE]: EstimateType;
  [EarningEstimate.EP_AMOUNT]?: number;
  [EarningEstimate.EP_LOWER_RANGE]?: number;
  [EarningEstimate.EP_UPPER_RANGE]?: number;
}

export type ChartEarningsIcpDataItem = Pick<AnalyticsICPEarningsResponse, 'DT' | 'EARNINGS'>;

export type ChartEarningsIcpData = ChartEarningsIcpDataItem[];

export interface AnalyticsImpressionsResponseEntry {
  NAME: string;
  TOTAL_IMPRESSIONS: number;
}

export interface AnalyticsCountryImpressionsResponse {
  DT: string;
  COUNTRIES: AnalyticsImpressionsResponseEntry[];
}

export interface AnalyticsPlatformImpressionsResponse {
  DT: string;
  PLATFORMS: AnalyticsImpressionsResponseEntry[];
}

export interface AnalyticsSourceImpressionsResponse {
  DT: string;
  SOURCES: AnalyticsImpressionsResponseEntry[];
}

export type ImpressionsDataItem = Pick<AnalyticsTotalClicksAndImpressionsResponse, 'DT' | 'TOTAL_IMPRESSIONS'>;
export type ImpressionsData = ImpressionsDataItem[];

export type ImpressionsDataEntry = {
  NAME: string;
  TOTAL_IMPRESSIONS: number;
};

export type ImpressionsSourceDataItem = {
  DT: string;
  SOURCES: ImpressionsDataEntry[];
};
export type ImpressionsSourceData = ImpressionsSourceDataItem[];

export type ImpressionsCountryDataItem = {
  DT: string;
  COUNTRIES: ImpressionsDataEntry[];
};
export type ImpressionsCountryData = ImpressionsCountryDataItem[];

export type ImpressionsPlatformDataItem = {
  DT: string;
  PLATFORMS: ImpressionsDataEntry[];
};
export type ImpressionsPlatformData = ImpressionsPlatformDataItem[];

export interface AnalyticsTotalPlaysResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  TOTAL_PLAYS: number;
  PTR?: number;
}

export type PlaysDataItem = Pick<AnalyticsTotalPlaysResponse, 'DT' | 'TOTAL_PLAYS'>;

export type PlaysData = PlaysDataItem[];

export type PTRDataItem = Pick<AnalyticsTotalPlaysResponse, 'DT' | 'PTR'>;

export type PTRData = PTRDataItem[];

export interface AnalyticsTotalClicksAndImpressionsResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  TOTAL_CLICKS: number;
  TOTAL_IMPRESSIONS: number;
  CTR: number;
}

export interface AnalyticsCountryClicksResponse {
  DT: string;
  COUNTRIES: AnalyticsClicksResponseEntry[];
}

export interface AnalyticsPlatformClicksResponse {
  DT: string;
  PLATFORMS: AnalyticsClicksResponseEntry[];
}

export interface AnalyticsSourceClicksResponse {
  DT: string;
  SOURCES: AnalyticsClicksResponseEntry[];
}

export interface AnalyticsClicksResponseEntry {
  NAME: string;
  TOTAL_CLICKS: number;
}

export type ClicksDataEntry = {
  NAME: string;
  TOTAL_CLICKS: number;
};

export type ClicksSourceDataItem = {
  DT: string;
  SOURCES: ClicksDataEntry[];
};
export type ClicksSourceData = ClicksSourceDataItem[];

export type ClicksCountryDataItem = {
  DT: string;
  COUNTRIES: ClicksDataEntry[];
};
export type ClicksCountryData = ClicksCountryDataItem[];

export type ClicksPlatformDataItem = {
  DT: string;
  PLATFORMS: ClicksDataEntry[];
};
export type ClicksPlatformData = ClicksPlatformDataItem[];

export type TotalCliksDataItem = Pick<AnalyticsTotalClicksAndImpressionsResponse, 'DT' | 'TOTAL_CLICKS'>;

export type TotalCliksData = TotalCliksDataItem[];

export type CTRDataItem = Pick<AnalyticsTotalClicksAndImpressionsResponse, 'DT' | 'CTR'>;

export type CTRData = CTRDataItem[];

export interface AnalyticsSessionLengthResponse {
  SESSION_MINUTES: number;
  COUNT_SESSIONS: number;
}
export interface SessionLengthByDayDataResponse {
  DT: string;
  AVERAGE_SESSION_MINUTES: number;
  PERCENTAGE_ABOVE_10_MINUTES: number;
}

export type SessionLengthDataItem = Pick<AnalyticsSessionLengthResponse, 'SESSION_MINUTES' | 'COUNT_SESSIONS'>;

export type SessionLengthData = SessionLengthDataItem[];

export type AnalyticsSurveyResponse = {
  DT: string;
  PAYLOAD: AnalyticsSurveyResponsePayload[];
};

export type AnalyticsSurveyResponsePayload = {
  R_TEXT: string;
  R_ORDER: number;
  RESPONSE_COUNT: number;
};

export type AnalyticsSurveyResponsePayloadDataItem = Pick<AnalyticsSurveyResponsePayload, 'R_ORDER' | 'RESPONSE_COUNT'>;
export type AnalyticsSurveyResponsePayloadData = AnalyticsSurveyResponsePayloadDataItem[];

export type AnalyticsSurveyResponseDataItem = Pick<AnalyticsSurveyResponse, 'DT'> & Record<string, number>;
export type AnalyticsSurveyResponseData = AnalyticsSurveyResponseDataItem[];

export type AnalyticsSurveyResponseDistributionDataItem = Pick<AnalyticsSurveyResponsePayload, 'R_ORDER'> & {
  RESPONSE_DISTRIBUTION_COUNT: number;
};
export type AnalyticsSurveyResponseDistributionData = AnalyticsSurveyResponseDistributionDataItem[];

export type AverageRatingData = {
  DT: string;
  AVERAGE_RATING: number;
}[];

export interface AnalyticsFavoritesResponse {
  DT: string;
  FAVORITES_NET_CUMULATIVE: number;
}

export type ChartEstimatesDataItem = Partial<AnalyticsEPEstimatesResponse>;

export type ChartEstimatesData = ChartEarningsIcpDataItem[];

export type RatingOverTimeData = {
  DT: string;
  [RATING_LEVELS.RATE_1]: number;
  [RATING_LEVELS.RATE_2]: number;
  [RATING_LEVELS.RATE_3]: number;
  [RATING_LEVELS.RATE_4]: number;
  [RATING_LEVELS.RATE_5]: number;
  [RATING_LEVELS.RATE_6]: number;
  [RATING_LEVELS.RATE_7]: number;
  [RATING_LEVELS.RATE_8]: number;
  [RATING_LEVELS.RATE_9]: number;
  [RATING_LEVELS.RATE_10]: number;
}[];

export type AnalyticsFavoritesDataItem = Pick<AnalyticsFavoritesResponse, 'DT' | 'FAVORITES_NET_CUMULATIVE'>;
export type AnalyticsFavoritesData = AnalyticsFavoritesDataItem[];

export enum SatisfactionCsvKeys {
  RATING_SUMMARY = 'rating_summary',
  RATING_OVER_TIME = 'rating_over_time',
  RATING_OVER_TIME_AVERAGE = 'rating_over_time_average',
  RATING_COMPARE = 'rating_compare',
  HAVE_FUN_SUMMARY = 'have_fun_summary',
  HAVE_FUN_OVER_TIME = 'have_fun_over_time',
  HAVE_FUN_COMPARE = 'have_fun_compare',
  DIFFICULTY_SUMMARY = 'difficulty_summary',
  DIFFICULTY_OVER_TIME = 'difficulty_over_time',
  DIFFICULTY_COMPARE = 'difficulty_compare',
}

export enum EngagementCsvKeys {
  PLAYTIME = 'playtime',
  ACTIVE_PLAYERS = 'active_players',
  NEW_RETURNED_PLAYERS = 'new_returned_players',
  PLAYTIME_TOTAL = 'playtime_total',
  PLAYTIME_COUNTRIES = 'playtime_countries',
  PLAYTIME_PLATFORMS = 'playtime_platforms',
  ACTIVE_PLAYERS_TOTAL = 'active_players_total',
  ACTIVE_PLAYERS_COUNTRIES = 'active_players_countries',
  ACTIVE_PLAYERS_PLATFORMS = 'active_players_platforms',
}

export enum AudienceCsvKeys {
  OVERVIEW = 'overview',
  IMPRESSIONS_TOTAL = 'impressions_total',
  IMPRESSIONS_SOURCES = 'impressions_sources',
  IMPRESSIONS_COUNTRIES = 'impressions_countries',
  IMPRESSIONS_PLATFORMS = 'impressions_platforms',
  CLICKS_TOTAL = 'clicks_total',
  CLICKS_SOURCES = 'clicks_sources',
  CLICKS_COUNTRIES = 'clicks_countries',
  CLICKS_PLATFORMS = 'clicks_platforms',
  PLAYS = 'plays',
}

export enum GameplayCsvKeys {
  SESSION_LENGTH = 'session_length',
  GAME_XP = 'game_xp',
  ANALYTICS_DEVICE = 'analytics_device',
}

export enum AnalyticsSwitcherTypes {
  TOTAL = 'total',
  SOURCES = 'sources',
  PLATFORMS = 'platforms',
  COUNTRIES = 'countries',
}

export enum AnalyticsTableType {
  ACTIVE_PLAYTIME = 'active_playtime',
  ACTIVE_PLAYERS = 'active_players',
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
}

export interface AnalyticsCountryActivePlayersResponse {
  DT: string;
  COUNTRIES: AnalyticsActivePlayersResponseEntry[];
}

export interface AnalyticsPlatformActivePlayersResponse {
  DT: string;
  PLATFORMS: AnalyticsActivePlayersResponseEntry[];
}

export interface AnalyticsActivePlayersResponseEntry {
  NAME: string;
  ACTIVE_PLAYERS: number;
}

export interface AnalyticsActivePlaytimeResponseEntry {
  NAME: string;
  ACTIVE_MINS: number;
}

export interface AnalyticsCountryActivePlaytimeResponse {
  DT: string;
  COUNTRIES: AnalyticsActivePlaytimeResponseEntry[];
}

export interface AnalyticsPlatformActivePlaytimeResponse {
  DT: string;
  PLATFORMS: AnalyticsActivePlaytimeResponseEntry[];
}
