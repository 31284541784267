import { memo } from 'react';
import { Alert2Icon, AlertIcon, CloseIcon, InfoIcon, StatusApprovedIcon } from '@epic-ui/icons';
import { Alert, AlertTitle, IconButton, Skeleton } from '@mui/material';
import { clsx } from 'clsx';
import useStyles from '@/components/common/alerts/info-box.styles';
import useSkeletonStyles from '@/components/common/charts/skeletons/skeletons.styles';
import { BootstrapTooltip } from '@/components/common/tooltip.component';
import Button from '@/components/ui/Button';
import type { AlertColor } from '@mui/material';
import type { FC, JSX } from 'react';
interface InfoBoxProps {
  children: React.ReactNode;
  severity: AlertColor;
  onClose?: () => void;
  onSubmit?: () => void;
  icon?: any;
  submitButtonText?: string;
  submitButtonTooltip?: string;
  className?: string;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
  isSkeletonVisible?: boolean;
}
const InfoBox: FC<InfoBoxProps> = ({
  severity,
  onClose,
  onSubmit,
  submitButtonText,
  submitButtonTooltip,
  className,
  icon,
  isSubmitDisabled,
  isLoading = false,
  children,
  isSkeletonVisible = false
}): JSX.Element => {
  const classes = useStyles();
  const skeletonClasses = useSkeletonStyles();
  if (isSkeletonVisible) {
    return <Skeleton data-testid="info-box-skeleton" className={clsx(skeletonClasses.skeletonStyleOverrideV2, className)} height={40} />;
  }
  const hasSubmitButton = !!(submitButtonText && onSubmit);
  const hasCloseButton = !!onClose;
  const icons = {
    success: <StatusApprovedIcon className={classes.iconSuccess} />,
    info: <InfoIcon className={classes.iconInfo} />,
    warning: <Alert2Icon />,
    error: <AlertIcon />
  };
  const actions: JSX.Element[] = [];
  if (hasSubmitButton) {
    const submitBtn = <BootstrapTooltip key="submit" placement="bottom" title={submitButtonTooltip}>
        <span>
          <Button id="submit_alert_btn" onClick={onSubmit} disabled={isSubmitDisabled} loading={isLoading}>
            {submitButtonText}
          </Button>
        </span>
      </BootstrapTooltip>;
    actions.push(submitBtn);
  }
  if (hasCloseButton) {
    const closeIcon = <IconButton key="close" aria-label="Close" size="small" onClick={onClose} data-testid="closeInfoboxButton">
        <CloseIcon fontSize="small" />
      </IconButton>;
    actions.push(closeIcon);
  }
  return <Alert action={actions.length ? actions : undefined} severity={severity} icon={icon || icons[severity]} className={clsx(classes.alert, className, {
    [classes.alertWithMobileStyles]: hasCloseButton
  })} data-sentry-element="Alert" data-sentry-component="InfoBox" data-sentry-source-file="info-box.tsx">
      <AlertTitle data-sentry-element="AlertTitle" data-sentry-source-file="info-box.tsx">{children}</AlertTitle>
    </Alert>;
};
export default memo(InfoBox);