import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    ratingsDisplay: {
      marginTop: theme.spacing(6.4),
    },
    textFieldsLineByLine: {
      display: 'grid',
      marginBottom: theme.spacing(4),
    },
    textFieldsBlock: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr',
      gridGap: '20px',
      marginBottom: theme.spacing(6.4),

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
      },
    },
    dRatingInfoByCert: {
      display: 'flex',
      gap: 10,
      marginTop: theme.spacing(4.8),
    },
  }),
);

export default useStyles;
