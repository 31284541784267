//@ts-nocheck
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { canAccess } from '@creator-portal/common/auth';
import { disableEnvironmentIarcRatings } from '@creator-portal/common/iarc';
import { GAME_FEATURE } from '@creator-portal/common/links/constants';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import { IMAGE_MEDIA_NAME, IMG_ASPECT_RATIO, IMG_MIN_HEIGHT, IMG_MIN_WIDTH, LOBBY_MEDIA_NAME, SQUARE_MEDIA_NAME, TRAILER_MEDIA_NAME } from '@creator-portal/common/publishing/constants';
import { LinkCodeInfo, MarketingOptIn } from '@creator-portal/common/types';
import { uploadFile } from '@/services/media/upload-service';
import { DiscoveryErrors, initMediaUpload, publishBuild } from '@/services/publishing/publishing-service';
import { BootstrapTooltip } from '@/components/common/tooltip.component';
import useStyles from '@/components/publishing/deploy-release-flow/stepper-content.styles';
import { PUBLISH_PROGRESS_FIELD, VISIBILITY_FIELDS } from '@/components/publishing/deploy-release-flow/stepper-types';
import StepperModalCloseConfirmation from '@/components/publishing/deploy-release-flow/steps/modals/close-confirmation-modal';
import { getStepperKeyByIndex, prepareFileUploadUrl, preparePresetFileFullName, preparePresetVideoFileFullName, validateCurrentStep } from '@/components/publishing/deploy-release-flow/util';
import { RatingStatus } from '@/components/publishing/ratings-dialog.component';
import Button from '@/components/ui/Button';
import { usePublicRuntimeConfig } from '@/hooks/use-public-runtime-config';
import { useAuthSession } from '@/hooks/useAuthSession';
import { useProject } from '@/contexts/project.context';
import { usePublishingAutoSaving } from '@/contexts/publishing-auto-saving.context';
import { checkProjectSysMetaIncludesGameFeature, getProjectTypeForAnalytics, prepareAttributionDto } from '@/util/publishing';
import { trackClickInteraction } from '@/util/tracking';
import type { AlertDialogError } from '@/components/common/alerts/error-dialog';
import type { AttributionRatingsDetails, GameDetails, MarketingFields, MediaDetails, NewChanges, PrivateVersion, StepperControls, VisibilityDetails } from '@/components/publishing/deploy-release-flow/stepper-types';
import type { PublishBuildDto } from '@/types/publishing';
import type { FC, MouseEventHandler, MutableRefObject } from 'react';
interface ModalActions {
  activeStep: number;
  stepsLength: number;
  ratingStatus: RatingStatus | null;
  onDialogClose: () => void;
  handleNext: () => MouseEventHandler<HTMLButtonElement> | undefined;
  handleBack: () => MouseEventHandler<HTMLButtonElement> | undefined;
  setPublishFailsError: (error: AlertDialogError) => void;
  preventOpenDeployModalRef?: MutableRefObject<boolean>;
  isLegacyIarcCert: boolean;
}
const MOVE_FORWARD_INDEX = 2;
const MOVE_BACK_INDEX = 3;
interface DeployReleaseFormState extends GameDetails, NewChanges, MediaDetails, AttributionRatingsDetails, VisibilityDetails, StepperControls, PrivateVersion {
  buildCode: LinkCodeInfo;
  isMarketingAndPromotionChecked: boolean;
  machineTranslationPreferences: {
    title: string;
    introduction: string;
    tagline: string;
  };
}
export const getMarketingFields = (isTurnedOn: boolean): MarketingOptIn => {
  return {
    discovery_featuring: isTurnedOn,
    epic_picks: isTurnedOn,
    epic_owned_social_media: isTurnedOn,
    epic_made_video: isTurnedOn,
    creator_market_program: isTurnedOn
  };
};
export const StepperActions: FC<ModalActions> = ({
  activeStep,
  stepsLength,
  ratingStatus,
  onDialogClose,
  handleNext,
  handleBack,
  setPublishFailsError,
  preventOpenDeployModalRef,
  isLegacyIarcCert
}) => {
  const {
    EPIC_ENV,
    EPIC_DEPLOYMENT
  } = usePublicRuntimeConfig();
  const session = useAuthSession();
  const {
    project
  } = useProject();
  const hasIPPublishingAccess = canAccess(session, PERMISSION.LEGO_FEATURE);
  const hasFallGuysAccess = canAccess(session, PERMISSION.FALL_GUYS_FEATURE);
  const hasFallGuysPublishingAccess = canAccess(session, PERMISSION.FALL_GUYS_ISLANDS_PUBLISHING_FEATURE);
  const hasTmntAccess = canAccess(session, PERMISSION.TMNT_FEATURE);
  const hasTmntPublishingAccess = canAccess(session, PERMISSION.TMNT_ISLANDS_PUBLISHING_FEATURE);
  const hasTwdPublishingAccess = canAccess(session, PERMISSION.TWDU_ISLANDS_PUBLISHING_FEATURE);
  const isLegoProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.LEGO);
  const isFallGuysProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.FALL_GUYS);
  const isTmntProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.TMNT);
  const isTwdProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.TWDU);
  const {
    t
  } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isSmBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    onResetReleaseFromStorage
  } = usePublishingAutoSaving();
  const router = useRouter();
  const {
    reset,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: {
      errors
    },
    watch
  } = useFormContext();
  const projectTypeForAnalytics = getProjectTypeForAnalytics(project?.sysMeta);
  const {
    confirmationDialog: ConfirmationDialog,
    handlePublishDialogClose
  } = StepperModalCloseConfirmation(() => {
    onDialogClose();
    trackClickInteraction({
      eventCategory: 'PublishFlowCancel',
      eventAction: 'cancel',
      eventLabel: projectTypeForAnalytics
    });
  });
  const isLoading = watch(PUBLISH_PROGRESS_FIELD);
  const isActiveStepRating = activeStep === 1;
  const handleCancelButton = () => {
    handlePublishDialogClose();
  };
  const setVisitedStep = (index: number) => {
    const stepKey = getStepperKeyByIndex(index);
    setValue('stepsToValidate', {
      ...getValues('stepsToValidate'),
      [stepKey]: true
    });
  };
  const handleNextButton = () => {
    const isNewChangesFieldExist = getValues(VISIBILITY_FIELDS.isProjectHasReleases);
    const stepToValidateIndex = activeStep < MOVE_FORWARD_INDEX ? activeStep : activeStep + 1;
    validateCurrentStep(activeStep, trigger, Boolean(isNewChangesFieldExist));
    if (activeStep === MOVE_FORWARD_INDEX) setVisitedStep(activeStep);
    setVisitedStep(stepToValidateIndex);
    handleNext();
  };
  const handleBackButton = () => {
    const isNewChangesFieldExist = getValues(VISIBILITY_FIELDS.isProjectHasReleases);
    const stepToValidateIndex = activeStep < MOVE_BACK_INDEX ? activeStep : activeStep - 1;
    validateCurrentStep(activeStep, trigger, Boolean(isNewChangesFieldExist));
    if (activeStep !== MOVE_BACK_INDEX) setVisitedStep(stepToValidateIndex);
    handleBack();
  };
  const handlePublishingError = (description: string, code: string, message: string) => {
    setPublishFailsError({
      title: t('alert.publishFails.title'),
      description: description,
      errorCode: code,
      errorMessage: message
    });
    trackClickInteraction({
      eventCategory: 'PublishFlowError',
      eventAction: 'submit',
      eventLabel: projectTypeForAnalytics
    });
  };
  const handleSuccess = () => {
    const projectId = router.query?.projectId;
    const team = router.query?.team;
    const lang = router.query?.lang;
    const options = {
      pathname: '/[team]/projects/[projectId]/releases',
      query: {
        team,
        projectId,
        lang
      }
    };
    if (preventOpenDeployModalRef) {
      preventOpenDeployModalRef.current = true;
    }
    // Reload the page to show the new version
    void router.replace(projectId && team ? options : router.asPath).then(() => {
      setValue(PUBLISH_PROGRESS_FIELD, false);
      onDialogClose();
      reset();
      onResetReleaseFromStorage();
      toast.success(t('alert.publishCompletes'));
      return;
    });
    trackClickInteraction({
      eventCategory: 'PublishFlowComplete',
      eventAction: 'submit',
      eventLabel: projectTypeForAnalytics
    });
  };
  const handleSubmitButton = async (data: DeployReleaseFormState) => {
    trackClickInteraction({
      eventCategory: 'PublishFlowSubmit',
      eventAction: 'submit',
      eventLabel: projectTypeForAnalytics
    });
    const {
      title,
      tagline,
      tags,
      introduction,
      changes,
      imageToUpload,
      videoToUpload,
      squareImageToUpload,
      lobbyImageToUpload,
      autoActivate,
      attributions,
      machineTranslationPreferences,
      existingSquareImageUrl,
      existingVideoVuid,
      lobbyExistingImageUrl,
      buildCode,
      discoveryIntent,
      isMarketingAndPromotionChecked
    } = data;
    setValue(PUBLISH_PROGRESS_FIELD, true);
    try {
      let correlationId: string | undefined;
      let mediaSubmissionId: string | null = null;
      let mediaSubmissionToken: string | null = null;
      const mediaSubmissionFileEtags: Record<string, {
        etags: string[];
      }> = {};
      if (imageToUpload || videoToUpload || squareImageToUpload || lobbyImageToUpload) {
        const initMediaUploadDto = {
          image: imageToUpload,
          trailer: videoToUpload,
          squareImage: squareImageToUpload,
          lobbyBackground: lobbyImageToUpload
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const initMediaUploadResponse = await initMediaUpload(buildCode, initMediaUploadDto);
        mediaSubmissionToken = initMediaUploadResponse.mediaSubmissionToken;
        mediaSubmissionId = initMediaUploadResponse.mediaSubmissionId;
        correlationId = initMediaUploadResponse.correlationId;
        const filesToUploadUrls = initMediaUploadResponse.filesToUploadUrls;
        const imageUploadUrl = prepareFileUploadUrl(filesToUploadUrls, preparePresetFileFullName(IMAGE_MEDIA_NAME, imageToUpload));
        const trailerUploadUrl = prepareFileUploadUrl(filesToUploadUrls, preparePresetVideoFileFullName(TRAILER_MEDIA_NAME, videoToUpload));
        const squareImageUploadUrl = prepareFileUploadUrl(filesToUploadUrls, preparePresetFileFullName(SQUARE_MEDIA_NAME, squareImageToUpload));
        const lobbyImageUploadUrl = prepareFileUploadUrl(filesToUploadUrls, preparePresetFileFullName(LOBBY_MEDIA_NAME, lobbyImageToUpload));
        if (imageUploadUrl && imageToUpload) {
          try {
            const imageUpload = await uploadFile(imageUploadUrl, false, true, imageToUpload);
            if (imageUpload.etag !== null) mediaSubmissionFileEtags[preparePresetFileFullName(IMAGE_MEDIA_NAME, imageToUpload)] = {
              etags: [imageUpload.etag]
            };
          } catch (e: any) {
            handlePublishingError(e?.errorCode, e?.errorMessage, t('meta.gameImage.uploadError'));
            console.error('Error while loading landscape image ', e);
            setValue(PUBLISH_PROGRESS_FIELD, false);
            return;
          }
        }
        if (trailerUploadUrl && videoToUpload) {
          try {
            const trailerUpload = await uploadFile(trailerUploadUrl, false, true, videoToUpload);
            if (trailerUpload.etag !== null) mediaSubmissionFileEtags[preparePresetVideoFileFullName(TRAILER_MEDIA_NAME, videoToUpload)] = {
              etags: [trailerUpload.etag]
            };
          } catch (e: any) {
            handlePublishingError(e?.errorCode, e?.errorMessage, t('meta.gameTrailer.uploadError'));
            console.error('Error while loading trailer video ', e);
            setValue(PUBLISH_PROGRESS_FIELD, false);
            return;
          }
        }
        if (squareImageUploadUrl && squareImageToUpload) {
          try {
            const squareImageUpload = await uploadFile(squareImageUploadUrl, false, true, squareImageToUpload);
            if (squareImageUpload.etag !== null) mediaSubmissionFileEtags[preparePresetFileFullName(SQUARE_MEDIA_NAME, squareImageToUpload)] = {
              etags: [squareImageUpload.etag]
            };
          } catch (e: any) {
            handlePublishingError(e?.errorCode, e?.errorMessage, t('meta.gameImage.uploadError'));
            console.error('Error while loading square image', e);
            setValue(PUBLISH_PROGRESS_FIELD, false);
            return;
          }
        }
        if (lobbyImageUploadUrl && lobbyImageToUpload) {
          try {
            const imageUpload = await uploadFile(lobbyImageUploadUrl, false, true, lobbyImageToUpload);
            if (imageUpload.etag !== null) mediaSubmissionFileEtags[preparePresetFileFullName(LOBBY_MEDIA_NAME, lobbyImageToUpload)] = {
              etags: [imageUpload.etag]
            };
          } catch (e: any) {
            handlePublishingError(e?.errorCode, e?.errorMessage, t('meta.gameImage.uploadError'));
            console.error('Error while loading lobby image ', e);
            setValue(PUBLISH_PROGRESS_FIELD, false);
            return;
          }
        }
      }
      try {
        const hasSquareThumbnail = !!(existingSquareImageUrl || squareImageToUpload);
        const hasTrailer = !!(existingVideoVuid || videoToUpload);
        const hasLobbyBackground = !!(lobbyImageToUpload || lobbyExistingImageUrl);
        const dto: PublishBuildDto = {
          mediaSubmissionId,
          mediaSubmissionToken,
          mediaSubmissionFileEtags,
          title,
          tagline,
          tags,
          introduction,
          marketing_opt_in: getMarketingFields(isMarketingAndPromotionChecked),
          autoActivate: !!autoActivate,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          attributions: attributions.map(prepareAttributionDto),
          hasSquareThumbnail,
          hasTrailer,
          hasLobbyBackground,
          machineTranslationPreferences: {
            title: !!machineTranslationPreferences.title,
            introduction: !!machineTranslationPreferences.introduction,
            tagline: !!machineTranslationPreferences.tagline
          },
          discoveryIntent
        };
        if (changes) {
          dto.changes = changes;
        }
        const publishResponse = await publishBuild(buildCode, correlationId || '', dto);
        if (publishResponse.errorCodes) {
          setValue(PUBLISH_PROGRESS_FIELD, false);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          publishResponse.errorCodes.map(errorCode => {
            switch (errorCode) {
              case DiscoveryErrors.VIDEO_CODEC:
                {
                  handlePublishingError(errorCode, undefined, t('meta.gameTrailer.videoCodecError'));
                  return;
                }
              case DiscoveryErrors.AUDIO_CODEC:
                {
                  handlePublishingError(errorCode, undefined, t('meta.gameTrailer.audioCodecError'));
                  return;
                }
              case DiscoveryErrors.VIDEO_ASPECT_RATIO:
                {
                  handlePublishingError(errorCode, undefined, t('meta.gameTrailer.videoAspectRatioError'));
                  return;
                }
              case DiscoveryErrors.VIDEO_RESOLUTION:
                {
                  handlePublishingError(errorCode, undefined, t('meta.gameTrailer.videoResolutionError'));
                  return;
                }
              case DiscoveryErrors.IMG_ASPECT_RATIO:
                {
                  handlePublishingError(errorCode, undefined, t('meta.gameImage.imageAspectRatioError', {
                    ratio: IMG_ASPECT_RATIO
                  }));
                  return;
                }
              case DiscoveryErrors.IMG_RESOLUTION:
                {
                  handlePublishingError(errorCode, undefined, t('meta.gameImage.imageResolutionError', {
                    width: IMG_MIN_WIDTH,
                    height: IMG_MIN_HEIGHT
                  }));
                  return;
                }
              default:
                {
                  handlePublishingError(errorCode, undefined, 'Uknown error');
                  return;
                }
            }
          });
        } else {
          handleSuccess();
        }
      } catch (e: any) {
        const errorCode = e?.errorCode;
        const errorData = e?.data;
        let errorDescription = '';
        if (errorCode === 'errors.com.epicgames.creator-portal-backend.moderation_pending') {
          errorDescription = t('alert.publishFailsPendingReview.description');
        } else if (errorData && errorData?.errors && (errorData?.errors as any[])?.find(item => item?.property === 'RESOLUTION' || item?.property === 'ASPECT_RATIO')) {
          errorDescription = t('alert.publishFails.trailer.description');
        } else if (errorCode === 'errors.com.epicgames.creator-portal-backend.publish_not_allowed') {
          errorDescription = t('alert.publishFailsNotEnrolled.description');
        }
        handlePublishingError(e?.errorCode, e?.errorMessage, errorDescription);
        console.log('Error confirming discovery submission', e);
        setValue(PUBLISH_PROGRESS_FIELD, false);
        return;
      }
    } catch (e: any) {
      const errorDescription = e?.errorCode === 'errors.com.epicgames.creator-portal-backend.moderation_pending' ? t('alert.publishFailsPendingReview.description') : 'Uknown error';
      handlePublishingError(e?.errorCode, e?.errorMessage, errorDescription);
      console.log('Error submitting to discovery', e);
      setValue(PUBLISH_PROGRESS_FIELD, false);
      return;
    }
  };
  const isPublishButtonDisabled = Object.keys(errors).length !== 0;
  let publishDisabledTitle = isPublishButtonDisabled ? t('publish.stepper.submit.disabled') : '';
  if (!hasFallGuysPublishingAccess && isFallGuysProject) publishDisabledTitle = t('publish.tooltip.block.fall-gyus');
  if (!hasTmntPublishingAccess && isTmntProject) publishDisabledTitle = t('publish.tooltip.block.tmnt');
  if (!hasTwdPublishingAccess && isTwdProject) publishDisabledTitle = t('publish.tooltip.block.twdu');
  const buttonSize = isSmBreakPoint ? 'small' : 'large';
  const disableEnvIarcRatings = disableEnvironmentIarcRatings({
    EPIC_ENV: EPIC_ENV,
    EPIC_DEPLOYMENT: EPIC_DEPLOYMENT
  }, session);
  const isRatingMature = ratingStatus === RatingStatus.MATURE;
  const isNextButtonDisabled = isActiveStepRating && !disableEnvIarcRatings && (isRatingMature || !ratingStatus || isLegacyIarcCert);
  let nextButtonDisabledTooltipText = t('projects.rating.not-received.button.next.disabled.tooltip');
  if (isRatingMature) nextButtonDisabledTooltipText = t('projects.rating.received.button.next.disabled.tooltip');
  if (isRatingMature && hasIPPublishingAccess && isLegoProject) nextButtonDisabledTooltipText = t('projects.rating.received.button.next.disabled.tooltip.brand', {
    brand: t('projectCategory.lego')
  });
  if (isRatingMature && hasFallGuysAccess && isFallGuysProject) nextButtonDisabledTooltipText = t('projects.rating.received.button.next.disabled.tooltip.brand', {
    brand: t('projectCategory.fall-guys')
  });
  if (isRatingMature && hasTmntAccess && isTmntProject) nextButtonDisabledTooltipText = t('projects.rating.received.button.next.disabled.tooltip.brand', {
    brand: t('projectCategory.tmnt')
  });
  const NextButton = isNextButtonDisabled ? <BootstrapTooltip placement="bottom" title={nextButtonDisabledTooltipText}>
      <span id="submitWrapperTooltip" className={classes.submitWrapper}>
        <Button type="button" onClick={handleNextButton} size={buttonSize} disabled>
          {t('button.next')}
        </Button>
      </span>
    </BootstrapTooltip> : <Button type="button" onClick={handleNextButton} size={buttonSize}>
      {t('button.next')}
    </Button>;
  const BackButton = <Button variant="outlined" type="button" onClick={handleBackButton} size={buttonSize} disabled={isLoading}>
      {t('button.back')}
    </Button>;
  const firstStep = <>
      <Button variant="outlined" type="button" onClick={() => handleCancelButton()} size={buttonSize} data-testid={'cancelPublishing'}>
        {t('button.cancel')}
      </Button>
      {NextButton}
      <ConfirmationDialog />
    </>;
  const middleStep = <>
      {BackButton}
      {NextButton}
    </>;
  const lastStep = <>
      {BackButton}
      <BootstrapTooltip placement="top" title={publishDisabledTitle}>
        <span id="submitWrapperTooltip" className={classes.submitWrapper}>
          <Button type="submit" disabled={isPublishButtonDisabled || isLoading || !!publishDisabledTitle} size={buttonSize} onClick={handleSubmit(data => handleSubmitButton(data as DeployReleaseFormState))}>
            {t('button.publish')}
          </Button>
        </span>
      </BootstrapTooltip>
    </>;
  if (activeStep === 0) return firstStep;
  if (activeStep === stepsLength - 1) return lastStep;
  return middleStep;
};