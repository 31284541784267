import { useMediaQuery, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import useStyles from '@/components/common/navigation/user-card.styles';
import { BootstrapTooltip } from '@/components/common/tooltip.component';
import { Roles } from '@/util/teams';
import type { TeamMembershipRole } from '@creator-portal/common/types';
import type { JSX } from 'react';
const UserCard = ({
  activeTeamName,
  teamMemberRole,
  onClick,
  isNavbarCollapsed
}: {
  activeTeamName: string;
  teamMemberRole?: TeamMembershipRole;
  onClick: () => void;
  isNavbarCollapsed: boolean;
}): JSX.Element | null => {
  const classes = useStyles();
  const {
    t
  } = useTranslation();
  const RoleColorClasses = {
    [Roles.COLLABORATOR]: classes.collaboratorColor,
    [Roles.ADMIN]: classes.adminColor,
    [Roles.OWNER]: classes.ownerColor,
    [Roles.PUBLISHER]: classes.publisherColor
  };
  if (!teamMemberRole) return null;
  return <BootstrapTooltip placement="right" disableHoverListener={!isNavbarCollapsed} title={t('navbar.user-card.tooltip', {
    activeTeamName
  })} PopperProps={{
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 10]
      }
    }]
  }} data-sentry-element="BootstrapTooltip" data-sentry-component="UserCard" data-sentry-source-file="user-card.tsx">
      <Card data-testid={'user-card-component'} onClick={onClick} variant="outlined" className={clsx(classes.userCard, RoleColorClasses[teamMemberRole])} data-sentry-element="Card" data-sentry-source-file="user-card.tsx">
        <div className={classes.teamNameLetterWrapper}>
          <span className={classes.teamNameLetter}>{activeTeamName.charAt(0)}</span>
        </div>
        <CardContent className={classes.userCardContent} data-sentry-element="CardContent" data-sentry-source-file="user-card.tsx">
          <Typography component="div" className={classes.userData} data-sentry-element="Typography" data-sentry-source-file="user-card.tsx">
            <span className={classes.label}>{`${t('user-card.team.viewing')}:`}</span>
            <span className={classes.teamName}>{activeTeamName}</span>
            <i className={classes.arrowDown}></i>
          </Typography>
        </CardContent>
      </Card>
    </BootstrapTooltip>;
};
export default UserCard;