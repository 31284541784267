import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    input: {
      backgroundColor: theme.palette.shade7,
    },
    tagInput: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      direction: theme.direction,
    },
    tag: {
      textTransform: 'uppercase',
      fontWeight: 600,
      '& svg': {
        fontSize: `${theme.typography.pxToRem(20)} !important`,
        color: `${theme.palette.shade2} !important`,
      },
    },
    keyboardArrowDown: {
      '& .MuiInputAdornment-root': {
        marginRight: 8,
      },
      '& .EuiChipContainer-root': {
        [isRTL ? 'marginLeft' : 'marginRight']: 18,
      },
    },
    checkboxOption: {
      padding: 0,
      marginRight: 8,
    },
    checkboxOptionUnactive: {},
    checkboxOptionActive: {
      '& svg': {
        stroke: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.shade7} !important`,
      },
    },
  });
});

export default useStyles;
