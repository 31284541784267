import { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import MainLayout from '@/components/common/main.layout';
import Dialog, { DialogType } from '@/components/ui/Dialog';
import useStyles from './server-side-errors.styles';
import type { SSRError } from '../../types/global';
interface ServerSideErrorsProps {
  errors?: SSRError[];
}
const ServerSideErrorsComponent: React.FC<ServerSideErrorsProps> = ({
  errors
}: ServerSideErrorsProps) => {
  errors ??= [];
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const [isOpen, setOpen] = useState<boolean>(!!errors.length);
  const classes = useStyles();
  const onClose = (): void => {
    setOpen(false);
    if (errors?.[0].redirectUrl) void router.push(errors[0].redirectUrl);else if (window.history.length > 0) router.back();else void router.push('/');
  };
  const description = errors.map(({
    code,
    message
  }, i) => <div key={`${code ?? 'error'}-${i}`}>
      {i > 0 ? <div className={classes.errorDivider} /> : undefined}

      <Typography component="p" className={classes.description}>
        {code === 'errors.com.epicgames.creator-portal-backend.team_not_found' ? t('alert.team.does-not-exist', {
        teamId: router.query.team
      }) : message}
      </Typography>
      {code && <Typography component="p" className={classes.errorCode}>
          {code}
        </Typography>}
    </div>);
  return <>
      <MainLayout data-sentry-element="MainLayout" data-sentry-source-file="server-side-errors.tsx">
        <div />
      </MainLayout>
      <Dialog open={isOpen} dialogType={DialogType.ERROR} onClose={onClose} onSecondaryButton={onClose} secondaryButtonText={t('button.go-back')} data-sentry-element="Dialog" data-sentry-source-file="server-side-errors.tsx">
        {description}
      </Dialog>
    </>;
};
export default ServerSideErrorsComponent;