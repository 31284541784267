import { memo } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import useStyles from '@/components/common/navigation/team-card.styles';
import { Roles } from '@/util/teams';
import type { TeamMembershipRole } from '@creator-portal/common/types';
import type { JSX } from 'react';
const TeamCard = ({
  activeTeamName,
  teamMemberRole,
  onClick,
  memberCount
}: {
  activeTeamName: string;
  teamMemberRole?: TeamMembershipRole;
  memberCount?: number;
  onClick: () => void;
}): JSX.Element | null => {
  const classes = useStyles();
  const {
    t
  } = useTranslation();
  const RoleColorClasses = {
    [Roles.COLLABORATOR]: classes.collaboratorColor,
    [Roles.ADMIN]: classes.adminColor,
    [Roles.OWNER]: classes.ownerColor,
    [Roles.PUBLISHER]: classes.publisherColor
  };
  if (!teamMemberRole) return null;
  return <Card onClick={onClick} variant="outlined" className={clsx(classes.teamCard, RoleColorClasses[teamMemberRole])} data-sentry-element="Card" data-sentry-component="TeamCard" data-sentry-source-file="team-card.tsx">
      <CardContent className={classes.teamCardContent} data-sentry-element="CardContent" data-sentry-source-file="team-card.tsx">
        <div className={classes.teamName}>{activeTeamName}</div>
        {memberCount ? <div className={classes.teamCardFooter}>
            <div className={classes.teamMembersLabel}>{t('modal.switch-team.members')}</div>
            <div className={classes.teamStatistic}>{memberCount < 0 ? 0 : memberCount}</div>
          </div> : null}
      </CardContent>
    </Card>;
};
export default memo(TeamCard);