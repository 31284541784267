// TODO review this file changes. Can we delete it? - https://jira.it.epicgames.com/browse/FNCE-8311
import { Locale } from 'date-fns/locale';
import * as locales from 'date-fns/locale';

export const getStoreByIarcLang = (lang: string): { [key: string]: string } | null => {
  switch (lang) {
    case 'ar-SA':
      return {};
    case 'de-DE':
      return {};
    case 'es-ES':
      return {};
    case 'fr-FR':
      return {};
    case 'it-IT':
      return {};
    case 'ja-JP':
      return {};
    case 'ko-KR':
      return {};
    case 'pl-PL':
      return {};
    case 'pt-BR':
      return {};
    case 'ru-RU':
      return {};
    case 'tr-TR':
      return {};
    case 'en':
      return {};
    default:
      return null;
  }
};

export const getStoreByLang = (lang: string): { [key: string]: string } | null => {
  switch (lang) {
    case 'ar':
      return {};
    case 'de':
      return {};
    case 'es-ES':
      return {};
    case 'es-MX':
      return {};
    case 'fr':
      return {};
    case 'it':
      return {};
    case 'ja':
      return {};
    case 'ko':
      return {};
    case 'pl':
      return {};
    case 'pt-BR':
      return {};
    case 'ru':
      return {};
    case 'tr':
      return {};
    case 'en-US':
      return {};
    default:
      return null;
  }
};

export const getTranslationByKey = (iarcLang: string, key: string): string => {
  const store = getStoreByLang(iarcLang) || getStoreByIarcLang(iarcLang) || ({} as { [key: string]: string });

  return store[key] || key;
};

export const getTagTranslation = (iarcLang: string, tagValue: string): string => {
  // It is necessary in order to fix incorrect handling of keys that contain the ":" character. (for example 'difficulty: easy')
  const tagInStore = tagValue.includes(':') ? tagValue.replaceAll(':', '.') : tagValue;

  return getTranslationByKey(iarcLang, `options.island-tags.${tagInStore}`);
};

interface Locales {
  [key: string]: Locale;
}

export const getDateFnsLocale = (lang: string): Locale => {
  const dateFnsLocales = locales as Locales;
  const locale: Locale = dateFnsLocales[lang];

  switch (lang) {
    case 'es-ES':
      return locales['es'];
    case 'es-MX':
      return locales['es'];
    case 'pt-BR':
      return locales['ptBR'];

    default:
      if (locale) {
        return locale;
      }
      return locales['enUS'];
  }
};
