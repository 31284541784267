import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, Typography } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { MAX_IARC_EMAIL_LENGTH } from '@creator-portal/common/publishing/constants';
import { IARC_RATING_ONLINE_PRIVACY_URL } from '@/config/common/constants';
import { getPrivacyUrl } from '@/config/common/functions';
import { createSchemaForEditIarcEmail } from '@/services/publishing/publishing-validations';
import { EMAIL_FIELDS, IarcEmailData, RATING_FIELDS } from '@/components/publishing/deploy-release-flow/stepper-types';
import Dialog, { DialogType } from '@/components/ui/Dialog';
import TextField from '@/components/ui/TextField';
import useStyles from './edit-iarc-dialog.styles';
import type { JSX } from 'react';
export interface IarcEmailValues {
  iarcEmail: string;
}
interface EditIarcEmailDialogProps {
  onSubmit: (data: IarcEmailData) => void;
  onClose: () => void;
  email?: string;
  isOpen: boolean;
  isLoading: boolean;
  fieldName: EMAIL_FIELDS;
  hasIarcPortability: boolean;
}
const EditIarcEmailDialog: React.FC<EditIarcEmailDialogProps> = ({
  email = '',
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  fieldName,
  hasIarcPortability
}): JSX.Element => {
  const {
    t,
    i18n
  } = useTranslation();
  const classes = useStyles();
  const schema = useMemo(() => createSchemaForEditIarcEmail(t), [t]);
  const {
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: {
      errors
    }
  } = useForm<IarcEmailValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      [RATING_FIELDS.iarcEmail]: email
    }
  });
  const {
    iarcEmail: iarcEmailState
  } = watch();
  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(RATING_FIELDS.iarcEmail, e.target.value);
    await trigger(RATING_FIELDS.iarcEmail);
  };
  const handleEmailSubmit = (): void => {
    if (fieldName === EMAIL_FIELDS.EMAIL) {
      onSubmit({
        [EMAIL_FIELDS.EMAIL]: iarcEmailState
      });
    } else if (fieldName === EMAIL_FIELDS.PUBLIC_EMAIL) {
      onSubmit({
        [EMAIL_FIELDS.PUBLIC_EMAIL]: iarcEmailState
      });
    }
  };
  useEffect(() => {
    if (isOpen && !isLoading) {
      setValue(RATING_FIELDS.iarcEmail, email);
    }
    return () => clearErrors();
  }, [isLoading, email, isOpen]);
  const isErrorOccur = !!errors.iarcEmail?.message;
  const isNotEqualWithDefaultValue = iarcEmailState?.trim() !== email;
  const footerContent = <Typography component="p" className={classes.footerContent}>
      <Trans i18nKey="edit-iarc-email-dialog.footer-text" components={{
      a: <a href={IARC_RATING_ONLINE_PRIVACY_URL} target="_blank" rel="noopener noreferrer" />,
      a2: <a href={getPrivacyUrl(i18n.language)} target="_blank" rel="noopener noreferrer" />
    }} />
    </Typography>;
  return <Dialog open={isOpen} onClose={onClose} dialogType={DialogType.INFO} title={t('edit-iarc-email-dialog.title')} footerContent={footerContent} className={classes.dialog} primaryButtonText={t('button.save')} secondaryButtonText={t('button.close')} onSecondaryButton={onClose} onPrimaryButton={handleEmailSubmit} isSecondaryButtonDisabled={isLoading} isPrimaryButtonDisabled={isLoading || isErrorOccur || !isNotEqualWithDefaultValue} data-sentry-element="Dialog" data-sentry-component="EditIarcEmailDialog" data-sentry-source-file="edit-iarc-email-dialog.tsx">
      <Typography component="p" className={classes.description} data-sentry-element="Typography" data-sentry-source-file="edit-iarc-email-dialog.tsx">
        {t('edit-iarc-email-dialog.description')}
      </Typography>
      <div className={classes.textFieldBlock}>
        <TextField multiline maxLength={!hasIarcPortability ? MAX_IARC_EMAIL_LENGTH : undefined} error={!!errors[RATING_FIELDS.iarcEmail]?.message} value={iarcEmailState} name={RATING_FIELDS.iarcEmail} label={<>
              {t('edit-iarc-email-dialog.input-label')} <span className={classes.spanRequired}>*</span>
            </>} onChange={handleEmailChange} data-sentry-element="TextField" data-sentry-source-file="edit-iarc-email-dialog.tsx" />
        <FormHelperText error data-sentry-element="FormHelperText" data-sentry-source-file="edit-iarc-email-dialog.tsx">{errors[RATING_FIELDS.iarcEmail]?.message || ' '}</FormHelperText>
      </div>
    </Dialog>;
};
export default EditIarcEmailDialog;