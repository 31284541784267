// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"oB62dhpNDRHmHOYEXS8xz"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/creator-portal/static/one";import * as Sentry from '@sentry/nextjs';

let env: string;
let deployment: string;
let buildReleaseVersion: string;

Sentry.init({
  dsn: 'https://043cf4470c264049a959040f88d1f720@o10593.ingest.sentry.io/6585029',

  // Tracing causes us to hit the rate limit quickly
  enableTracing: false,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  async beforeSend(event) {
    if (event.request?.cookies) {
      delete event.request.cookies.EPIC_EG1;
      delete event.request.cookies.EPIC_EG1_REFRESH;
    }

    if (!env || !deployment) {
      const response = await fetch('/env');
      ({ env, deployment, buildReleaseVersion } = (await response.json()) as {
        env: string;
        deployment: string;
        buildReleaseVersion: string;
      });
    }

    event.environment = `${env}-${deployment}`;
    event.release = `creator-portal-web@${buildReleaseVersion}`;

    return event;
  },
});
