import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { trackClickInteraction } from '@/util/tracking';

import type { AuthSession } from '@creator-portal/common/types';

const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const maxTouchPoints = navigator.maxTouchPoints || 0;

  if (/mobile|android|iphone|ipod|blackberry|mini|windows\sce|palm/i.test(userAgent)) {
    return 'mobile';
  }
  if (/tablet|ipad|playbook|silk/i.test(userAgent) || (maxTouchPoints > 1 && window.matchMedia('(max-width: 1024px)').matches)) {
    return 'tablet';
  }
  return 'desktop';
};

export const useTrackScreenDimension = (session?: AuthSession) => {
  const router = useRouter();

  const sendAnalyticsData = useCallback(async () => {
    const route = router.route;
    const deviceType = getDeviceType();
    const viewport = `${window.innerWidth}x${window.innerHeight}`;
    const screen = `${window.screen.width}x${window.screen.height}`;

    trackClickInteraction({
      eventCategory: 'page',
      eventAction: `track_screen_dimension`,
      eventLabel: 'screen',
      portalAccountId: session?.accountId,
      data: {
        deviceType,
        route,
        viewport,
        screen,
      },
    });
  }, [router.route, session?.accountId]);

  useEffect(() => {
    if (!session) return;

    void sendAnalyticsData();
  }, [sendAnalyticsData, session]);
};
