export const IMPERSONATE_HEADER = 'X-ON-BEHALF-OF';

export const MONTH_OFFSET = 2;
export const THREE_MONTH_RANGE = 3;
export const SIX_MONTH_RANGE = 6;
export const TWELVE_MONTH_RANGE = 12;

export const USESWR_AUTO_REFRESH = 3600000;

export const PAGE_CONTROLS_STICKY_OFFSET_TOP_DESKTOP = 100;
export const PAGE_CONTROLS_STICKY_OFFSET_TOP_MOBILE = 60;

export const LOCAL_STORAGE_ESTIMATES_WIDGET_ACKNOWLEDGMENT_KEY = 'estimatesWidgetAcknowledgmentV2';

export const ROUNDING_STEP = 0.01;
