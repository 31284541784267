import { memo, useMemo } from 'react';
import { DialogComposer } from '@epic-ui/components';
import { Alert2Icon, AlertIcon, CampaignsIcon, InfoIcon, StatusApprovedIcon, WarningIcon } from '@epic-ui/icons';
import { CircularProgress, Typography } from '@mui/material';
import { clsx } from 'clsx';
import Button from '@/components/ui/Button';
import { withMuiRTL } from '@/hoc/withMuiRTL';
import useStyles from './Dialog.styles';
import type { ButtonProps } from '@/components/ui/Button';
export enum DialogType {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR_NEW = 'error_new',
  INFO_NEW = 'info_new',
  LOADING = 'loading',
}
export interface DialogProps {
  open: boolean;
  dialogType: DialogType;
  className?: string;
  title?: string;
  description?: any;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButton?: () => void;
  onSecondaryButton?: () => void;
  onClose?: () => void;
  children: any;
  footerContent: any;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
  displayCloseButton?: boolean;
  dataTestId?: string;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  headerIconType?: 'campaigns';
}
const Dialog: React.FunctionComponent<DialogProps> = ({
  open,
  onClose,
  onPrimaryButton,
  onSecondaryButton,
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  dialogType,
  isPrimaryButtonDisabled = false,
  isSecondaryButtonDisabled = false,
  children,
  footerContent = null,
  className,
  displayCloseButton = true,
  dataTestId,
  primaryButtonProps,
  secondaryButtonProps,
  headerIconType
}) => {
  const classes = useStyles();
  const hasPrimaryButton = useMemo(() => primaryButtonText && (onPrimaryButton || primaryButtonProps), [primaryButtonText, onPrimaryButton]);
  const hasSecondaryButton = useMemo(() => secondaryButtonText && (onSecondaryButton || secondaryButtonProps), [secondaryButtonText, onSecondaryButton]);
  const infoIcon = headerIconType === 'campaigns' ? <CampaignsIcon className={classes.infoIcon} /> : <InfoIcon className={classes.infoIcon} />;
  return <DialogComposer placeholder="" className={clsx(classes.composer, className, {
    [classes.composerError]: dialogType === DialogType.ERROR || DialogType.ERROR_NEW,
    [classes.composerInfo]: dialogType === DialogType.INFO || dialogType === DialogType.SUCCESS || dialogType === DialogType.INFO_NEW || dialogType === DialogType.LOADING,
    [classes.composerWarning]: dialogType === DialogType.WARNING
  })} dialogActions={<>
          <div className={classes.actions}>
            {hasSecondaryButton ? <Button {...secondaryButtonProps} data-testid="dialog_secondary_button" variant="outlined" className={classes.button} onClick={onSecondaryButton} disabled={isSecondaryButtonDisabled}>
                {secondaryButtonText}
              </Button> : null}

            {hasPrimaryButton ? <Button {...primaryButtonProps} data-testid="dialog_primary_button" className={classes.button} onClick={onPrimaryButton} disabled={isPrimaryButtonDisabled}>
                {primaryButtonText}
              </Button> : null}
          </div>
          {footerContent}
        </>} displayCloseButton={displayCloseButton} CloseButtonProps={{
    className: dialogType === DialogType.WARNING ? classes.closeBlack : classes.closeWhite
  }} dialogTitle={<>
          {dialogType === DialogType.ERROR && <AlertIcon className={classes.infoIcon} />}
          {dialogType === DialogType.ERROR_NEW && <WarningIcon className={classes.infoIcon} />}
          {dialogType === DialogType.INFO && infoIcon}
          {dialogType === DialogType.INFO_NEW && <AlertIcon className={classes.infoIcon} />}
          {dialogType === DialogType.WARNING && <Alert2Icon className={clsx(classes.infoIcon, classes.warningIcon)} />}
          {dialogType === DialogType.SUCCESS && <StatusApprovedIcon className={classes.approvedIcon} />}
          {dialogType === DialogType.LOADING && <CircularProgress color="inherit" style={{
      display: 'inline-block'
    }} className={classes.infoIcon} />}

          <Typography variant="h5" className={clsx(classes.title, {
      [classes.titleWarning]: dialogType === DialogType.WARNING
    })}>
            {title}
          </Typography>
        </>} fullWidth onClose={onClose} open={open} data-testid={dataTestId} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} data-sentry-element="DialogComposer" data-sentry-component="Dialog" data-sentry-source-file="Dialog.tsx">
      {children ? children : <Typography component="p" className={classes.description}>
          {description}
        </Typography>}
    </DialogComposer>;
};
export default memo(withMuiRTL(Dialog));