import { ProfileIcon } from '@epic-ui/icons';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import { usePathname, useRouter } from 'next/navigation';
import { canAccess } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import LocaleSwitcher from '@/components/common/locale-switcher.component';
import useStyles from '@/components/common/main.layout.styles';
import Button from '@/components/ui/Button';
import { useAuthSession } from '@/hooks/useAuthSession';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';
import type { JSX } from 'react';
interface NavigationBottomMobileProps {
  slideOpener: () => void;
}
export default function navigationBottomMobile({
  slideOpener
}: NavigationBottomMobileProps): JSX.Element {
  const classes = useStyles();
  const {
    t
  } = useTranslation();
  const user = useAuthSession();
  const router = useRouter();
  const pathname = usePathname();
  const creatorProgramStatus = useCreatorProgramStatus();
  const isEnrollButtonVisible = canAccess(user, PERMISSION.ICP_FEATURE) && !pathname?.includes('/enroll') && (creatorProgramStatus?.needsToEnroll || creatorProgramStatus?.resumeEnroll);
  const handleImpersonateAccount = () => {
    void router.push('/enroll');
  };
  return <Box className={classes.wrapper} data-sentry-element="Box" data-sentry-component="navigationBottomMobile" data-sentry-source-file="navigation-bottom-mobile.component.tsx">
      {isEnrollButtonVisible ? <div>
          <Button onClick={handleImpersonateAccount} size="medium" className={classes.button}>
            {creatorProgramStatus?.resumeEnroll ? t('epic.ecp.header.cta-btn.continue-enrollment') : t('epic.ecp.header.cta-btn.enroll')}
          </Button>
        </div> : null}
      <div className={classes.bottomBar}>
        <List component="nav" data-sentry-element="List" data-sentry-source-file="navigation-bottom-mobile.component.tsx">
          <ListItemButton key="User" component="a" className={clsx(classes.navLeftOption, classes.navLeftOptionHover)} onClick={slideOpener} data-sentry-element="ListItemButton" data-sentry-source-file="navigation-bottom-mobile.component.tsx">
            <ListItemIcon className={classes.listItemIcon} data-sentry-element="ListItemIcon" data-sentry-source-file="navigation-bottom-mobile.component.tsx">
              <ProfileIcon className={clsx(classes.appBarIcon, classes.listItemIcon)} data-sentry-element="ProfileIcon" data-sentry-source-file="navigation-bottom-mobile.component.tsx" />
            </ListItemIcon>
            <ListItemText className={classes.navLeftItem} data-sentry-element="ListItemText" data-sentry-source-file="navigation-bottom-mobile.component.tsx">{user?.displayName}</ListItemText>
          </ListItemButton>
        </List>
        <Box className={classes.switcherContainer} data-sentry-element="Box" data-sentry-source-file="navigation-bottom-mobile.component.tsx">
          <LocaleSwitcher data-sentry-element="LocaleSwitcher" data-sentry-source-file="navigation-bottom-mobile.component.tsx" />
        </Box>
      </div>
    </Box>;
}