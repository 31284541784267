import { useState } from 'react';
import { CharacterCountAdornment, ChipContainer } from '@epic-ui/components';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Autocomplete, Checkbox, Chip, InputAdornment } from '@mui/material';
import { clsx } from 'clsx';
import TextFieldWithMuiRTL from '@/components/common/inputs/text-field';
import useStyles from './ChipTextField.styles';
import type { JSX, SyntheticEvent } from 'react';
export type ChipTextFieldProps = {
  value: any;
  disabled: boolean;
  options: string[];
  getOptionLabel: (option: string) => string;
  textFieldlabel: string;
  maxTags?: number;
  className?: string;
  required?: boolean;
  onChange: (_event: SyntheticEvent<Element, Event>, value: string[]) => void;
  hasTagLimit?: boolean;
  disableCloseOnSelect?: boolean;
  showCheckboxesInList?: boolean;
};
const ChipTextFieldComponent = (props: ChipTextFieldProps): JSX.Element => {
  const classes = useStyles();
  const {
    options,
    required = true,
    maxTags = 100,
    disabled = false,
    value = [],
    textFieldlabel,
    getOptionLabel,
    className,
    onChange,
    hasTagLimit = true,
    disableCloseOnSelect = false,
    showCheckboxesInList = false
  } = props;
  const [open, setOpen] = useState(false);
  const tagsAmount = value?.length || 0;
  return <Autocomplete fullWidth disableClearable multiple freeSolo disableCloseOnSelect={disableCloseOnSelect} id="text-field-input" className={clsx(classes.input, {
    [classes.keyboardArrowDown]: !hasTagLimit
  }, className)} classes={{
    paper: classes.tagInput,
    input: classes.tagInput
  }} value={value} disabled={disabled} options={options} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} getOptionDisabled={() => hasTagLimit ? tagsAmount >= maxTags : false} getOptionLabel={getOptionLabel} renderOption={showCheckboxesInList ? (props, option, {
    selected
  }) => <li {...props}>
                <Checkbox checked={selected} className={clsx(classes.checkboxOption, !selected ? classes.checkboxOptionUnactive : classes.checkboxOptionActive)} />
                {getOptionLabel(option)}
              </li> : undefined} renderInput={params => <TextFieldWithMuiRTL {...params} label={textFieldlabel} required={required} inputProps={{
    ...params.inputProps,
    required: true
  }} InputLabelProps={{
    ...params.InputLabelProps
  }} InputProps={{
    ...params.InputProps,
    endAdornment: hasTagLimit ? <InputAdornment position="start">
                <CharacterCountAdornment max={maxTags} value={tagsAmount} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </InputAdornment> : <InputAdornment position="end" onClick={() => setOpen(!open)} style={{
      cursor: 'pointer'
    }}>
                <KeyboardArrowDown style={{
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
      }} />
              </InputAdornment>,
    required: true
  }} />} renderTags={(values, getProps) => <ChipContainer onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {values.map((value, index) => <Chip {...getProps({
      index
    })} key={index} size="small"
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    label={getOptionLabel(value)} className={classes.tag} />)}
        </ChipContainer>} onChange={onChange} data-sentry-element="Autocomplete" data-sentry-component="ChipTextFieldComponent" data-sentry-source-file="ChipTextField.tsx" />;
};
export default ChipTextFieldComponent;