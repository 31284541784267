import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OptionsMenu } from '@epic-ui/components';
import { FileDropArea, FileDropProvider } from '@epic-ui/uploader';
import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { clsx } from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { RATING_CERTIFICATE_MAX_SIZE } from '@creator-portal/common/publishing/constants';
import { IARC_RATING_ONLINE_PRIVACY_URL, RATINGS_GUIDE_URL } from '@/config/common/constants';
import { getPrivacyUrl } from '@/config/common/functions';
import { getRatingFileCertificate } from '@/services/iarc/iarc-service';
import { createSchemaForEditIarcRating } from '@/services/publishing/publishing-validations';
import InfoBox from '@/components/common/alerts/info-box';
import CircularLoader from '@/components/common/loaders/circular-loader';
import { MANAGE_RATING_FIELDS } from '@/components/publishing/deploy-release-flow/stepper-types';
import Button from '@/components/ui/Button';
import ChipTextFieldComponent from '@/components/ui/ChipTextField';
import Dialog, { DialogType } from '@/components/ui/Dialog';
import ArticleIcon from '@/styles/icons/publishing-stepper/article';
import DownloadIcon from '@/styles/icons/publishing-stepper/download';
import useStyles from './edit-iarc-dialog.styles';
import type { RatingData } from '@/util/ratingFunctions';
import type { TraditionalRatingUpload, UpdateTraditionalRating } from '@creator-portal/common/iarc/types';
import type { JSX } from 'react';
const IDS_WITH_SAME_AGE_RATING_TEXT = [36, 37];
export interface IarcRatingValues {
  file: File | null;
  ageRating: number;
  descriptors: string[];
  elements: string[];
}
interface EditIarcRatingDialogProps {
  isOpen: boolean;
  onSubmit: (ratingData: UpdateTraditionalRating | null, file: File | null) => void;
  onClose: () => void;
  isLoading: boolean;
  rating: RatingData;
  projectId: string;
  certId: string;
  handleOpenRevertToIARCDialog: () => void;
}
const areArraysEqual = (arr1: string[], arr2: string[]) => arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
const EditIarcRatingDialog: React.FC<EditIarcRatingDialogProps> = ({
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  rating,
  projectId,
  certId,
  handleOpenRevertToIARCDialog
}): JSX.Element => {
  const {
    t,
    i18n
  } = useTranslation();
  const classes = useStyles();
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(true);
  const [uploadedFile, setUploadedFile] = useState<TraditionalRatingUpload | null>(null);
  const [fileIsRequired, setFileIsRequired] = useState<boolean>(rating.ratingBoardId !== 9); // Russia

  useEffect(() => {
    void (async () => {
      try {
        const ratingCertificate = await getRatingFileCertificate(projectId, certId, rating.ratingBoardId);
        if (ratingCertificate && ratingCertificate.originalName) {
          setUploadedFile(ratingCertificate);
          setFileIsRequired(true);
        }
      } catch (error) {
        console.error('Error fetching rating file certificate:', error);
      } finally {
        setIsLoadingFile(false);
      }
    })();
  }, []);
  const ageRatingsText = rating.ageRatings;
  const descriptorsText = rating.descriptors.map(el => el.descriptorText);
  const elementsText = rating.interactiveElements.map(el => el.interactiveElementText);
  const defaultDescriptors = rating.descriptors.filter(el => rating.descriptorIds.includes(el.descriptorId)).map(el => el.descriptorText);
  const defaultElements = rating.interactiveElements.filter(el => rating.interactiveElementIds.includes(el.interactiveElementId)).map(el => el.interactiveElementText);
  const schema = useMemo(() => createSchemaForEditIarcRating(t, fileIsRequired), [t, fileIsRequired]);
  const {
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: {
      errors
    }
  } = useForm<IarcRatingValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      [MANAGE_RATING_FIELDS.file]: null,
      [MANAGE_RATING_FIELDS.ageRating]: rating.ageRatingId,
      [MANAGE_RATING_FIELDS.descriptors]: defaultDescriptors,
      [MANAGE_RATING_FIELDS.elements]: defaultElements
    }
  });
  const {
    file,
    ageRating,
    descriptors,
    elements
  } = watch();
  const handleAddFile = async (file: File[] | null) => {
    if (file && file.length > 0) {
      setValue(MANAGE_RATING_FIELDS.file, file[0]);
      await trigger(MANAGE_RATING_FIELDS.file);
    }
  };
  const handleDeleteFile = async () => {
    setUploadedFile(null);
    setValue(MANAGE_RATING_FIELDS.file, null);
    await trigger(MANAGE_RATING_FIELDS.file);
  };
  const handleChange = (fieldName: 'ageRating' | 'descriptors' | 'elements', value: number | string[]) => {
    setValue(fieldName, value);
  };
  useEffect(() => {
    return () => clearErrors();
  }, [isLoading, rating, isOpen]);
  const isErrorOccur = fileIsRequired && !file && !uploadedFile || errors?.file?.message || !ageRating;
  const isNotEqualWithDefaultFileValue = file;
  const isNotEqualWithDefaultDataValue = ageRating !== rating.ageRatingId || !areArraysEqual(descriptors, defaultDescriptors) || !areArraysEqual(elements, defaultElements);
  const handleSubmit = (): void => {
    let ratingData: UpdateTraditionalRating | null = null;
    if (isNotEqualWithDefaultDataValue || !rating?.isTraditional) {
      const descriptorIds = rating.descriptors.filter(el => descriptors.includes(el.descriptorText)).map(el => el.descriptorId);
      const interactiveElementIds = rating.interactiveElements.filter(el => elements.includes(el.interactiveElementText)).map(el => el.interactiveElementId);
      if (ageRating) {
        ratingData = {
          ratingBoardId: rating.ratingBoardId,
          ageRatingId: ageRating,
          descriptorIds,
          interactiveElementIds
        };
      }
    }
    onSubmit(ratingData, file);
  };
  const pdfFile = file ? {
    link: false,
    name: file.name,
    size: file.size
  } : uploadedFile ? {
    link: uploadedFile.bucketPath,
    name: uploadedFile.originalName,
    size: uploadedFile.size
  } : null;
  const divToTriggerRef = useRef<HTMLInputElement>(null);
  const handleReplaceFile = () => {
    if (divToTriggerRef && divToTriggerRef.current) {
      divToTriggerRef.current.click();
    }
  };
  const refFileDropArea: {
    ref?: React.RefObject<HTMLInputElement> | undefined;
  } = pdfFile ? {
    ref: divToTriggerRef as React.RefObject<HTMLInputElement>
  } : {};
  const pdfFileSize = !errors?.file?.message && pdfFile ? ((pdfFile?.size || 0) / 1024 / 1024).toFixed(2) : 0;
  const handleDownloadFile = () => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = pdfFile?.name || '';
      link.click();
      URL.revokeObjectURL(fileUrl);
    } else if (uploadedFile) {
      const link = document.createElement('a');
      link.href = typeof pdfFile?.link === 'string' ? pdfFile.link : '';
      link.download = pdfFile?.name || '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const footerContent = <Typography component="p" className={clsx(classes.footerContent, classes.footerContentManageRating)}>
      <Trans i18nKey="edit-iarc-email-dialog.footer-text" components={{
      a: <a href={IARC_RATING_ONLINE_PRIVACY_URL} target="_blank" rel="noopener noreferrer" />,
      a2: <a href={getPrivacyUrl(i18n.language)} target="_blank" rel="noopener noreferrer" />
    }} />
    </Typography>;
  return <Dialog open={isOpen} onClose={onClose} dialogType={DialogType.INFO} title={t('edit-iarc-rating-dialog.title', {
    ratingBoardShortText: rating.ratingBoardShortText
  })} footerContent={!rating?.isLocked && footerContent} className={classes.dialog} primaryButtonText={t('button.save')} secondaryButtonText={t('button.close')} onSecondaryButton={onClose} onPrimaryButton={!rating?.isLocked && handleSubmit} isSecondaryButtonDisabled={isLoading} isPrimaryButtonDisabled={isLoading || isErrorOccur || !isNotEqualWithDefaultFileValue && !isNotEqualWithDefaultDataValue} data-sentry-element="Dialog" data-sentry-component="EditIarcRatingDialog" data-sentry-source-file="edit-iarc-rating-dialog.tsx">
      {rating?.isLocked ? <InfoBox severity="warning" className={classes.warningInfoBox}>
          {t('edit-iarc-rating-dialog.warning-message')}
        </InfoBox> : null}
      <Typography component="p" className={clsx(classes.description, classes.descriptionManageRating)} data-sentry-element="Typography" data-sentry-source-file="edit-iarc-rating-dialog.tsx">
        <Trans i18nKey="edit-iarc-rating-dialog.description" values={{
        ratingBoardShortText: rating.ratingBoardShortText,
        ratingBoardText: rating.ratingBoardText
      }} components={{
        a: <a href={`${RATINGS_GUIDE_URL}#${rating.ratingBoardShortText.toLowerCase()}`} target="_blank" rel="noopener noreferrer" />
      }} data-sentry-element="Trans" data-sentry-source-file="edit-iarc-rating-dialog.tsx" />
      </Typography>
      <div className={classes.textFieldBlock}>
        {rating?.isTraditional && !rating?.isLocked ? <Button variant="outlined" className={classes.revertToIARC} onClick={handleOpenRevertToIARCDialog}>
            {t('edit-iarc-rating-dialog.revert-button')}
          </Button> : null}
        <div className={classes.fieldFileTitle}>{t('edit-iarc-email-dialog.input.file.title')}</div>
        <div className={classes.fieldFileSubtitle}>
          {t('edit-iarc-email-dialog.input.file.subtitle')} {fileIsRequired && <span className={classes.spanRequired}>*</span>}
        </div>
        {isLoadingFile ? <div className={classes.dCircularLoader}>
            <CircularLoader cssClassName="loader-after-list" />
          </div> : <FileDropProvider>
            <FileDropArea className={clsx(!pdfFile ? classes.fileDropArea : classes.fileContentHide, {
          [classes.fileDropAreaError]: !!errors?.file?.message
        })} label={<div>
                  <Trans i18nKey="edit-iarc-email-dialog.input.file.label" values={{
            fileSize: RATING_CERTIFICATE_MAX_SIZE
          }} components={{
            a: <span className={classes.linkStyle} />
          }} />
                </div>} activeLabel="activeLabel" inputProps={{
          multiple: false,
          ...refFileDropArea
        }} disabled={rating?.isLocked} onDrop={handleAddFile} />
            {pdfFile ? <div className={classes.uploadedFileContent}>
                <div>
                  <div>
                    <ArticleIcon />
                  </div>
                  <div>
                    <div>{pdfFile?.name}</div>
                    <div className={classes.uploadedFileSize}>
                      <Trans i18nKey="edit-iarc-email-dialog.file-size" values={{
                  fileSize: pdfFileSize
                }} components={{
                  span: <span />
                }} />
                    </div>
                  </div>
                </div>
                <div>
                  {!rating?.isLocked ? <OptionsMenu placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <MenuItem onClick={handleDownloadFile}>{t('button.download')}</MenuItem>
                      <MenuItem onClick={handleReplaceFile}>{t('button.replace')}</MenuItem>
                      <MenuItem onClick={handleDeleteFile}>{t('button.delete')}</MenuItem>
                    </OptionsMenu> : <IconButton onClick={handleDownloadFile}>
                      <DownloadIcon />
                    </IconButton>}
                </div>
              </div> : null}
            <FormHelperText error className={classes.formHelperText}>
              {errors?.file?.message || ' '}
            </FormHelperText>
          </FileDropProvider>}
      </div>
      <div>
        <FormControl className={classes.selectField} disabled={rating?.isLocked} data-sentry-element="FormControl" data-sentry-source-file="edit-iarc-rating-dialog.tsx">
          <InputLabel data-sentry-element="InputLabel" data-sentry-source-file="edit-iarc-rating-dialog.tsx">
            {t('edit-iarc-email-dialog.input.category.label')} <span className={classes.spanRequired}>*</span>
          </InputLabel>
          <Select multiple={false} value={ageRating} onChange={e => handleChange(MANAGE_RATING_FIELDS.ageRating, e.target.value as number)} IconComponent={KeyboardArrowDownIcon} data-sentry-element="Select" data-sentry-source-file="edit-iarc-rating-dialog.tsx">
            {ageRatingsText.map(el => <MenuItem key={el.ageRatingId} value={el.ageRatingId}>
                {el.ageRatingText || el.ageRatingShortText}
                {IDS_WITH_SAME_AGE_RATING_TEXT.includes(el.ageRatingId) && ` ${el.ageControl}+`}
              </MenuItem>)}
          </Select>
        </FormControl>

        <ChipTextFieldComponent required={false} value={descriptors} disabled={!!rating?.isLocked} options={descriptorsText} getOptionLabel={option => option} textFieldlabel={t('edit-iarc-email-dialog.input.descriptors.label')} className={classes.selectAutocompleteField} onChange={(_, value) => handleChange(MANAGE_RATING_FIELDS.descriptors, value)} hasTagLimit={false} disableCloseOnSelect showCheckboxesInList data-sentry-element="ChipTextFieldComponent" data-sentry-source-file="edit-iarc-rating-dialog.tsx" />

        <ChipTextFieldComponent required={false} value={elements} disabled={!!rating?.isLocked} options={elementsText} getOptionLabel={option => option} textFieldlabel={t('edit-iarc-email-dialog.input.elements.label')} className={classes.selectAutocompleteField} onChange={(_, value) => handleChange(MANAGE_RATING_FIELDS.elements, value)} hasTagLimit={false} disableCloseOnSelect showCheckboxesInList data-sentry-element="ChipTextFieldComponent" data-sentry-source-file="edit-iarc-rating-dialog.tsx" />
      </div>
    </Dialog>;
};
export default EditIarcRatingDialog;