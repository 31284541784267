import fetch from 'cross-fetch';
import { cloneDeep } from 'lodash';
import getConfig from 'next/config';

import { isNode } from '@creator-portal/common/util/env';
import { CSRF_COOKIE_NAME } from '@creator-portal/common/http';

import { getCookieValue } from './cookie';

import type { IncomingMessage, ServerResponse } from 'node:http';

export interface ErrorPayload {
  errors?: any;
  errorCode: string;
  errorMessage: string;
  errorStack?: string[];
}

interface XhrErrorResponse {
  success: false;
  url: string;
  method?: string;
  status: number;
  statusText?: string;
  data: ErrorPayload;
}

interface XhrSuccessResponse<T> {
  success: true;
  url: string;
  method?: string;
  status: number;
  statusText?: string;
  data: T;
}

interface ServerContext {
  req?: IncomingMessage & { cookies?: Record<string, string | undefined> };
  res?: ServerResponse;
  resolvedUrl?: string;
}

export type XhrResponse<T> = XhrSuccessResponse<T> | XhrErrorResponse;

const { serverRuntimeConfig = {} } = getConfig() || {};
const { EPIC_APP_BACKEND_SERVER_PROTOCOL, EPIC_APP_BACKEND_SERVER_PORT } = serverRuntimeConfig;
const BACKEND = EPIC_APP_BACKEND_SERVER_PROTOCOL ? `${EPIC_APP_BACKEND_SERVER_PROTOCOL}://localhost:${EPIC_APP_BACKEND_SERVER_PORT}` : '';

const ContentTypeJson = { 'Content-Type': 'application/json' };

export class CustomError<T> extends Error {
  errorCode: string;
  errorMessage: string;
  data?: T;
  constructor(msg: string, errorCode: string, errorMessage: string, data?: T) {
    super(msg);

    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.data = data;
    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

/** throw a structured error if the supplied xhr response is in an error state. */
export function throwOnFailure<T>(rsp: XhrResponse<T>): asserts rsp is XhrSuccessResponse<T> {
  if (rsp.success) return;

  if (rsp.status === 401) {
    const currentURL = window.location.pathname;

    window.location.href = `/auth/login?redirectTo=${encodeURIComponent(currentURL)}`;
  }

  const errorStack = rsp.data.errorStack || [];
  const { errorCode, errorMessage } = rsp.data;
  if (!errorCode) throw new CustomError(`(${rsp.status}) ${rsp.statusText || ''}\n\n${JSON.stringify(rsp.data)}`, '', '', rsp.data);

  let msg = `xhr failure: ${errorCode}\n${errorMessage}\n\nrequest: [${rsp.method || '?'}] ${rsp.url.replace(BACKEND, '')}`;

  msg = `${msg}\n\nBackend Stack:`;
  errorStack.forEach((line) => (msg += `\n${line}`));

  throw new CustomError(msg, errorCode, errorMessage, rsp.data);
}

export function isErrorPayload(e: unknown): e is ErrorPayload {
  return e !== null && typeof e === 'object' && 'errorCode' in e && 'errorMessage' in e;
}

/** xhr client factory. */
export function getInstance(ctx?: ServerContext): XhrService {
  return new XhrService(ctx);
}

/** swr fetcher function that utilizes the xhr service. */
export async function fetchSwr<T>(input: RequestInfo, init: RequestInit = {}): Promise<T> {
  const xhr = getInstance();
  const rsp = await xhr.fetchJson<T>(input, init);
  throwOnFailure(rsp);

  return rsp.data;
}

export async function fetchSwrNullable<T>(input: RequestInfo, init: RequestInit = {}): Promise<T | null> {
  const xhr = getInstance();
  const rsp = await xhr.fetchJson<T>(input, init);
  throwOnFailure(rsp);

  if (rsp.status === 204) return null;

  return rsp.data;
}

class XhrService {
  constructor(private readonly ctx?: ServerContext) {}

  public getCurrentURL(): string | undefined {
    return this.ctx?.resolvedUrl || undefined;
  }

  public getCSRFTokenFromCookie(): string | undefined {
    // Calls made from client side do not have nextjs server context. In these
    // cases we need to do a lookup for document.cookie.
    return isNode() ? this.ctx?.req?.cookies?.[CSRF_COOKIE_NAME] : getCookieValue(CSRF_COOKIE_NAME, document.cookie);
  }

  public async fetchJson<T>(requestInfo: RequestInfo, requestInit: RequestInit = {}): Promise<XhrResponse<T>> {
    // Minimal fix before the GDC to solve issue where the init variable was mutated here
    // which affected the passed objects outside this function.
    // TODO: After the GDC this function should be refactored to not mutate the input parameters
    const init = cloneDeep(requestInit);
    let input = cloneDeep(requestInfo);
    if (!init.headers) init.headers = {};

    let path = typeof input === 'string' ? input : input.url;
    if (path.includes('://')) {
      path = new URL(path).pathname;
    }

    // FNCE-6040 -- Client Side Path Traversal last effort prevention
    // this will really only catch CSPT attempts if URL was passed in here as a string...
    // Request objects will have already normalized the url, making this unaware of CSPT attempts
    if (path.includes('..')) {
      throw new Error('Invalid path. Path traversal arguments are not allowed');
    }

    if (typeof input === 'string' && !input.startsWith('http')) {
      if (!input.startsWith('/')) throw new Error("ArgumentException: relative urls must start with '/' (type: string)");

      if (isNode())
        // we're doing a server-side fetch from the local backend.
        input = `${BACKEND}${input}`;
    } else if (input instanceof Request && !input.url.startsWith('http')) {
      if (!input.url.startsWith('/')) throw new Error("ArgumentException: relative urls must start with '/' (type: request)");

      if (isNode())
        // we're doing a server-side fetch from the local backend.
        input = new Request(`${BACKEND}${input.url}`, input);
    }

    const { ctx } = this;
    if (ctx && ctx.req && input.toString().toLocaleLowerCase().startsWith(BACKEND)) {
      // this xhr request is destined for our backend... copy all the headers from the provided incoming request.
      init.headers = { ...(ctx.req.headers as any), ...init.headers, 'x-ssr': '1' };
      init.headers && delete init.headers['connection']; // connection header not allowed by node18 fetch
    }

    const csrfToken = this.getCSRFTokenFromCookie();
    if (csrfToken) {
      init.headers = { ...init.headers, 'x-csrf-token': csrfToken };
    }

    if (!(init.body instanceof FormData)) {
      init.headers = { ...init.headers, ...ContentTypeJson };
    }

    // perform fetch.
    return fetch(input, init).then(async (rsp) => {
      if (ctx?.res && input.toString().toLocaleLowerCase().startsWith(BACKEND)) {
        // this response is coming from our backend...make sure any set cookies are forwarded.
        const cookies = rsp.headers.get('set-cookie');
        if (cookies && !ctx.res.headersSent) ctx.res.setHeader('set-cookie', cookies);
      }

      const isJsonRsp = rsp.headers.get('content-type')?.includes('application/json');

      if (rsp.ok) {
        if (rsp.status !== 204) {
          // TODO: This is probably not the best way to handle this. The issue is publishing response is not returning a json object.
          let data = {} as T;
          if (isJsonRsp) data = (await rsp.json()) as T;

          return { success: true, status: rsp.status, statusText: rsp.statusText, method: init.method, url: input.toString(), data };
        }
        return {
          success: true,
          status: rsp.status,
          statusText: rsp.statusText,
          method: init.method,
          url: input.toString(),
          data: {} as T,
        };
      }

      const dataText = await rsp.text();
      let data: T | string = dataText;

      try {
        if (isJsonRsp) {
          data = JSON.parse(dataText) as T;
        }
      } catch (e) {
        data = dataText;
      }

      return {
        success: false,
        status: rsp.status,
        statusText: rsp.statusText,
        method: init.method,
        url: input.toString(),
        data: isJsonRsp
          ? (data as ErrorPayload)
          : { errorCode: `"xhr.${rsp.status}`, errorMessage: `http ${rsp.status} ${rsp.statusText}`, errors: data },
      };
    });
  }
}

export type { XhrService };
