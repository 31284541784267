import '@/styles/global.css';
import '@/styles/fonts/index.css';
import '@/styles/nprogress.css';
import '@epic-typeface/inter';
import '@epic-typeface/inter-tight';
import '@epic-typeface/recursive';
import { useEffect, useMemo, useState } from 'react';
import { createTheme as createEdsMuiTheme } from '@eds-web/mui';
import { EDSProvider, themeClass } from '@eds-web/react';
import { createEpicTheme } from '@epic-ui/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as StylesProvider } from '@mui/styles';
import { appWithTranslation, useTranslation } from 'next-i18next';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import nProgress from 'nprogress';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { SWRConfig } from 'swr';
import root from 'window-or-global';
import { getDateFnsLocale } from '@creator-portal/common/util/locales';
import { makeNavPath } from '@creator-portal/common/util/makeNavPath';
import { LOCAL_STORAGE_ESTIMATES_WIDGET_ACKNOWLEDGMENT_KEY } from '@creator-portal/common/analytics/constants';
import { PERSONAL_TEAM_ID } from '@creator-portal/common/publishing/constants';
import { DEFAULT_LOCALE } from '@/config/common/locale.config';
import { getCreatorProgramStatusUrl } from '@/config/profile/functionalConstants';
import { getTeamsUrl } from '@/config/teams/functionalConstants';
import { getCreatorPrivileges } from '@/services/profile/profile-service';
import { ErrorBoundary } from '@/components/common/error-boundary';
import ServerSideErrors from '@/components/common/server-side-errors';
import { useTrackScreenDimension } from '@/hooks/use-track-screen-dimension';
import { AuthSessionProvider } from '@/contexts/auth-session.context';
import { ColorModeProvider } from '@/contexts/color-mode.context';
import { CreatorPrivilegesProvider } from '@/contexts/creator-privileges.context';
import { CreatorProgramStatusProvider } from '@/contexts/creator-program-status.context';
import { PublicRuntimeConfigProvider } from '@/contexts/public-runtime-config.provider';
import { TeamsProvider } from '@/contexts/teams.context';
import { addAuthQueryParameters } from '@/util/addAuthQueryParameters';
import { log } from '@/util/logging';
import * as Xhr from '@/util/xhr';
import { alertOverrides } from '@/styles/overrides/alerts';
import { breakpoints } from '@/styles/overrides/breakpoints';
import { commonColors } from '@/styles/overrides/commonColors';
import { darkColors } from '@/styles/overrides/darkColors';
import { lightColors } from '@/styles/overrides/lightColors';
import { tooltipsOverrides } from '@/styles/overrides/tooltips';
import { typography } from '@/styles/overrides/typography';
import { getPublicRuntimeConfig } from '@/public-runtime-config';
import nextI18NextConfig from '../../next-i18next.config.cjs';
import type { PublicRuntimeConfig } from '@/public-runtime-config';
import type { GlobalPageProps } from '@/types/global';
import type { AuthSession, CreatorAlerts, CreatorPrivileges, CreatorProgramStatusResponse, PagedResults, TeamSearchResult } from '@creator-portal/common/types';
import type { AppContext, AppInitialProps } from 'next/app';
import type { JSX } from 'react';
if (process.env.NODE_ENV === 'development' && process.env.ENABLE_MOCK_SERVICE_WORKER === 'true') {
  // import module for side effects
  require('../mocks');
}
const DEFAULT_THEME_MODE = 'dark';
const method = 'GET';
export interface CustomPageProps {
  teams: TeamSearchResult[];
}
export interface CustomAppProps extends AppInitialProps<Record<string, any> & GlobalPageProps> {
  Component?: any;
  session?: AuthSession;
  creatorProgramStatus?: CreatorProgramStatusResponse;
  creatorPrivileges?: CreatorPrivileges | null;
  publicRuntimeConfig?: PublicRuntimeConfig;
  canonicalUrl?: string;
  alerts?: CreatorAlerts;
}

// Only call in browser
function filterLocaleFromQS() {
  const loc = document.location;
  const {
    search
  } = document.location;
  if (!search) return; // no querystring to filter.

  if (search === '?')
    // just a trailing question mark, no real params (remove it).
    return history.replaceState(window.history.state, '', `${loc.origin}${loc.pathname}`);
  const args = loc.search.slice(1).split('&').filter(q => !q.startsWith('locale='));
  history.replaceState(window.history.state, '', `${loc.origin}${loc.pathname}${args.length ? '?' : ''}${args.join('&')}`);
}
function getCanonicalUrl(app: AppContext): string | undefined {
  let canonicalUrl: string | undefined;
  const {
    req,
    query
  } = app.ctx;
  if (req) {
    // resolve on server using req object.
    const scheme = 'https'; // this will always be https from a user's perspective.
    const path = app.ctx.pathname;
    const hostname = req.headers.host;
    if (!hostname) {
      log.error('Expected host header not set.', '_app.tsx => getCanonicalUrl');
      return canonicalUrl;
    }
    canonicalUrl = `${scheme}://${hostname}${path}`;
    if (query) {
      const args = {
        ...query
      };
      delete args.locale;
      const qs = Object.entries(args).reduce((prev, [key, val]) => {
        if (!val) return prev;else if (typeof val === 'string') return prev ? `${prev}&${key}=${val}` : `${key}=${val}`;else return prev ? `${prev}${val.reduce((x, y) => `${x}&${key}=${y}`, '')}` : `${prev}${val.reduce((x, y) => `${x}&${key}=${y}`, '')}`;
      }, '');
      canonicalUrl = `${canonicalUrl}${qs ? `?${qs}` : ''}`;
    }
  } else {
    // resolve on client using document
    const [base, qs] = document.location.href.split('?');
    canonicalUrl = base;
    if (qs) {
      const args = qs.split('&').filter(q => !q.startsWith('locale='));
      canonicalUrl = `${canonicalUrl}${args.length ? '?' : ''}${args.join('&')}`;
    }
  }
  return canonicalUrl;
}
const CustomApp = ({
  Component,
  pageProps,
  session,
  creatorProgramStatus,
  canonicalUrl,
  creatorPrivileges,
  publicRuntimeConfig
}: CustomAppProps): JSX.Element => {
  const {
    i18n,
    t
  } = useTranslation();
  const [mounted, setMounted] = useState(false);
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>(DEFAULT_THEME_MODE);
  const [themeDirection, setThemeDirection] = useState<'rtl' | 'ltr' | undefined>(undefined);
  Router.events.on('routeChangeStart', () => nProgress.start());
  Router.events.on('routeChangeError', () => nProgress.done());
  Router.events.on('routeChangeComplete', () => nProgress.done());
  const edsMuiTheme = createEdsMuiTheme();
  useTrackScreenDimension(session);
  useEffect(() => {
    setMounted(true);
    document.getElementById('jss-server-side')?.remove();
    filterLocaleFromQS();
    return () => {
      Router.events.off('routeChangeStart', () => nProgress.start());
      Router.events.off('routeChangeError', () => nProgress.done());
      Router.events.off('routeChangeComplete', () => nProgress.done());
    };
  }, []);
  useEffect(() => {
    const savedThemeMode = localStorage.getItem('themeMode') || DEFAULT_THEME_MODE;
    if (savedThemeMode !== themeMode) {
      setThemeMode(themeMode === 'light' ? 'dark' : 'light');
    }
  }, []);
  useEffect(() => {
    const isRTL = /^ar/.test(i18n.language);
    const direction = isRTL ? 'rtl' : 'ltr';
    if (themeDirection !== direction) setThemeDirection(direction);
  }, [i18n.language]);
  useEffect(() => {
    if (!session) {
      localStorage.removeItem(LOCAL_STORAGE_ESTIMATES_WIDGET_ACKNOWLEDGMENT_KEY);
    }
  }, [session]);
  const colorMode = useMemo(() => ({
    toggleColorMode: () => {
      const nextColorMode = themeMode === 'light' ? 'dark' : 'light';
      setThemeMode(nextColorMode);
      localStorage.setItem('themeMode', nextColorMode);
    }
  }), [themeMode]);
  const theme = useMemo(() => createEpicTheme({
    direction: themeDirection,
    breakpoints,
    palette: {
      mode: themeMode,
      ...(themeMode === 'light' ? lightColors : darkColors),
      ...commonColors
    },
    components: {
      ...tooltipsOverrides,
      ...alertOverrides
    },
    typography,
    zIndex: {
      modal: 9001,
      tooltip: 10001
    }
  }), [themeDirection, themeMode]);
  const hasServerSideErrors = Array.isArray(pageProps.errors) && pageProps.errors.length > 0;
  const lang = i18n.language;
  const locale = getDateFnsLocale(lang);

  /* eslint-disable */
  registerLocale(lang, locale);
  setDefaultLocale(lang);
  /* eslint-enable */

  return <StyledEngineProvider injectFirst data-sentry-element="StyledEngineProvider" data-sentry-component="CustomApp" data-sentry-source-file="_app.tsx">
      <MuiProvider theme={edsMuiTheme} data-sentry-element="MuiProvider" data-sentry-source-file="_app.tsx">
        <StylesProvider theme={edsMuiTheme} data-sentry-element="StylesProvider" data-sentry-source-file="_app.tsx">
          <ColorModeProvider value={colorMode} data-sentry-element="ColorModeProvider" data-sentry-source-file="_app.tsx">
            <MuiProvider theme={theme} data-sentry-element="MuiProvider" data-sentry-source-file="_app.tsx">
              <StylesProvider theme={theme} data-sentry-element="StylesProvider" data-sentry-source-file="_app.tsx">
                <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="_app.tsx" />
                <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
                  <title>{t('header.site-title')}</title>
                  <meta name="viewport" content="width=device-width, initial-scale=1.0" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                  <link rel="canonical" href={canonicalUrl} />
                </Head>
                <AuthSessionProvider value={session} data-sentry-element="AuthSessionProvider" data-sentry-source-file="_app.tsx">
                  <div style={{
                  visibility: mounted ? 'visible' : 'hidden'
                }}>
                    <CreatorPrivilegesProvider value={creatorPrivileges} data-sentry-element="CreatorPrivilegesProvider" data-sentry-source-file="_app.tsx">
                      <CreatorProgramStatusProvider value={creatorProgramStatus} data-sentry-element="CreatorProgramStatusProvider" data-sentry-source-file="_app.tsx">
                        <PublicRuntimeConfigProvider config={publicRuntimeConfig} data-sentry-element="PublicRuntimeConfigProvider" data-sentry-source-file="_app.tsx">
                          <SWRConfig value={{
                          fallback: pageProps.fallback ?? {}
                        }} data-sentry-element="SWRConfig" data-sentry-source-file="_app.tsx">
                            <TeamsProvider value={pageProps.teams} data-sentry-element="TeamsProvider" data-sentry-source-file="_app.tsx">
                              <div className={themeClass}>
                                <EDSProvider dir={themeDirection} locale={lang} data-sentry-element="EDSProvider" data-sentry-source-file="_app.tsx">
                                  {hasServerSideErrors ? <ServerSideErrors errors={pageProps.errors} /> : <ErrorBoundary>
                                      <Component {...pageProps} />
                                    </ErrorBoundary>}
                                </EDSProvider>
                              </div>
                            </TeamsProvider>
                          </SWRConfig>
                        </PublicRuntimeConfigProvider>
                      </CreatorProgramStatusProvider>
                    </CreatorPrivilegesProvider>
                  </div>
                </AuthSessionProvider>
              </StylesProvider>
            </MuiProvider>
          </ColorModeProvider>
        </StylesProvider>
      </MuiProvider>
    </StyledEngineProvider>;
};
CustomApp.getInitialProps = async (app: AppContext): Promise<CustomAppProps> => {
  const xhr = Xhr.getInstance(app.ctx);
  const canonicalUrl = getCanonicalUrl(app);
  const initialProps = await App.getInitialProps(app);
  const {
    query,
    pathname,
    res,
    req,
    asPath
  } = app.ctx;
  const referer = req?.headers.referer;
  let lang: string | null | undefined;
  try {
    lang = referer && new URL(referer).searchParams.get('lang');
  } catch {
    lang = DEFAULT_LOCALE;
    log.error(`Error occurred while parsing the referer: ${referer}`, '_app.tsx => getInitialProps');
  }
  if (pathname === '/' && res) {
    res.writeHead(307, {
      Location: makeNavPath('/welcome', asPath, {
        searchParams: {
          team: PERSONAL_TEAM_ID,
          ...(lang && {
            lang
          })
        }
      })
    });
    res.end();
  }
  if (!root.location) {
    (root as any).location = {
      protocol: '',
      hostname: '',
      port: '3000',
      origin: '',
      hash: '' //is not included on next ssr
    };
  }
  let teams: TeamSearchResult[] = [];
  let session: AuthSession | undefined;
  let creatorProgramStatus: CreatorProgramStatusResponse | undefined;
  let creatorPrivileges: CreatorPrivileges | null = null;
  const url = addAuthQueryParameters('/auth/session?', query); // note: we include all query params in this request to give the backend an opportunity to see them.

  try {
    const rsp = await xhr.fetchJson<AuthSession>(url, {
      method
    });
    if (rsp.success && rsp.status === 200) {
      session = rsp.data;
    }
  } catch (e: unknown) {
    log.error(e as Error, 'fetching session for _app initial props', true);
  }
  try {
    if (session) {
      const [creatorProgramStatusResponse, teamsResponse, creatorPrivilegesResponse] = await Promise.all([xhr.fetchJson<CreatorProgramStatusResponse>(getCreatorProgramStatusUrl(), {
        method: 'GET'
      }), xhr.fetchJson<PagedResults<TeamSearchResult>>(getTeamsUrl(), {
        method
      }), getCreatorPrivileges(xhr)]);
      if (creatorProgramStatusResponse.status === 200) creatorProgramStatus = creatorProgramStatusResponse.data as CreatorProgramStatusResponse || null;
      creatorPrivileges = creatorPrivilegesResponse;
      if (teamsResponse.success) teams = teamsResponse?.data?.results.filter(el => el !== null);else if (teamsResponse.status !== 401) log.error(`Unexpected http status when fetching teams: ${teamsResponse.status}`, '_app.tsx => getInitialProps', true, {
        status: teamsResponse.status,
        ...teamsResponse.data
      });
    }
  } catch (error) {
    console.log('Error while fetching affiliate status');
  }
  const publicRuntimeConfig = await getPublicRuntimeConfig();
  const props = {
    ...initialProps,
    pageProps: {
      teams
    },
    session,
    creatorProgramStatus,
    creatorPrivileges,
    publicRuntimeConfig,
    canonicalUrl
  };
  return props;
};
export default appWithTranslation(CustomApp, nextI18NextConfig);