import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

// TODO: Fixme: adding "any" annotation here to fix build problem after bumping library
export const BootstrapTooltip: any = styled(({
  className,
  ...props
}: TooltipProps) => <Tooltip {...props} arrow classes={{
  popper: className
}} PopperProps={{
  ...props.PopperProps,
  style: {
    zIndex: 9002
  }
}} enterTouchDelay={0} />)(({
  theme
}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.shade5
  },
  [`& .${tooltipClasses.tooltipPlacementRight} .${tooltipClasses.arrow}`]: {
    color: theme.direction === 'rtl' ? 'transparent' : theme.palette.shade5
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.shade5,
    color: theme.palette.shade0,
    textAlign: theme.direction === 'rtl' ? 'end' : 'start',
    padding: '13px 20px',
    maxWidth: '278px',
    direction: theme.direction
  }
}));