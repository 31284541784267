import { createContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { PagedResults } from '@creator-portal/common/types';
import { getTeamsUrl } from '@/config/teams/functionalConstants';
import { sortByRolesAndNames } from '@/services/teams/utils';
import { useAuthSession } from '@/hooks/useAuthSession';
import { getPersonalTeam } from '@/util/teams';
import * as Xhr from '@/util/xhr';
import type { TeamSearchResult } from '@creator-portal/common/types';
import type { JSX, ReactNode } from 'react';
interface TeamsProviderProps {
  children: ReactNode;
  value?: TeamSearchResult[] | null;
}
export interface TeamsContextProps {
  teams: TeamSearchResult[];
  activeTeam: TeamSearchResult | null;
  refreshTeams: () => void;
}
export const TeamsContext = createContext<TeamsContextProps>({
  teams: [],
  activeTeam: null,
  refreshTeams: () => {}
});
export function TeamsProvider({
  value,
  children
}: TeamsProviderProps): JSX.Element {
  const router = useRouter();
  const user = useAuthSession();
  const {
    t
  } = useTranslation();
  const {
    data: teamsData,
    mutate
  } = useSWR<PagedResults<TeamSearchResult>>(getTeamsUrl(), Xhr.fetchSwr, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: false,
    suspense: false,
    fallbackData: {
      limit: 100,
      results: value || []
    }
  });
  const teams = useMemo(() => {
    const teams = (teamsData?.results || value || [])?.filter(el => el !== null);
    return sortByRolesAndNames(teams);
  }, [teamsData, value]);
  const activeTeam = useMemo(() => {
    const teamId = router.query?.team;
    const personalTeam = getPersonalTeam(t('user-card.team.my-projects'), user?.displayName);
    if (!teamId) return personalTeam;
    const activeTeam = teams.find(team => team?.teamId === teamId);
    return activeTeam || personalTeam;
  }, [router.query, teams]);
  const refreshTeams = useCallback(() => {
    void mutate();
  }, [mutate]);
  return <TeamsContext.Provider value={{
    teams,
    activeTeam,
    refreshTeams
  }} data-sentry-element="unknown" data-sentry-component="TeamsProvider" data-sentry-source-file="teams.context.tsx">{children}</TeamsContext.Provider>;
}