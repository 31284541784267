import { Box, Button, Divider } from '@mui/material';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import { getImage } from '@/util/media';
import IconBackArrow from '@/styles/icons/icon-arrow-back.svg';
import useStyles from './sliding-panel-mobile.styles';
import UserAccountMenu from './user-account.component';
import type { FormEvent, JSX } from 'react';
export interface SlidingPanelMobileProps {
  isOpen: boolean;
  slideOpener: () => void;
  handleLogoutClick: (e: FormEvent) => void;
}
export default function SlidingPanelMobileComponent({
  isOpen,
  slideOpener,
  handleLogoutClick
}: SlidingPanelMobileProps): JSX.Element {
  const classes = useStyles();
  const {
    t
  } = useTranslation();
  return <Box component="div" className={clsx(classes.slidingPanel, isOpen && 'opened')} data-sentry-element="Box" data-sentry-component="SlidingPanelMobileComponent" data-sentry-source-file="sliding-panel-mobile.component.tsx">
      <Button variant="text" className={classes.buttonBack} onClick={slideOpener} data-sentry-element="Button" data-sentry-source-file="sliding-panel-mobile.component.tsx">
        <Image src={getImage(IconBackArrow)} className={classes.arrowBack} layout="fixed" height="15" width="15" data-sentry-element="Image" data-sentry-source-file="sliding-panel-mobile.component.tsx" />
        {t('navbar.back')}
      </Button>
      <Divider data-sentry-element="Divider" data-sentry-source-file="sliding-panel-mobile.component.tsx" />
      <UserAccountMenu handleLogoutClick={handleLogoutClick} data-sentry-element="UserAccountMenu" data-sentry-source-file="sliding-panel-mobile.component.tsx" />
    </Box>;
}