import { PROFILE_IMG_TYPES } from '@creator-portal/common/media/constants';
import { Acknowledgement, AcknowledgementType } from '@creator-portal/common/profile-service/types';

import { ALERTS_URL } from '@/config/common/constants';
import { getMediaUrl } from '@/config/media/functionalConstants';
import {
  getAcknowledgementsUrl,
  getCreatorPrivilegesUrl,
  getCurrentProfileReleaseUrl,
  getFollowersStatsUrl,
  getInitProfileUrl,
  getIslandUrl,
  getPickedIslandsUrl,
  getProfileDraftUrl,
  getProfileHeaderImageUrl,
  getProfileIslandsUrl,
  getProfileLiveUrl,
  getProfileMediaUrl,
  getProfileSaveMediaUrl,
  getPublishProfileUrl,
  getSharedPlayHistoryUrl,
} from '@/config/profile/functionalConstants';

import * as Xhr from '@/util/xhr';

import { ErrorPayload } from '../../util/xhr';

import type { Island } from '@/types/profile';
import type { XhrResponse, XhrService } from '@/util/xhr';
import type { LinkCodeData } from '@creator-portal/common/links-service/types';
import type {
  CreatorAlerts,
  CreatorPrivileges,
  FollowersStats,
  GalleryMediaEntry,
  MediaEntry,
  PlayerProfile,
  ProfileLinkCode,
  ProfileLive,
  ProfileRelease,
  SharedActivityData,
  ShortenedLinkCodeData,
} from '@creator-portal/common/types';

// TODO: fix any type when data is right
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const xhr = Xhr.getInstance();

export const publishProfile = async (accountId: string): Promise<null> => {
  const url = getPublishProfileUrl(accountId);
  const response = await xhr.fetchJson(url, {
    method: 'POST',
  });

  Xhr.throwOnFailure(response);

  return null;
};
export const getCreatorPrivileges = async (xhr: XhrService): Promise<CreatorPrivileges | null> => {
  try {
    const res = await xhr.fetchJson<CreatorPrivileges | undefined>(getCreatorPrivilegesUrl(), { method: 'GET' });
    const creatorPrivileges = (res?.data as CreatorPrivileges) || null;

    return creatorPrivileges;
  } catch (_error) {
    return null;
  }
};

export const getProfile = async (accountId: string, xhr: XhrService): Promise<PlayerProfile | undefined> => {
  const url = getProfileDraftUrl(accountId);
  const rsp = await xhr.fetchJson<PlayerProfile | undefined>(url, { method: 'GET' });
  // user might not have a profile/draft yet so do not throw an error here
  if (rsp.status === 404) {
    return undefined;
  }
  Xhr.throwOnFailure(rsp);

  return rsp.data;
};

export const getCurrentProfileRelease = async (accountId: string, xhr: XhrService): Promise<ProfileRelease | undefined> => {
  const url = getCurrentProfileReleaseUrl(accountId);
  const rsp = await xhr.fetchJson<ProfileRelease | undefined>(url, { method: 'GET' });
  // user might not have a release yet so do not throw an error here
  if (rsp.status === 404) {
    return undefined;
  }
  Xhr.throwOnFailure(rsp);

  return rsp.data;
};

export const getLivePinnedIslands = async (accountId: string, xhr: XhrService): Promise<ProfileLinkCode[] | undefined> => {
  const rsp = await xhr.fetchJson<ProfileLive>(getProfileLiveUrl(accountId), { method: 'GET' });
  Xhr.throwOnFailure(rsp);

  return rsp.data.pinnedLinks.map((linkCode) => linkCode);
};

export const getLivePickedIslands = async (accountId: string, x: XhrService = xhr): Promise<ShortenedLinkCodeData[] | undefined> => {
  const rsp = await x.fetchJson<ShortenedLinkCodeData[] | undefined>(getPickedIslandsUrl(accountId), { method: 'GET' });
  Xhr.throwOnFailure(rsp);

  return rsp.data;
};

export const getProfileHeaderImage = async (accountId: string, x: XhrService = xhr): Promise<GalleryMediaEntry | undefined> => {
  const url = getProfileHeaderImageUrl(accountId);
  const rsp = await x.fetchJson<GalleryMediaEntry | undefined>(url, { method: 'GET' });
  // user might not have a profile image yat
  if (rsp.status === 404) {
    return undefined;
  }
  Xhr.throwOnFailure(rsp);

  return rsp.data;
};

export const initProfile = async (accountId: string, xhr: XhrService): Promise<PlayerProfile> => {
  const url = getInitProfileUrl(accountId);
  const response = await xhr.fetchJson<PlayerProfile>(url, { method: 'POST' });
  Xhr.throwOnFailure(response);
  const profile: PlayerProfile = response.data;

  return profile;
};

export const resetDraftProfile = async (accountId: string): Promise<PlayerProfile> => initProfile(accountId, xhr);

export const getProfileIslands = async (accountId: string, xhr: XhrService): Promise<Island[]> => {
  const linkCodeResponse = await xhr.fetchJson<LinkCodeData[]>(getProfileIslandsUrl(accountId), { method: 'GET' });
  Xhr.throwOnFailure(linkCodeResponse);

  return linkCodeResponse.data.map((linkCodeData) => ({
    title: linkCodeData.meta.title as string,
    thumbnailUrl: linkCodeData.meta.image_url as string,
    linkCode: linkCodeData.linkCode,
    id: linkCodeData.meta.projectId as string,
  }));
};

export const getIsland = async (code: string): Promise<Island | ErrorPayload> => {
  const islandResponse = await xhr.fetchJson<Island>(getIslandUrl(code), { method: 'GET' });
  return islandResponse?.data;
};

export const updatePinnedItems = (url: string, index: number): Promise<XhrResponse<unknown>> =>
  xhr.fetchJson(url, {
    method: 'POST',
    body: JSON.stringify({
      index,
    }),
  });

export const putPickedItems = (accountId: string, linkCodes: string[]): Promise<XhrResponse<unknown>> => {
  const url = getPickedIslandsUrl(accountId);

  return xhr.fetchJson(url, {
    method: 'PUT',
    body: JSON.stringify({ linkCodes }),
  });
};

export const updateBio = (url: string, bioText: string, translation: string): Promise<XhrResponse<unknown>> =>
  xhr.fetchJson(url, {
    method: 'PUT',
    body: JSON.stringify({
      value: {
        sourceLang: translation,
        translations: {
          [translation]: bioText,
        },
      },
    }),
  });

export const removeItem = (url: string): Promise<XhrResponse<unknown>> => xhr.fetchJson(url, { method: 'DELETE' });

export const updatePlatformLink = (url: string, link: string): Promise<XhrResponse<unknown>> =>
  xhr.fetchJson(url, {
    method: 'PUT',
    body: JSON.stringify({
      username: link,
    }),
  });

export const getMedia = async (mediaId: string): Promise<MediaEntry> => {
  const url = getMediaUrl(mediaId);
  const response = await xhr.fetchJson<MediaEntry>(url, { method: 'GET' });

  Xhr.throwOnFailure(response);
  const media: MediaEntry = response.data;

  return media;
};

export const getAlerts = async (locale: string): Promise<CreatorAlerts | null> => {
  try {
    const alertsResponse = await xhr.fetchJson<CreatorAlerts>(`${ALERTS_URL}/${locale}`, { method: 'GET' });
    const creatorAlerts = (alertsResponse.success && alertsResponse.data) || null;

    return creatorAlerts;
  } catch (_error) {
    return null;
  }
};

export const getProfileMedia = async (
  accountId: string,
  mediaCategory: PROFILE_IMG_TYPES,
  x: XhrService = xhr,
): Promise<GalleryMediaEntry | undefined> => {
  const url = `${getProfileMediaUrl(accountId)}?mediaCategory=${mediaCategory}`;
  const rsp = await x.fetchJson<GalleryMediaEntry | undefined>(url, { method: 'GET' });
  // user might not have a profile image yat
  if (rsp.status === 404) {
    return undefined;
  }
  Xhr.throwOnFailure(rsp);

  return rsp.data;
};

export const saveProfileMedia = async (
  accountId: string,
  mediaCategory: PROFILE_IMG_TYPES,
  mediaId: string,
): Promise<XhrResponse<unknown>> => {
  const url = `${getProfileSaveMediaUrl(accountId, mediaId)}?mediaCategory=${mediaCategory}`;

  return xhr.fetchJson(url, {
    method: 'PUT',
  });
};

export const getSharedPlayHistory = async (accountId: string, x: XhrService = xhr): Promise<SharedActivityData[] | undefined> => {
  const rsp = await x.fetchJson<SharedActivityData[] | undefined>(getSharedPlayHistoryUrl(accountId), { method: 'GET' });
  Xhr.throwOnFailure(rsp);

  return rsp.data;
};

export const deletePlayHistory = async (accountId: string, linkCode: string): Promise<null> => {
  const url = `${getSharedPlayHistoryUrl(accountId)}/${encodeURIComponent(linkCode)}`;

  const rsp = await xhr.fetchJson(url, {
    method: 'DELETE',
  });
  Xhr.throwOnFailure(rsp);

  return null;
};

export const deletePlayHistoryBatch = async (accountId: string, linkCodes: string[]): Promise<null> => {
  const url = `${getSharedPlayHistoryUrl(accountId)}/batch-delete`;

  const rsp = await xhr.fetchJson(url, {
    method: 'POST',
    body: JSON.stringify({
      linkCodes,
    }),
  });
  Xhr.throwOnFailure(rsp);

  return null;
};

export const saveAcknowledgement = async (accountId: string, acknowledgementType: AcknowledgementType): Promise<XhrResponse<unknown>> => {
  const url = getAcknowledgementsUrl(accountId, acknowledgementType);

  return xhr.fetchJson(url, {
    method: 'POST',
  });
};

export const getLiveAcknowledgements = async (accountId: string, x: XhrService = xhr): Promise<Acknowledgement[] | undefined> => {
  const rsp = await x.fetchJson<Acknowledgement[] | undefined>(getAcknowledgementsUrl(accountId), { method: 'GET' });
  Xhr.throwOnFailure(rsp);

  return rsp.data;
};

export const getFollowersStats = async (x: XhrService = xhr): Promise<FollowersStats | null> => {
  try {
    const rsp = await x.fetchJson<FollowersStats | undefined>(getFollowersStatsUrl(), { method: 'GET' });

    return rsp?.data as FollowersStats;
  } catch (_error) {
    console.error('Failed to fetch followers stats');

    return null;
  }
};
