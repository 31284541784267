import { useContext, useEffect } from 'react';

import { AuthSessionContext } from '@/contexts/auth-session.context';

import type { AuthSession } from '@creator-portal/common/types';

interface UseAuthSessionOptions {
  onUnauthenticated?: () => void;
}

/**
 * Returns current AuthSession from react context api.
 * @type AssertNotUndefined removes undefined from return type definition.
 */
export function useAuthSession<AssertNotUndefined extends boolean = false>(
  options?: UseAuthSessionOptions,
): AssertNotUndefined extends true ? AuthSession : AuthSession | undefined {
  if (!AuthSessionContext) {
    throw new Error('React Context is unavailable in React Server Components');
  }

  const session = useContext(AuthSessionContext) as AssertNotUndefined extends true ? AuthSession : AuthSession | undefined;

  const { onUnauthenticated } = options ?? {};

  useEffect(() => {
    if (!session && onUnauthenticated) {
      onUnauthenticated();
    }
  }, [session, onUnauthenticated]);

  return session;
}
