/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useTheme } from '@mui/material';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin]
});
export function withMuiRTL<T>(Component: React.ComponentType<T>) {
  return (hocProps: any) => {
    const theme = useTheme();
    if (theme?.direction === 'rtl') {
      return <CacheProvider value={cacheRtl}>
          <Component {...hocProps} />
        </CacheProvider>;
    }
    return <Component {...hocProps} />;
  };
}