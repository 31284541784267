import { UTCDate } from '@date-fns/utc';
import { startOfDay } from 'date-fns';

import { DIFFICULTY_LEVELS, RATING_LEVELS } from '@creator-portal/common/types';

type ThemeMode = 'dark' | 'light';

export const VERTICAL_AXIS_TICK_NUM = 6;
export const FUN_VERTICAL_AXIS_TICK_NUM = 3;
export const HORIZONTAL_AXIS_TICK_NUM_LG = 3;
export const HORIZONTAL_AXIS_TICK_NUM_XL = 5;
export const MAX_BAND_WIDTH = 48;
export const MINS_IN_HOUR = 60;
export const DATEPICKER_START_DATE = startOfDay(new UTCDate(2023, 2, 1));
export const DATEPICKER_LOCAL_START_DATE = startOfDay(new Date(2023, 2, 1));
// There is no Clicks data earlier than this date
export const DATEPICKER_START_DATE_CLICKS = startOfDay(new UTCDate(2024, 7, 8));

export const SESSION_TIME_STEP = 10;
export const EXTRA_SESSION_TIME_LABEL = '90+';
export const EXTRA_SESSION_DATA_INDEX = 1000;
export const getFunBarColors = (mode: ThemeMode) => (mode === 'dark' ? ['#FF531B', '#689EFD'] : ['#FF531B', '#4A83E0']);
export const getRatingBarColors = (mode: ThemeMode) =>
  mode === 'dark'
    ? ['#FF531B', '#EE5B33', '#CD6C66', '#BC747F', '#AB7D98', '#9A85B1', '#8B8DCB', '#7996E4', '#729AF0', '#689EFD']
    : ['#FF531B', '#EB5830', '#D75E46', '#C4635D', '#AF6872', '#9A6E88', '#86739E', '#7278B4', '#5F7ECA', '#4A83E0'];

export const getDifficultyBarColors = (mode: ThemeMode) => ({
  [DIFFICULTY_LEVELS.TOO_EASY]: '#FF531B',
  [DIFFICULTY_LEVELS.EASY]: mode === 'dark' ? '#9A85B1' : '#9A6E88',
  [DIFFICULTY_LEVELS.JUST_RIGHT]: mode === 'dark' ? '#689EFD' : '#4A83E0',
  [DIFFICULTY_LEVELS.DIFFICULT]: mode === 'dark' ? '#9A85B1' : '#9A6E88',
  [DIFFICULTY_LEVELS.TOO_DIFFICULT]: '#FF531B',
});

export const getRatingColors = (mode: ThemeMode): Record<string, string> => ({
  [RATING_LEVELS.RATE_1]: getRatingBarColors(mode)[0],
  [RATING_LEVELS.RATE_2]: getRatingBarColors(mode)[1],
  [RATING_LEVELS.RATE_3]: getRatingBarColors(mode)[2],
  [RATING_LEVELS.RATE_4]: getRatingBarColors(mode)[3],
  [RATING_LEVELS.RATE_5]: getRatingBarColors(mode)[4],
  [RATING_LEVELS.RATE_6]: getRatingBarColors(mode)[5],
  [RATING_LEVELS.RATE_7]: getRatingBarColors(mode)[6],
  [RATING_LEVELS.RATE_8]: getRatingBarColors(mode)[7],
  [RATING_LEVELS.RATE_9]: getRatingBarColors(mode)[8],
  [RATING_LEVELS.RATE_10]: getRatingBarColors(mode)[9],
});

export const WHISKERS_WIDTH_COEFFICIENT = 0.7;
export const FALLBACK_Y_AXIS_MAX_VALUE = 5;

export const HIGHLIGHTS_HORIZONTAL_GAP = 5.8;
export const CHART_HORIZONTAL_SPACING = 4.8;
export const CHART_HORIZONTAL_SPACING_SM_DOWN = 3.2;
