import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    alertWithMobileStyles: {
      '&.MuiPaper-root': {
        flexWrap: 'nowrap',
        '& .MuiAlert-action': {
          width: 'auto',
          marginTop: 0,
        },
      },
    },
    alert: {
      direction: theme.direction,
      '&.MuiPaper-root': {
        padding: theme.spacing(2, 4),
        borderRadius: '8px !important',
        minHeight: '40px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      '& .MuiAlert-icon': {
        height: '20px',
        width: '20px',
      },
      '& .MuiTypography-root': {
        margin: 'auto 0',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 600,
      },
      '& .MuiAlert-action': {
        width: '100%',
        padding: theme.spacing(0),

        '& button': {
          width: '100%',
        },

        [theme.breakpoints.up('sm')]: {
          width: 'auto',
          '& button': {
            width: 'auto',
          },
          marginLeft: isRTL ? 0 : 'auto',
          marginRight: isRTL ? 'auto' : 0,
          padding: isRTL ? theme.spacing(0, 4, 0, 0) : theme.spacing(0, 0, 0, 4),
        },
      },
      '& .MuiButton-root': {
        [theme.breakpoints.up('sm')]: {
          minWidth: '216px',
        },
      },

      '& .MuiAlert-message': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),

        [theme.breakpoints.up('xs')]: {
          minWidth: '210px',
        },
      },

      '&.MuiAlert-standardSuccess': {
        background: `linear-gradient(91.54deg, ${theme.palette.commonGreenGradient1} -43.98%, ${theme.palette.commonGreen} 168.13%)`,
        '& .MuiTypography-root': {
          color: theme.palette.commonWhite,
        },
        '& .MuiSvgIcon-root': {
          color: `${theme.palette.commonWhite} !important`,
        },
        '& .MuiButton-root': {
          background: theme.palette.commonWhite,
          color: theme.palette.commonGreenGradient1,
        },
      },

      '&.MuiAlert-standardInfo': {
        background: alpha(theme.palette.shade7, 0.2),
        border: `1px solid ${theme.palette.shade4} !important`,
        '& .MuiTypography-root': {
          color: theme.palette.textSecondary,
        },
        '& .MuiSvgIcon-root': {
          color: `${theme.palette.blue} !important`,
        },
        '& .MuiButtonBase-root': {
          '& svg': {
            color: `${theme.palette.shade0} !important`,
          },
        },
        '& .MuiButton-root': {
          background: theme.palette.commonBlueGradient2,
          color: theme.palette.commonWhite,
        },
      },

      '&.MuiAlert-standardWarning': {
        background: `linear-gradient(91.52deg, ${theme.palette.commonOrangeGradient1} -5.24%, ${theme.palette.commonOrangeGradient2} 117.06%)`,
        '& .MuiTypography-root': {
          color: theme.palette.commonBlack,
        },
        '& .MuiSvgIcon-root': {
          color: `${theme.palette.commonBlack} !important`,
        },
        '& .MuiButtonBase-root': {
          '& svg': {
            color: `${theme.palette.commonBlack} !important`,
          },
        },
        '& .MuiButton-root': {
          background: theme.palette.commonWhite,
          color: theme.palette.commonBlack,
        },
      },

      '&.MuiAlert-standardError': {
        background: `linear-gradient(91.53deg, ${theme.palette.commonRedGradient1} -5.24%, ${theme.palette.commonRedGradient2} 103.3%)`,
        '& .MuiTypography-root': {
          color: theme.palette.commonWhite,
        },
        '& .MuiSvgIcon-root': {
          color: `${theme.palette.commonWhite} !important`,
        },
        '& .MuiButton-root': {
          background: theme.palette.commonWhite,
          color: theme.palette.commonRedGradient1,
        },
      },
    },
    iconSuccess: {
      width: '28px',
      height: '28px',
      color: `${theme.palette.commonGreen} !important`,
    },
    iconInfo: {
      color: `${theme.palette.blue} !important`,
    },
  });
});

export default useStyles;
