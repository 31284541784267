import { DISCOVERY_INTENT } from '@creator-portal/common/links';
import { Attribution, MachineTranslationPreferencesType, MarketingOptIn } from '@creator-portal/common/types';

export interface PublishBuildDto {
  mediaSubmissionId: string | null;
  mediaSubmissionToken: string | null;
  mediaSubmissionFileEtags: Record<string, { etags: string[] }>;
  title: string;
  tagline: string;
  tags: string[];
  introduction: string[];
  autoActivate: boolean;
  discoveryIntent?: DISCOVERY_INTENT;
  attributions: Attribution[];
  hasSquareThumbnail: boolean;
  hasLobbyBackground: boolean;
  hasTrailer: boolean;
  marketing_opt_in: MarketingOptIn;
  certId?: string;
  changes?: string;
  machineTranslationPreferences?: MachineTranslationPreferencesType;
}

export type PartnerIpProgramData = {
  image: string;
  title: string;
  description: string;
};
export interface AttributionModel {
  tempId: string;
  title: string;
  source_url: string;
  author: string;
  author_url: string;
  license: string;
  license_url: string;
}

export type { Attribution };

export enum RatingTableHeadTitles {
  PREVIEW = 'preview',
  RATING_SYSTEM = 'ratingCategory',
  REGION = 'region',
  DESCRIPTORS = 'descriptors',
  INTERACTIVE_ELEMENTS = 'interactive-elements',
}

interface CertDetail {
  certId: string;
  productType: number;
  ratingList: Rating[];
}

export interface Rating {
  ratingBoardId: number;
  ratingBoardShortText: string;
  ratingBoardText: string;
  ageRatingId: number;
  ageRatingText: string;
  ageControl: number | null;
  descriptorIds: number[];
  interactiveElementIds: number[];
  descriptorTexts: string[];
  interactiveElementTexts: string[];
  fileDescriptions: any[]; // we don't now specific type for fileDescriptions yet
  trad: boolean;
}

interface LoadResult {
  productName: string;
  developerEmail: string | null;
  publicDeveloperEmail?: string | null;
  ratingLogicVersion?: string;
  dateCreated?: string;
  certDetail: CertDetail;
}

export interface CertificateDetails {
  matchFound?: boolean;
  storeVersionNotSupported?: boolean;
  loadResult: LoadResult;
}

export enum ProjectTypeForAnalytics {
  LEGO = 'LEGO',
  ROCKET_RACING = 'ROCKET RACING',
  DEFAULT = 'DEFAULT',
  FALL_GUYS = 'FALL GUYS',
  TMNT = 'TMNT',
  TWD = 'TWD',
}
