import { DISCOVERY_INTENT } from '@creator-portal/common/links';
import { LinkCodeInfo } from '@creator-portal/common/links-service/types';

import { AttributionModel } from '@/types/publishing';

export enum STEPPER_KEYS {
  buildValidation = 'buildValidation',
  gameDetails = 'gameDetails',
  rating = 'rating',
  promotionalMedia = 'promotionalMedia',
  attributions = 'attributions',
  visibility = 'visibility',
}

export const PUBLISH_PROGRESS_FIELD = 'isPublishingInProgress';

export interface StepperControls {
  stepsToValidate: {
    [STEPPER_KEYS.gameDetails]: boolean;
    [STEPPER_KEYS.rating]: boolean;
    [STEPPER_KEYS.promotionalMedia]: boolean;
    [STEPPER_KEYS.attributions]: boolean;
    [STEPPER_KEYS.visibility]: boolean;
  };
  [PUBLISH_PROGRESS_FIELD]: boolean;
}

export enum GAME_DETAILS_FIELDS {
  title = 'title',
  tagline = 'tagline',
  tags = 'tags',
  introduction = 'introduction',
}

export enum GAME_DETAILS_MACHINE_TRANSLATIONS_PREFERENCES {
  title = 'machineTranslationPreferences.title',
  tagline = 'machineTranslationPreferences.tagline',
  introduction = 'machineTranslationPreferences.introduction',
}

export interface GameDetails {
  [GAME_DETAILS_FIELDS.title]: string;
  [GAME_DETAILS_FIELDS.tagline]: string;
  [GAME_DETAILS_FIELDS.tags]: string[];
  [GAME_DETAILS_FIELDS.introduction]: string[];
}

export enum NEW_CHANGES_FIELDS {
  changes = 'changes',
}
export interface NewChanges {
  [NEW_CHANGES_FIELDS.changes]: string;
}

export enum RATING_FIELDS {
  iarcEmail = 'iarcEmail',
  isIarcHasNotPublicEmail = 'isIarcHasNotPublicEmail',
  iarcPublicEmail = 'iarcPublicEmail',
  isIarcHasNotCertID = 'isIarcHasNotCertID',
  iarcCertId = 'iarcCertId',
}

export interface RatingDetails {
  [RATING_FIELDS.iarcEmail]: string;
  [RATING_FIELDS.isIarcHasNotPublicEmail]: boolean;
  [RATING_FIELDS.iarcPublicEmail]: string;
  [RATING_FIELDS.isIarcHasNotCertID]: boolean;
  [RATING_FIELDS.iarcCertId]: string;
}

export enum MANAGE_RATING_FIELDS {
  file = 'file',
  ageRating = 'ageRating',
  descriptors = 'descriptors',
  elements = 'elements',
}

export enum MEDIA_FILES_FIELDS {
  imageToUpload = 'imageToUpload',
  imageUploadingInfo = 'imageUploadingInfo',
  existingImageUrl = 'existingImageUrl',

  squareImageToUpload = 'squareImageToUpload',
  squareImageUploadingInfo = 'squareImageUploadingInfo',
  existingSquareImageUrl = 'existingSquareImageUrl',

  videoToUpload = 'videoToUpload',
  videoUploadingInfo = 'videoUploadingInfo',
  existingVideoVuid = 'existingVideoVuid',

  lobbyImageToUpload = 'lobbyImageToUpload',
  lobbyImageUploadingInfo = 'lobbyImageUploadingInfo',
  lobbyExistingImageUrl = 'lobbyExistingImageUrl',
}

export interface MediaDetails {
  [MEDIA_FILES_FIELDS.imageToUpload]: string | undefined;
  [MEDIA_FILES_FIELDS.imageUploadingInfo]: string | undefined;
  [MEDIA_FILES_FIELDS.existingImageUrl]: string;

  [MEDIA_FILES_FIELDS.squareImageToUpload]: string | undefined;
  [MEDIA_FILES_FIELDS.squareImageUploadingInfo]: string | undefined;
  [MEDIA_FILES_FIELDS.existingSquareImageUrl]: string;

  [MEDIA_FILES_FIELDS.videoToUpload]: string | undefined;
  [MEDIA_FILES_FIELDS.videoUploadingInfo]: string | undefined;
  [MEDIA_FILES_FIELDS.existingVideoVuid]: string;

  [MEDIA_FILES_FIELDS.lobbyImageToUpload]: string | undefined;
  [MEDIA_FILES_FIELDS.lobbyImageUploadingInfo]: string | undefined;
  [MEDIA_FILES_FIELDS.lobbyExistingImageUrl]: string;
}

export enum ATTRIBUTIONS_RATINGS_FIELDS {
  attributions = 'attributions',
}

export interface AttributionRatingsDetails {
  [ATTRIBUTIONS_RATINGS_FIELDS.attributions]: AttributionModel[];
}

export enum VISIBILITY_FIELDS {
  autoActivate = 'autoActivate',
  discoveryIntent = 'discoveryIntent',
  // used for UI purposes to show project visibility chooser
  isProjectHasReleases = 'isProjectHasReleases',
}

export interface VisibilityDetails {
  [VISIBILITY_FIELDS.autoActivate]: boolean;
  [VISIBILITY_FIELDS.discoveryIntent]: DISCOVERY_INTENT | undefined;
  [VISIBILITY_FIELDS.isProjectHasReleases]: boolean;
}

export enum PRIVATE_VERSION_FIELDS {
  buildCode = 'buildCode',
}

export interface PrivateVersion {
  [PRIVATE_VERSION_FIELDS.buildCode]: LinkCodeInfo | undefined;
}

export type DeployReleaseFormState =
  | GameDetails
  | NewChanges
  | MediaDetails
  | RatingDetails
  | AttributionRatingsDetails
  | VisibilityDetails
  | StepperControls
  | PrivateVersion;

export enum MarketingFields {
  IsMarketingAndPromotionChecked = 'isMarketingAndPromotionChecked',
}

export enum RATING_OPTIONS_KEY {
  hasIarcCert = 'hasIarcCert',
  takeQuestionnaire = 'takeQuestionnaire',
}

export enum RATING_OPERATIONS {
  attachExistingRating = 'attachExistingRating',
  refreshCertDetails = 'refreshCertDetails',
}

export enum EMAIL_FIELDS {
  EMAIL = 'email',
  PUBLIC_EMAIL = 'publicEmail',
}
export interface EmailData {
  [EMAIL_FIELDS.EMAIL]: string;
}
export interface PublicEmailData {
  [EMAIL_FIELDS.PUBLIC_EMAIL]: string;
}
export type IarcEmailData = EmailData | PublicEmailData;
