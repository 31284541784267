import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useParams, useSearchParams } from 'next/navigation';
import { PERSONAL_TEAM_ID } from '@creator-portal/common/publishing/constants';
import { AccountPolicy, TeamSearchResult } from '@creator-portal/common/types';
import { DEFAULT_LOCALE } from '@/config/common/locale.config';
import { getActiveTeamData, isMainNavigationCollapsed, prepareNavigationData } from '@/services/common/navigationUtils';
import useStyles from '@/components/common/main.layout.styles';
import { useAuthSession } from '@/hooks/useAuthSession';
import { useCreatorPrivileges } from '@/hooks/useCreatorPrivileges';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';
import useDialogWithQueryKey from '@/hooks/useDialogWithQueryKey';
import { useHasAccountPolicy } from '@/hooks/useHasAccountPolicy';
import SwitchTeamDialog, { DIALOG_ID } from '../teams/switch-team-dialog/switch-team-dialog.component';
import { NavSection, NavSectionItem } from './navigation/nav-section';
import UserCard from './navigation/user-card';
import type { Dispatch, FC, JSX, SetStateAction } from 'react';
interface MainNavigationComponentProps {
  teams: TeamSearchResult[];
  setNewExternalRoute: Dispatch<SetStateAction<string | undefined>>;
  isJoinMemberPage?: boolean;
  hideNavigation?: boolean;
  handleMobileSlideToggle?: () => void;
}
const MainNavigationComponent: FC<MainNavigationComponentProps> = ({
  teams,
  isJoinMemberPage,
  hideNavigation,
  handleMobileSlideToggle,
  setNewExternalRoute
}): JSX.Element => {
  const searchParams = useSearchParams();
  const params = useParams();
  const classes = useStyles();
  const user = useAuthSession();
  const creatorPrivileges = useCreatorPrivileges();
  const creatorProgramStatus = useCreatorProgramStatus();
  const isOver18 = useHasAccountPolicy(AccountPolicy.ASSUMED_OVER_18);
  const {
    queryValue: isOpenDialog,
    handleOpenDialog,
    handleCloseDialog
  } = useDialogWithQueryKey('dialogId');
  const teamId = searchParams?.get('team') ?? params?.team ?? PERSONAL_TEAM_ID;
  const lang = searchParams?.get('lang') ?? DEFAULT_LOCALE;
  const isCabined = searchParams?.get('isCabined');
  const {
    activeTeamName,
    teamMemberRole
  } = getActiveTeamData(teams, teamId);
  const isCabinedMode = isCabined === 'true';
  const isNavigationVisible = !!user && !hideNavigation && !isCabinedMode;
  const isNavbarCollapsed = isMainNavigationCollapsed(user, params);
  const navConfig: NavSection[] = prepareNavigationData(teamMemberRole, {
    lang,
    teamId,
    isJoinMemberPage,
    isNavigationVisible,
    isCabinedMode,
    creatorPrivileges,
    creatorProgramStatus,
    isOver18
  });
  return <Box className={classes.sideBar} data-sentry-element="Box" data-sentry-component="MainNavigationComponent" data-sentry-source-file="main.navigation.component.tsx">
      {isNavigationVisible && <UserCard onClick={() => handleOpenDialog(DIALOG_ID)} activeTeamName={activeTeamName} teamMemberRole={teamMemberRole} isNavbarCollapsed={isNavbarCollapsed} />}
      <List component="nav" className={classes.navList} data-sentry-element="List" data-sentry-source-file="main.navigation.component.tsx">
        {navConfig.map((navSection, index) => <NavSectionItem onClick={handleMobileSlideToggle} key={navSection.header ?? index} navSection={navSection} index={index} setNewExternalRoute={setNewExternalRoute} isNavbarCollapsed={isNavbarCollapsed} />)}
      </List>
      {!!isOpenDialog && <SwitchTeamDialog teams={teams} onClose={handleCloseDialog} isOpen={!!isOpenDialog} />}
    </Box>;
};
export default MainNavigationComponent;