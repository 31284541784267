import type { JSX } from 'react';
interface IconProps {
  className?: string;
}
const DownloadIcon = ({
  className
}: IconProps): JSX.Element => <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="DownloadIcon" data-sentry-source-file="download.tsx">
    <g id="Icon Arrow Down To Bracket" data-sentry-element="g" data-sentry-source-file="download.tsx">
      <g id="vector" data-sentry-element="g" data-sentry-source-file="download.tsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0007 1.75C10.4149 1.75 10.7507 2.08579 10.7507 2.5V12.356L12.8037 10.303C13.0965 10.0101 13.5714 10.0101 13.8643 10.303C14.1572 10.5959 14.1572 11.0708 13.8643 11.3637L10.0007 15.2273L6.13699 11.3637C5.84409 11.0708 5.84409 10.5959 6.13699 10.303C6.42988 10.0101 6.90475 10.0101 7.19765 10.303L9.25065 12.356V2.5C9.25065 2.08579 9.58644 1.75 10.0007 1.75Z" data-sentry-element="path" data-sentry-source-file="download.tsx" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.33398 13.4167C3.7482 13.4167 4.08398 13.7525 4.08398 14.1667V16.25C4.08398 16.5261 4.30784 16.75 4.58398 16.75H15.4173C15.6935 16.75 15.9173 16.5261 15.9173 16.25V14.1667C15.9173 13.7525 16.2531 13.4167 16.6673 13.4167C17.0815 13.4167 17.4173 13.7525 17.4173 14.1667V16.25C17.4173 17.3546 16.5219 18.25 15.4173 18.25H4.58398C3.47942 18.25 2.58398 17.3546 2.58398 16.25V14.1667C2.58398 13.7525 2.91977 13.4167 3.33398 13.4167Z" data-sentry-element="path" data-sentry-source-file="download.tsx" />
      </g>
    </g>
  </svg>;
export default DownloadIcon;