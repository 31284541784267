import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    dialog: {
      '& .MuiDialogContent-root': {
        padding: theme.spacing(4.8, 9.6),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(0, 9.6, 9.6),
        '& > div': {
          justifyContent: 'space-between',
          gap: 32,
          '& > button': {
            width: '100%',
          },
        },
      },
    },
    footerContent: {
      textAlign: 'center',
      fontSize: `${theme.typography.pxToRem(12)} !important`,
      fontWeight: 400,
      color: theme.palette.shade2,
      marginTop: `${theme.spacing(1)} !important`,
      width: '100% !important',
    },
    footerContentManageRating: {
      textAlign: isRTL ? 'right' : 'left',
    },
    description: {
      textAlign: 'center',
      fontSize: `${theme.typography.pxToRem(16)} !important`,
      color: theme.palette.shade0,
      // padding: theme.spacing(0, 1.5),
      lineHeight: theme.typography.pxToRem(20),
    },
    descriptionManageRating: {
      textAlign: isRTL ? 'right' : 'left',
      fontSize: `${theme.typography.pxToRem(12)} !important`,
    },
    revertToIARC: {
      marginBottom: `${theme.spacing(4.8)} !important`,
    },
    textFieldBlock: {
      padding: theme.spacing(4.8, 0, 0, 0),
    },
    selectField: {
      width: '100%',
      marginBottom: `${theme.spacing(3.2)} !important`,
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.shade7,
      },
    },
    selectAutocompleteField: {
      marginBottom: theme.spacing(3.2),
    },
    fieldFileTitle: {
      fontSize: `${theme.typography.pxToRem(14)} !important`,
      fontWeight: 700,
      lineHeight: theme.typography.pxToRem(21),
      color: theme.palette.shade0,
    },
    fieldFileSubtitle: {
      fontSize: `${theme.typography.pxToRem(12)} !important`,
      fontWeight: 400,
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.shade2,
      marginBottom: 8,
    },
    fileDropArea: {
      minHeight: 82,
      // @ts-ignore
      flexDirection: 'row !important',
      backgroundColor: theme.palette.shade7,
      border: `1px dashed ${theme.palette.shade3} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.shade7} !important`,
        border: `1px dashed ${theme.palette.shade1} !important`,
      },
      '& svg': {
        color: theme.palette.blue,
        margin: '-18px 10px 0 0 !important',
      },
      '& div:nth-child(2)': {
        textAlign: 'center',
      },
    },
    linkStyle: {
      textDecoration: 'underline',
    },
    formHelperText: {
      marginBottom: `${theme.spacing(1.8)} !important`,
    },
    fileDropAreaError: {
      border: `1px dashed ${theme.palette.error.light} !important`,
      '&:hover': {
        border: `1px dashed ${theme.palette.error.light} !important`,
      },
    },
    spanRequired: {
      color: theme.palette.commonOrange,
    },
    chipTextField: {
      marginBottom: 16,
      '& > div > div.MuiInputBase-root': {
        paddingTop: '25px !important',
        paddingBottom: '5px !important',
      },
    },
    uploadedFileContent: {
      height: 82,
      backgroundColor: theme.palette.shade7,
      border: `1px solid ${theme.palette.shade4}`,
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: isRTL ? theme.spacing(4, 4, 4, 2) : theme.spacing(4, 2, 4, 4),
      gap: 16,
      '& > div:nth-child(1)': {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: 16,
        width: 'calc(100% - 58px)',
        '& > div:nth-child(2)': {
          overflow: 'hidden',
          height: 40,
          '& div': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& > div:nth-child(1)': {
            color: theme.palette.shade0,
            fontWeight: 700,
            fontSize: theme.typography.pxToRem(13),
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    uploadedFileSize: {
      '& span': {
        padding: theme.spacing(0, 2, 0, 2),
      },
    },
    fileContentHide: {
      display: 'none !important',
    },
    warningInfoBox: {
      margin: `${theme.spacing(0, 0, 4.4, 0)} !important`,
      '& .MuiTypography-root': {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
      },
    },
    dCircularLoader: {
      justifyContent: 'space-evenly',
      display: 'flex',
      height: 111,
      alignItems: 'center',
    },
  });
});

export default useStyles;
